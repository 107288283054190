import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BusService } from "../../../services/bus.service";
import { EventsService } from "../../../services/events.service";
import ChangePasswordModel from './change-password.settings.model';
import ChangePasswordForm from "./change-password.settings.form";

@Component({
  selector: 'change-password-component',
  templateUrl: './change-password.settings.template.html',
  styleUrls: ['./change-password.settings.styles.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  private model: ChangePasswordModel;
  form: ChangePasswordForm;
  public isFormErrorMessage: boolean = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.model = new ChangePasswordModel();
    this.form = new ChangePasswordForm(this.model);
  }

  // start form validation (to show what is wrong)
  private invalidForm(): void {
    this.form.validate();
    this.isFormErrorMessage = true;
  }

  // start validation functionality
  public submit(): void {
    this.bus.publish(this.events.notified.validation.form.aggregation, this.form.isValid);
    this.bus.publish(this.events.notified.validation.form.action);
  }

  // start change password request
  private changePassword(): void {
    this.bus.publish(this.events.requested.data.settings.changePassword, this.form.formData)
  }

  private changePasswordSuccess(): void {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '320px',
      data: {success: true},
    });
    this.router.navigateByUrl('/');
  }

  private changePasswordFailure(): void {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '480px',
      data: {success: false},
    });
  }

  // subscribe on validation success/failure
  public subscribe(): void {
   this.bus.subscribe(this.events.received.validation.failure, this.invalidForm, this);
   this.bus.subscribe(this.events.received.validation.success, this.changePassword, this);
   this.bus.subscribe(this.events.received.data.settings.changePassword.success, this.changePasswordSuccess, this);
   this.bus.subscribe(this.events.received.data.settings.changePassword.failure, this.changePasswordFailure, this);
  }

  // unsubscribe on validation success/failure
  public unSubscribe(): void {
   this.bus.unsubscribe(this.events.received.validation.failure, this.invalidForm);
   this.bus.unsubscribe(this.events.received.validation.success, this.changePassword);
   this.bus.unsubscribe(this.events.received.data.settings.changePassword.success, this.changePasswordSuccess);
   this.bus.unsubscribe(this.events.received.data.settings.changePassword.failure, this.changePasswordFailure);
  }

  // make subscribe on a component initialization
  public ngOnInit(): void {
    this.subscribe();
  }

  // make unsubscribe before destroying component
  public ngOnDestroy(): void {
    this.unSubscribe();
  }
}

@Component({
  selector: 'change-password-dialog',
  templateUrl: './change-password.dialog.template.html'
})
export class ChangePasswordDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any){}
}
