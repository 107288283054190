import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copied-dialog',
  templateUrl: './copied-dialog.component.html',
  styleUrls: ['./copied-dialog.component.scss']
})
export class CopiedDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  get isMac() {
    return window.navigator.userAgent.indexOf("Mac") != -1;
  }
}
