import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs';

@Injectable()
export class XlsxExportService {

  constructor() { }

  export(json: any, outputFilenamePrefix: string) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    XLSX.utils.book_append_sheet(wb, ws);
    const dateString = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`;
    XLSX.writeFile(wb, `${outputFilenamePrefix}_${dateString}.xlsx`);
  }

}
