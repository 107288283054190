import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { DPIARiskConstants } from '../dpia.consts';


@Component({
  selector: 'app-dpia-risk-dialog',
  templateUrl: './dpia-risk-dialog.component.html',
  styleUrls: ['./dpia-risk-dialog.component.scss']
})
export class DpiaRiskDialogComponent implements OnInit {

  changed = false;

  probability: number;
  probabilityReasons: { systematic: boolean, text: string }[] = [];

  riskRanges = DPIARiskConstants.thresholds.map((item, index) => {
    if (index < DPIARiskConstants.thresholds.length - 1) {
      return [item, DPIARiskConstants.thresholds[index + 1]];
    }
  }).filter(_ => _);

  riskConstants = DPIARiskConstants;

  focusedReason = undefined;

  constructor(
    public ref: MatDialogRef<DpiaRiskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.probability = data.probability;
    this.probabilityReasons = data.probabilityReasons.map(_ => Object.assign({}, _));
  }

  ngOnInit() {
  }

  setProbability(range) {
    if (this.probability < range[0] || this.probability >= range[1]) {
      this.probability = (range[0] + range[1]) / 2;
      this.changed = true;
    }
  }

  addReason() {
    this.changed = true;
    this.focusedReason = { systematic: false, text: '' };
    this.probabilityReasons.push(this.focusedReason);
  }

  removeReason(reason) {
    this.changed = true;
    this.probabilityReasons = this.probabilityReasons.filter(r => r !== reason);
  }

  done() {
    this.ref.close({
      mutated: this.changed,
      probability: this.probability,
      reasons: this.probabilityReasons
    });
  }
}
