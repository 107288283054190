import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { RequestService } from '../request.service';

import { DirectoryUser } from './directory-user';


@Injectable()
export class DirectoryService {
  api = {
    root: '/directory',
    users: '/users',
  };

  constructor(private request: RequestService) {}

  search(keyword?: string, role?: string) {
    let params = new HttpParams();
    if (keyword && keyword.length > 0) {
      params = params.set('keyword', keyword);
    }

    if (role && role.length > 0) {
      params = params.set('role', role);
    }

    return this.request.get<DirectoryUser[]>({
      uri: this.api.root + this.api.users,
      parameters: params,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }
}
