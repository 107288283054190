import { Component, OnInit, OnDestroy } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';

import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';

import { AddClientDialogComponent } from '../add-client-dialog/add-client-dialog.component';
import { ClientManagementService } from 'app/services/supervision/client-management.service';
import { DeleteClientDialogComponent } from '../delete-client-dialog/delete-client-dialog.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientSupervisionService } from '../../../services/supervision/client-supervision.service';
import { SupervisionClientTokenService } from '../../../services/supervision/client-token.service';
import { SupervisionTemplatesService } from '../../../services/supervision/templates.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { AppConfig } from '../../../app.config';


@Component({
  selector: 'app-supervision-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class SupervisionClientDetailsComponent implements OnInit, OnDestroy {

  appConfig = AppConfig;

  supervisionId: number;
  supervision: any;

  loadingToken: boolean;

  controllerId: number;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private clientService: ClientManagementService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private clientTokenService: SupervisionClientTokenService,
    private supervisionService: ClientSupervisionService,
    private templates: SupervisionTemplatesService,
    private authService: AuthenticationService,
  ) {

  }

  superviseController() {
    this.loadingToken = true;
    this.bus.publish(this.events.requested.data.supervision.clientToken.single, this.supervisionId);
  }

  startSupervision(token) {
    this.loadingToken = false;
    this.supervisionService.startSupervision(token);
  }

  setSupervision(supervision) {
    this.supervision = supervision;
  }

  public deleteClient() {
    this.dialog.open(DeleteClientDialogComponent, {
      width: '350px',
      data: {
        client: this.supervision
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.router.navigate(['/supervision/clients']);
      }
    });
  }

  googleMapsUrl(): SafeResourceUrl {
    if (this.supervision) {
      let address = `${this.supervision.controllerAddressStreet}, ` +
        `${this.supervision.controllerAddressPostcode} ` +
        `${this.supervision.controllerAddressCity},` +
        ` ${this.supervision.controllerAddressCountry}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyBuRSXd5-JmVLAkFCPHd7_QQpWmi_5gfRo` +
        `&q=${encodeURIComponent(address)}`);
    }
    return undefined;
  }

  public applyTemplate() {
    this.templates.applyTemplate(this.supervision);
  }

  public createTemplate() {
    this.templates.createTemplate(this.supervision);
  }

  private setControllerId() {
    this.controllerId = this.authService.controllerId;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.supervision.clients.single.success, this.setSupervision, this);
    this.bus.subscribe(this.events.received.data.supervision.clients.single.failure, () => { this.router.navigate(['/not-found']); }, this);
    this.bus.subscribe(this.events.received.data.supervision.clientToken.single.success, this.startSupervision, this);
  }

  public ngOnInit(): void {
    this.subscribe();
    this.setControllerId();

    this.activatedRoute.params.subscribe((params: Params) => {
      this.supervisionId = params['clientId'];

      this.bus.publish(this.events.requested.data.supervision.clients.single, this.supervisionId);
    });
  }

  public ngOnDestroy(): void {
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
  }

}
