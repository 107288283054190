import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


const Languages = {
  'en': 'English',
  'de': 'Deutsch',
};

@Component({
  selector: 'language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    if (localStorage.getItem('lang'))
      this.setLanguage(localStorage.getItem('lang'));
  }

  public get current() {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  public get currentLanguage() {
    return Languages[this.current];
  }

  public change() {
    if (this.current == 'de') this.setLanguage('en');
    else this.setLanguage('de');

    setTimeout(() => window.location.reload(), 200);
  }

  public setLanguage(lang) {
    if (lang in Languages) {
      this.translate.use(lang);
      localStorage.setItem('lang', lang);
    }
  }
}
