import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';


@Component({
  selector: 'app-manage-invite-dialog',
  templateUrl: './manage-invite-dialog.component.html',
  styleUrls: ['./manage-invite-dialog.component.scss']
})
export class ManageInviteDialogComponent implements OnInit, OnDestroy {

  invite;
  public resending: boolean = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialogRef: MatDialogRef<ManageInviteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.invite = data.invite;
  }

  delete() {
    this.bus.publish(this.events.requested.action.controller.users.deleteInvite, this.invite.id);
  }

  resend() {
    this.bus.publish(this.events.requested.action.controller.users.resendInvite, this.invite.id);
    this.resending = true;
  }

  resent() {
    this.resending = false;
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  deleted() {
    this.dialogRef.close(true);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.controller.users.deleteInvite.success, this.deleted, this);
    this.bus.subscribe(this.events.received.action.controller.users.resendInvite.success, this.resent, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.controller.users.deleteInvite.success, this.deleted);
    this.bus.unsubscribe(this.events.received.action.controller.users.resendInvite.success, this.resent);
  }
}
