import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'page-not-found-component',
  templateUrl: './page-not-found.template.html',
  styleUrls: ['./page-not-found.styles.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {

  constructor() { }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {}
  
}
