import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';


export const roles = {
  dsb: 'org_roles.dsb',
  dst: 'org_roles.dst',
  admin: 'org_roles.admin',
  advisor: 'org_roles.advisor',
  dsbv: 'org_roles.dsbv',
  vtb: 'org_roles.vtb',
  anyone: 'org_roles.anyone',
};

export const hasRoleOrStronger = (role, userRole) => {
  if (!userRole) {
    return false;
  }

  const roleIndex = Object.values(roles).indexOf(role);
  const userRoleIndex = Object.values(roles).indexOf(userRole);
  return roleIndex >= userRoleIndex;
};

export const hasRoleOrWeaker = (role, userRole) => {
  if (!userRole) {
    return false;
  }

  const roleIndex = Object.values(roles).indexOf(role);
  const userRoleIndex = Object.values(roles).indexOf(userRole);
  return roleIndex <= userRoleIndex;
};


@Injectable()
export class AccessControlService {

  constructor(private auth: AuthenticationService) { }

  public hasRoleOrStronger(role: string): boolean {
    return hasRoleOrStronger(role, this.auth.role);
  }

  public hasRoleOrWeaker(role: string): boolean {
    return hasRoleOrWeaker(role, this.auth.role);
  }
}
