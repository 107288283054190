import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { Location } from '@angular/common';
import { CustomPaDialogConfirmationComponent } from '../custom-pa-dialog-confirmation/custom-pa-dialog-confirmation.component';

@Component({
  selector: 'app-custom-pa-dialog',
  templateUrl: './custom-pa-dialog.component.html',
  styleUrls: ['./custom-pa-dialog.component.scss']
})
export class CustomPaDialogComponent implements OnInit {

  formGroup: FormGroup;
  paId = null;
  departments = null;
  department = null;
  status: string;

  constructor(
            private location: Location,
            private router: Router,
            private activatedRoute: ActivatedRoute,
            private bus: BusService,
            private events: EventsService,
            private dialog: MatDialog,
            @Inject(MAT_DIALOG_DATA) public data: any,
            public dialogRef: MatDialogRef<CustomPaDialogComponent>,
          ) {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
      department: new FormControl('', [Validators.required]),
      description: new FormControl(''),
    });

    if (data) {
      this.paId = data.id;
      this.bus.publish(this.events.requested.data.rpa.customPa.get, {id: this.paId});
    }
  }

  ngOnInit() {
    this.subscribe();

    this.bus.publish(this.events.requested.data.departments.get);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  submit() {
    if (this.formGroup.valid) {
      if (!this.paId) {
        this.bus.publish(this.events.requested.data.rpa.customPa.add, this.formGroup.value);
      } else {
        this.bus.publish(this.events.requested.data.rpa.customPa.update,
          Object.assign({id: this.paId}, this.formGroup.value));
      }
    }
  }

  addSuccess(result) {
    this.paId = result.id;

    this.dialogRef.close();

    this.router.navigate([`/rpa/pa-details/${this.paId}`]);
  }

  updateSuccess() {
    this.dialogRef.close(true);
  }

  fetch(paData) {
    this.status = paData.status;
    this.formGroup.patchValue({
      name: paData.name,
      department: paData.department,
      description: paData.description,
    });
  }

  fetchFailed() {
    this.dialog.open(CustomPaDialogConfirmationComponent, {
      width: '350px',
      data: {
        message: 'failed',
        canStay: false,
        routingManually: true,
        failure: true,
      }
    }).afterClosed().subscribe(() => console.log('okay') /*this.location.back() */);
  }

  delete() {
    this.dialog.open(CustomPaDialogConfirmationComponent, {
      width: '350px',
      data: {
        message: 'delete-confirm',
        paName: this.formGroup.value.name,
        warning: true,
        confirmation: true,
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.bus.publish(this.events.requested.data.rpa.customPa.delete, {id: this.paId});
      }
    });
  }

  deleteSuccess() {
    this.dialog.open(CustomPaDialogConfirmationComponent, {
      width: '350px',
      data: {
        message: 'deleted',
        paName: this.formGroup.value.name,
        routingManually: true,
        canStay: false,
      }
    }).afterClosed().subscribe(() => {
      this.dialogRef.close();
      this.router.navigate(['/rpa']);
    });
  }

  updateDepartments(departments) {
    this.departments = departments;
  }

  private subscribe() {
    this.bus.subscribe(this.events.received.data.rpa.customPa.add.success, this.addSuccess, this);
    this.bus.subscribe(this.events.received.data.rpa.customPa.update.success, this.updateSuccess, this);
    this.bus.subscribe(this.events.received.data.rpa.customPa.delete.success, this.deleteSuccess, this);
    this.bus.subscribe(this.events.received.data.rpa.customPa.get.success, this.fetch, this);
    this.bus.subscribe(this.events.received.data.rpa.customPa.get.failure, this.fetchFailed, this);

    this.bus.subscribe(this.events.received.data.departments.get.success, this.updateDepartments, this);
  }

  private unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.rpa.customPa.add.success, this.addSuccess);
    this.bus.unsubscribe(this.events.received.data.rpa.customPa.update.success, this.updateSuccess);
    this.bus.unsubscribe(this.events.received.data.rpa.customPa.delete.success, this.deleteSuccess);
    this.bus.unsubscribe(this.events.received.data.rpa.customPa.get.success, this.fetch);
    this.bus.unsubscribe(this.events.received.data.rpa.customPa.get.failure, this.fetchFailed);
    this.bus.unsubscribe(this.events.received.data.departments.get.success, this.updateDepartments);
  }
}
