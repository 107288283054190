import { Component, OnInit } from '@angular/core';

import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';


@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(
    private bus: BusService,
    private events: EventsService,
  ) { }

  ngOnInit() {
  }

  public get docs() {
    return `assets/documents/${localStorage['lang'] || 'en'}`;
  }

  progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_intro',
    });
  }

}
