import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rpa-custom-pa-dialog',
  templateUrl: './custom-pa.rpa.dialog.template.html',
  styleUrls: ['./custom-pa-dialog-confirmation.component.scss']
})
export class CustomPaDialogConfirmationComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomPaDialogConfirmationComponent>,
  ) {}
}