import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EventsService } from 'app/services/events.service';
import { BusService } from 'app/services/bus.service';
import { MatDialog } from '@angular/material';
import { AddClientDialogComponent } from '../add-client-dialog/add-client-dialog.component';
import { ClientManagementService } from 'app/services/supervision/client-management.service';
import { DeleteClientDialogComponent } from '../delete-client-dialog/delete-client-dialog.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfig } from 'app/app.config';
import { FormControl, Validators } from '@angular/forms';
import { SupervisorProfileService } from '../../../services/supervision/supervisor-profile.service';

@Component({
  selector: 'app-supervision-supervisor-profile',
  templateUrl: './supervisor-profile.component.html',
  styleUrls: ['./supervisor-profile.component.scss']
})
export class SupervisorProfileComponent implements OnInit, OnDestroy {

  @ViewChild('upload', { static: false }) upload;
  displayName = new FormControl('', [Validators.required]);

  profile: any;

  loading = false;
  updateIcon: string = undefined;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private profileService: SupervisorProfileService
  ) {

  }

  setProfile(profile) {
    if (profile) {
      this.displayName.setValue(profile.displayName);
      if (profile.logoPath) {
        this.updateLogo();
      }
    }
  }

  public updateLogo() {
    this.upload.load(
      `${AppConfig.apiUrl}/supervision/supervisor/profile/logo/` +
      `?token=${localStorage.getItem('currentUser')}`);
  }

  submit() {
    this.loading = true;
    this.bus.publish(this.events.requested.action.supervision.supervisor.profile.update, { displayName: this.displayName.value });
    this.displayName.markAsPristine();
  }

  updateSuccess() {
    this.loading = false;
    this.updateIcon = 'done';
  }

  updateFailure() {
    this.loading = false;
    this.updateIcon = 'error_outline';
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.supervision.supervisor.profile.success, this.setProfile, this);
    this.bus.subscribe(this.events.received.action.supervision.supervisor.profile.update.success, this.updateSuccess, this);
    this.bus.subscribe(this.events.received.action.supervision.supervisor.profile.update.failure, this.updateFailure, this);
  }

  public ngOnInit(): void {
    this.subscribe();
    this.bus.publish(this.events.requested.data.supervision.supervisor.profile);
  }

  public ngOnDestroy(): void {
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
  }

}
