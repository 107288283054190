import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';
import { Router } from '@angular/router';

@Injectable()
export class ClientSupervisionService {

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService,
    private router: Router
  ) {

  }

  public startSupervision(token: string) {
    // Supervisor Token Backup
    const supervisorToken = localStorage.getItem('currentUser');

    localStorage.setItem('supervisorToken', supervisorToken);

    localStorage.setItem('currentUser', token);
    localStorage.setItem('currentUserProductType', 'default');
    localStorage.setItem('isInSupervisionMode', 'true');

    // Tell subscribing services (especially AuthenticationService) about the new token.
    this.bus.publish(this.events.notified.supervision.client.changed, { newToken: token });
    this.bus.publish(this.events.notified.supervision.client.started);

    this.router.navigate(['/todolist']);
  }

  public endSupervision(reroute: boolean = true) {
    const supervisorToken = localStorage.getItem('supervisorToken');

    localStorage.setItem('currentUser', supervisorToken);
    localStorage.setItem('currentUserProductType', 'supervisor');
    localStorage.setItem('isInSupervisionMode', 'false');

    localStorage.removeItem('isInSupervisionMode');
    localStorage.removeItem('supervisorToken');
    // EE-1586: Under the assumption that supervisors don't expire, clean up expiry.
    localStorage.removeItem('isExpired');

    this.bus.publish(this.events.notified.supervision.client.changed, { newToken: supervisorToken });
    this.bus.publish(this.events.notified.supervision.client.ended);

    if (reroute)
      this.router.navigate(['/supervision/clients']);
  }
}
