import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../services/authentication.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('controllerLogo', { static: false }) private controllerLogo: ElementRef;

  private _expanded = false;
  private _restExpanded = false;

  constructor(
    private router: Router,
    private bus: BusService,
    private events: EventsService,
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }

  public get route() {
    return this.router.url;
  }

  get loggedIn() {
    return localStorage.getItem('currentUser');
  }

  get logoLink() {
    return 'todolist';
  }

  public get expanded() {
    return this._expanded;
  }

  public expand() {
    if (!this._restExpanded) {
      this._restExpanded = true;
      this.tempExpand();
    }
  }

  public collapse() {
    if (this._restExpanded) {
      this._restExpanded = false;
      this.tempCollapse();
    }
  }

  public tempExpand() {
    if (!this._expanded) {
      this._expanded = true;
      this.bus.publish(this.events.ui.occured.sidenav.expand);
    }
  }

  public tempCollapse() {
    if (this._expanded) {
      this._expanded = false || this._restExpanded;
      if (!this._expanded) {
        this.bus.publish(this.events.ui.occured.sidenav.collapse);
      }
    }
  }

  ngOnInit() {
    this.bus.subscribe(this.events.ui.requested.sidenav.expand, this.expand, this);
    this.bus.subscribe(this.events.ui.requested.sidenav.collapse, this.collapse, this);
    this.bus.subscribe(this.events.notified.user.logoUpdate, this.updateControllerLogo, this);
    this.bus.subscribe(this.events.notified.user.logout, this.updateControllerLogo, this);
    this.bus.subscribe(this.events.received.authentication.success, this.updateControllerLogo, this);
    this.bus.subscribe(this.events.notified.supervision.client.changed, this.updateControllerLogo, this);
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.events.ui.requested.sidenav.expand, this.expand);
    this.bus.unsubscribe(this.events.ui.requested.sidenav.collapse, this.collapse);
    this.bus.unsubscribe(this.events.notified.user.logoUpdate, this.updateControllerLogo);
    this.bus.unsubscribe(this.events.notified.user.logout, this.updateControllerLogo);
    this.bus.unsubscribe(this.events.received.authentication.success, this.updateControllerLogo);
    this.bus.unsubscribe(this.events.notified.supervision.client.changed, this.updateControllerLogo);
  }

  ngAfterViewInit() {
    this.updateControllerLogo();
  }

  updateControllerLogo() {
    // if (localStorage.getItem('currentUser')) {
    //   this.controllerLogo.nativeElement.src =
    //     `${AppConfig.apiUrl}/controllers/logo/` +
    //     `?token=${localStorage.getItem('currentUser')}` +
    //     `&${new Date().getTime()}`
    //     ;
    // } else {
    //   this.controllerLogo.nativeElement.src = '#';
    // }
  }
}
