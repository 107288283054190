import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {SafeResourceUrl, DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';

import {BusService} from '../../../services/bus.service';
import {EventsService} from '../../../services/events.service';

import {AppConfig} from '../../../app.config';


@Component({
  selector: 'app-milestone-one',
  templateUrl: './milestone-one.component.html',
  styleUrls: ['./milestone-one.component.scss']
})
export class MilestoneOneComponent implements OnInit, OnDestroy {

  @ViewChild('goalsUpload', { static: false }) goalsUpload;
  @ViewChild('dpoUpload', { static: false }) dpoUpload;

  milestoneProgress = 0;
  controller = undefined;
  dpo = undefined;
  departments = undefined;

  constructor(
    private events: EventsService,
    private bus: BusService,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.todos.milestoneOneProgress);
    this.bus.publish(this.events.requested.data.controller.get);
    this.bus.publish(this.events.requested.data.dpo.get);
    this.bus.publish(this.events.requested.data.departments.get);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  updateMilestoneProgress(data) {
    if (data.aggregate)
      this.milestoneProgress = data.aggregate;
  }

  updateController(data) {
    this.controller = data;
    setTimeout(() => {
      if (this.goalsUpload)
        this.goalsUpload.load(
          `${AppConfig.apiUrl}/controllers/goals/` +
          `?token=${localStorage.getItem('currentUser')}`);
    }, 200);
  }

  updateDpo(data) {
    this.dpo = data;
    setTimeout(() => {
      if (this.dpoUpload)
        this.dpoUpload.load(
          `${AppConfig.apiUrl}/dpo/appointment/` +
          `?token=${localStorage.getItem('currentUser')}`);
    }, 200);
  }

  updateDepartments(data) {
    this.departments = data;
  }

  googleMapsUrl(): SafeResourceUrl {
    if (this.controller) {
      let address = `${this.controller.controllerAddressStreet}, ` +
        `${this.controller.controllerAddressPostcode} ` +
        `${this.controller.controllerAddressCity},` +
        ` ${this.controller.controllerAddressCountry}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyBuRSXd5-JmVLAkFCPHd7_QQpWmi_5gfRo` +
        `&q=${encodeURIComponent(address)}`);
    }
    return undefined;
  }

  public progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_milestone_i',
    });
  }

  continue() {
    this.progress(1);
    this.router.navigate(['']);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.todos.milestoneOne.success, this.updateMilestoneProgress, this);
    this.bus.subscribe(this.events.received.data.controller.get.success, this.updateController, this);
    this.bus.subscribe(this.events.received.data.dpo.get.success, this.updateDpo, this);
    this.bus.subscribe(this.events.received.data.departments.get.success, this.updateDepartments, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.todos.milestoneOne.success, this.updateMilestoneProgress);
    this.bus.unsubscribe(this.events.received.data.controller.get.success, this.updateController);
    this.bus.unsubscribe(this.events.received.data.dpo.get.success, this.updateDpo);
    this.bus.unsubscribe(this.events.received.data.departments.get.success, this.updateDepartments);
  }
}
