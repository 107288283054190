import { Component, OnInit, OnDestroy, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { AppConfig } from '../../../../app.config';
import { BusService } from '../../../../services/bus.service';
import { EventsService } from '../../../../services/events.service';

import { Router } from '@angular/router';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { DocumentResourceService } from '../../../../services/documents/document-resource.service';
import { UserManagementService } from 'app/services/user/user-management.service';

@Component({
  selector: 'app-documents-upload-dialog',
  templateUrl: './documents-upload-dialog.component.html',
  styleUrls: ['./documents-upload-dialog.component.scss']
})
export class DocumentsUploadDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('upload', { static: false }) upload;

  appConfig = AppConfig;

  fileName = new FormControl('', []);
  description = new FormControl('', []);
  tags: Array<string> = [];

  documentId: number = undefined;

  isExistingDocument = false;

  showHistory = false;
  loadingHistory = true;
  historyEntries = [];
  users = [];
  uploadKey = 'document';

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DocumentsUploadDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private userService: UserManagementService,
    private docService: DocumentResourceService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.setExistingData();

    if (this.data && this.data.uploadKey) {
      this.uploadKey = this.data.uploadKey;
    }
  }

  setExistingData() {
    if (this.data && this.data.isExistingDocument) {
      this.fileName.setValue(this.data.document.fileName);
      this.description.setValue(this.data.document.description);
      this.tags = this.data.document.tags;
      this.isExistingDocument = true;
      this.documentId = this.data.document.id;
    }
  }

  ngOnInit() {
    this.subscribe();
    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.isExistingDocument) {
      this.upload.load(AppConfig.documentDownloadUrl + this.documentId + `/file?token=${localStorage.getItem('currentUser')}`);
      this.bus.publish(this.events.requested.data.documents.history, this.documentId);

      this.loadingHistory = true;
    }
  }

  updateDocument(response: HttpResponse<any>) {
    if (response.ok && response.body) {
      this.documentId = response.body.id;
      this.fileName.setValue(response.body.fileName);
    }
  }

  update() {
    if (this.documentId) {
      this.bus.publish(this.events.requested.action.documents.update, {
        id: this.documentId,
        fileName: this.fileName.value,
        description: this.description.value,
        tags: this.tags
      });
    }
  }

  updated() {
    this.dialogRef.close(true);
  }

  updateFailed(error) {
    console.error(error);
  }

  uploadUrl() {
    if (this.isExistingDocument) {
      return `/document/${this.documentId}/file`;
    } else if (this.data && this.data.uploadUrl) {
      return this.data.uploadUrl;
    } else {
      return '/document/file';
    }
  }

  hasReason(historyItem) {
    return historyItem.reason != null;
  }

  toggleShowHistory() {
    this.showHistory = !this.showHistory;
  }

  receiveDocumentHistory(history) {
    this.loadingHistory = false;
    this.historyEntries = history;
    this.orderArray(false);
  }

  documentHistoryFailure() {
    this.loadingHistory = false;
    this.historyEntries = [];
  }

  downloadLink(historyItem) {
    return this.appConfig.documentDownloadUrl + historyItem.documentId + `/history/${historyItem.id}`
            + `/file?token=${localStorage.getItem('currentUser')}`;
  }

  orderArray(ascending: boolean) {
    this.historyEntries = this.historyEntries.sort((a, b) => {
      if (ascending) {
        const c = a;
        a = b;
        b = c;
      }

      return (new Date(b.originallyUploadedAt) as any) - (new Date(a.originallyUploadedAt) as any);
    });
  }

  uploaderName(document) {
    const candidates = this.users.filter(user => user.id === document.uploaderId);
    if (candidates.length > 0) {
      return candidates[0].name;
    }
    return '---';
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.documents.update.success, this.updated, this);
    this.bus.subscribe(this.events.received.action.documents.update.failure, this.updateFailed, this);

    this.bus.subscribe(this.events.received.data.documents.history.success, this.receiveDocumentHistory, this);
    this.bus.subscribe(this.events.received.data.documents.history.failure, this.documentHistoryFailure, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.documents.update.success, this.updated);
    this.bus.unsubscribe(this.events.received.action.documents.update.failure, this.updateFailed);
  }
}
