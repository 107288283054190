import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';

import { ControllerType, SettingsType } from '../../components/supervision/templates/types';
import { TemplatingEvent,
        CreationCanceledEvent, CreatingTemplateEvent, TemplateCreatedEvent,
        UpdatingTemplateEvent, TemplateUpdatedEvent,
        ApplicationCanceledEvent, ApplyingTemplateEvent, TemplateAppliedEvent
      } from '../../components/supervision/templates/events';

import { ApplyTemplateDialogComponent } from '../../components/supervision/templates/apply-template-dialog/apply-template-dialog.component';
import { CreateTemplateDialogComponent } from '../../components/supervision/templates/create-template-dialog/create-template-dialog.component';
import { TemplateInfoDialogComponent } from '../../components/supervision/templates/template-info-dialog/template-info-dialog.component';

import { SupervisionTemplatesBackendService,
          CreateTemplateResponse
        } from './templates-backend.service';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';


@Injectable()
export class SupervisionTemplatesService {

  constructor(
    private dialog: MatDialog,
    private backend: SupervisionTemplatesBackendService,
  ) {}

  /**
   *
   * will start the process of creating a template from given
   * controller by opening a dialog and then making the proper request to
   * the backend.
   *
   * @returns an event emitter for tracking the process.
   *
   */
  public createTemplate(controller: ControllerType): EventEmitter<TemplatingEvent> {
    let emitter = new EventEmitter<TemplatingEvent>(true);

    this.dialog.open(CreateTemplateDialogComponent, {
      width: '480px',
      data: { controller }
    }).afterClosed().subscribe(result => {
      if (!result) {
        //
        // user decided to cancel the template creation process.
        //

        emitter.emit(new CreationCanceledEvent(controller));
      }
      else if (!result.original) {
        //
        // there is no original template, so we need to create
        // a new one.
        //

        let event = new CreatingTemplateEvent(controller, result.templateName, result.settings);
        emitter.emit(event);
        this.dialog.open(TemplateInfoDialogComponent, {
          width: '350px',
          data: { event, emitter }
        });

        this.backend.create(
          event.controller.controllerId,
          event.templateName,
          event.settings
        ).subscribe((response: CreateTemplateResponse) => {
          let createdEvent = new TemplateCreatedEvent(response.templateId, event);

          setTimeout(() => {
            emitter.emit(createdEvent);
          }, 1000);
        });
      }
      else {
        //
        // there is an original template given, so we need to update
        // the template.
        //

        let event = new UpdatingTemplateEvent(controller, result.original, result.templateName, result.settings);

        emitter.emit(event);
        this.dialog.open(TemplateInfoDialogComponent, {
          width: '350px',
          data: { event, emitter }
        });

        this.backend.update(
          event.template.id,
          event.controller.controllerId,
          event.newTemplateName,
          event.settings
        ).subscribe(() => {
          let updatedEvent = new TemplateUpdatedEvent(event);

          setTimeout(() => {
            emitter.emit(updatedEvent);
          }, 1000);
        });
      }
    });

    return emitter;
  }

  /**
   *
   * will start the process of applying a template to given
   * controller by opening a dialog and then making the proper request to
   * the backend.
   *
   * @returns an event emitter for tracking the process.
   *
   */
  public applyTemplate(controller: ControllerType): EventEmitter<TemplatingEvent> {
    let emitter = new EventEmitter<TemplatingEvent>();

    this.dialog.open(ApplyTemplateDialogComponent, {
      width: '480px',
      data: {
        controller
      }
    }).afterClosed().subscribe(result => {
      if (!result) emitter.emit(new ApplicationCanceledEvent(controller));
      else {
        let event = new ApplyingTemplateEvent(controller, result.template, result.settings);

        emitter.emit(event);
        this.dialog.open(TemplateInfoDialogComponent, {
          width: '350px',
          data: { event, emitter }
        });

        this.backend.apply(event.template.id, event.controller.controllerId, event.settings)
          .subscribe(() => {
            let appliedEvent = new TemplateAppliedEvent(event);

            setTimeout(() => {
              emitter.emit(appliedEvent);
            }, 1000);
          });
      }
    });

    return emitter;
  }
}
