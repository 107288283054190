import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';

import { TodosService, Todo } from 'app/services/todos/todos.service';

import { TodoDetailDialogComponent } from '../todo-detail-dialog/todo-detail-dialog.component';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'todo-card',
  templateUrl: './todo-card.component.html',
  styleUrls: ['./todo-card.component.scss']
})
export class TodoCardComponent implements OnInit {

  @Input() todo: Todo;
  @Input() doneButton = true;
  @Input() assignee = false;
  @Input() issuer = false;
  @Input() dontTouchURL = false;  // the default would be to pass a query parameter, but sometimes that is NOT what we want
  @Output() todoChange = new EventEmitter<Todo>();

  constructor(
    private service: TodosService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  done(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.service.done(this.todo).subscribe(() => {
      this.todoChange.emit(this.todo);
    });
  }

  get now() { return new Date(); }

  open() {
    this.dialog.open(TodoDetailDialogComponent, {
      width: '700px',
      data: { todo: this.todo, dontTouchURL: this.dontTouchURL }
    })
    .afterClosed().subscribe(mutated => {
      if (mutated) {
        this.todoChange.emit(this.todo);
      }
    });
  }
}
