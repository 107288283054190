
import {tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import { Router } from "@angular/router";
import { BusService } from "../services/bus.service";
import { EventsService } from "../services/events.service";


/*
  Using interceptors is all about changing outgoing requests and incoming responses
  Here we are using it for attaching authentication information(token)
*/
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private bus: BusService,
    private events: EventsService,
    private router: Router,
) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith("/account/authenticate")) {
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        // this.router.navigate(["rpa"]);
        this.bus.publish(this.events.notified.request.finished);
      }));
    }
    if (req.url.endsWith("/account/registration")) {
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        // this.router.navigate(["verification"]);
        this.bus.publish(this.events.notified.request.finished);
      }));
    }
    if (req.url.endsWith('/account/send-password-reset-email')) {
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        this.bus.publish(this.events.notified.request.finished);
      }));
    }
    if (req.url.endsWith('/account/email-auth')) {
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        this.bus.publish(this.events.notified.request.finished);
      }));
    }
    if (req.url.endsWith('/account/send-verification-email')) {
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        this.bus.publish(this.events.notified.request.finished);
      }));
    }
    const token = this.authenticationService.token;
    if (token) {
      const tokenParameter = req.params.set("token", token);
      const modifiedRequest = req.clone({params: tokenParameter});
      return next.handle(modifiedRequest).pipe(tap((event: HttpEvent<any>) => {
        this.bus.publish(this.events.notified.request.finished);
        this.bus.publish(this.events.notified.saving.finished);
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.bus.publish(this.events.notified.user.logout);
          } else {
            //console.log("Another error: " + err.status, err.message)
          }
        }
        this.bus.publish(this.events.notified.request.finished);
        this.bus.publish(this.events.notified.saving.finished);
      }));
    } else {
      this.bus.publish(this.events.notified.request.finished);
      this.bus.publish(this.events.notified.saving.finished);
      return next.handle(req);
    }
  }
}
