import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { EventsService } from 'app/services/events.service';
import { BusService } from 'app/services/bus.service';
import { MatDialog } from '@angular/material';
import { AppConfig } from '../../../app.config';
import { DocumentsUploadDialogComponent } from '../modals/upload/documents-upload-dialog.component';
import { DocumentResourceService } from '../../../services/documents/document-resource.service';
import { UserService } from '../../../services/user/user.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { DeleteDocumentDialogComponent } from '../modals/delete/delete-document-dialog.component';
import { DocumentTaglistDialogComponent } from '../modals/taglist/document-taglist-dialog.component';
import { DocumentHistoryDialogComponent } from '../modals/history/document-history-dialog.component';


@Component({
  selector: 'app-documents-container',
  templateUrl: './documents-container.component.html',
  styleUrls: ['./documents-container.component.scss']
})
export class DocumentsContainerComponent implements OnInit, OnDestroy {
  appConfig = AppConfig;

  filter: string;

  @Input() readonly = false;
  @Input() canDelete = false;
  @Input() showHistoryButton = true;

  @Input() align = 'center';
  @Input() searchbox = false;
  @Input('upload-url') uploadUrl;
  @Input('upload-key') uploadKey = 'document';
  @Input() source = [];
  @Output() documentChanged = new EventEmitter<any>();

  users = [];

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private docService: DocumentResourceService,
    private userService: UserManagementService,
  ) {

  }

  get documents() {
    return this.filtered(this.source);
  }

  private filtered(documentList) {
    if (this.filter) {
      const match = key => key.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
      return documentList.filter(document => {
        if (document.fileName && match(document.fileName)) {
          return true;
        }

        const tags = document.tags.map((tag) => tag.value);
        const matches = tags.filter(s => s.includes(this.filter));

        if (matches.length > 0) {
          return true;
        }

        return false;
      });
    } else {
      return documentList;
    }
  }

  uploaderName(document) {
    const candidates = this.users.filter(user => user.id === document.uploaderId);
    if (candidates.length > 0) {
      return candidates[0].name;
    }
    return '---';
  }

  downloadLink(document) {
    return this.appConfig.documentDownloadUrl + document.id + `/file?token=${localStorage.getItem('currentUser')}`;
  }

  docImage(document) {
    if (document.extension) {
      const ext = document.extension.toLowerCase();
      if (ext === 'pdf') { return 'assets/docs/pdf.svg'; }
      if (ext === 'doc' || ext === 'docx') { return 'assets/docs/doc.svg'; }
      if (ext === 'xls' || ext === 'xlsx') { return 'assets/docs/xls.svg'; }
    }

    return 'assets/docs/generic.svg';
  }

  limitTags(tags) {
    if (tags.length > 2) {
      return tags.slice(0, 2);
    }

    return tags;
  }

  showTags(document) {
    this.dialog.open(DocumentTaglistDialogComponent, {
      width: '350px',
      data: {
        document: document
      }
    });
  }

  editDocument(document, event) {
    if (this.readonly) { return; }
    if (event) { event.stopPropagation(); }

    this.dialog.open(DocumentsUploadDialogComponent, {
      width: '700px',
      data: {
        isExistingDocument: true,
        document: document,
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.documentChanged.emit();
      }
    });
  }

  showHistory(document, event) {
    if (event) { event.stopPropagation(); }

    this.dialog.open(DocumentHistoryDialogComponent, {
      width: '700px',
      data: {
        document: document
      }
    });
  }

  deleteDocument(document, event) {
    if (event) { event.stopPropagation(); }

    this.dialog.open(DeleteDocumentDialogComponent, {
      width: '400px',
      data: {
        document: document
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.documents.all);
        this.documentChanged.emit();
      }
    });
  }

  public addDocument() {
    this.dialog.open(DocumentsUploadDialogComponent, {
      width: '700px',
      data: {
        uploadUrl: this.uploadUrl,
        uploadKey: this.uploadKey,
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.documents.all);
        this.documentChanged.emit();
      }
    });
  }

  public ngOnInit(): void {
    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });
  }

  public ngOnDestroy(): void {
  }

}
