import { Injectable } from '@angular/core';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import { RequestService } from '../request.service';

export interface RecordsModel {
  id?: number; // optional because server sets it, same for others
  title: string;
  details?: string;
  controllerId?: number;
  createdBy?: number;
  createdAt?: Date;
  status?: string;
  type: string;
  prefix: string;
  metadata?: any;
  department?: number;
  // convenience properties on the frontend:
  refNum?: string;
  firstName?: string;
  lastName?: string;
  documents?: [any];
}

@Injectable()
export class RecordsResourceService {

  api = {
    root: '/records',
    update: '/records',
    create: '/records',
    getAll: '/records',
    getSingle: '/records',
    delete: '/records'
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getSingle(model: RecordsModel) {
    return this.requestService.get<any>({
      uri: this.api.getSingle + '/' + model.type + '/' + model.id,
      handlers: {
        success: records => {
          this.bus.publish(this.events.received.data.records.single.success, records);
        },
        error: error => {
          this.bus.publish(this.events.received.data.records.single.failure, error);
        },
      }
    });
  }

  getAll(_type: string) {
    return this.requestService.get<RecordsModel>({
      uri: this.api.getAll + '/' + _type,
      handlers: {
        success: records => {
          this.bus.publish(this.events.received.data.records.all.success, records);
        },
        error: error => {
          this.bus.publish(this.events.received.data.records.all.failure, error);
        }
      },
    });
  }

  update(model: RecordsModel) {
    return this.requestService.put<RecordsModel, any>({
      uri: this.api.update + '/' + model.type + '/' + model.id,
      body: model,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.records.update.success, response);
        },
        error: error => {
          this.bus.publish(this.events.received.action.records.update.failure, error.error);
        },
      }
    });
  }

  create(model: RecordsModel) {
    return this.requestService.post<RecordsModel, any>({
      uri: this.api.create + '/' + model.type,
      body: model,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.records.create.success, response);
        },
        error: error => {
          this.bus.publish(this.events.received.action.records.create.failure, error.error);
        },
      }
    });
  }

  delete(model: RecordsModel) {
    return this.requestService.delete<any>({
      uri: this.api.delete + '/' + model.type + '/' + model.id,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.action.records.delete.success, response);
        },
        error: error => {
          this.bus.publish(this.events.received.action.records.delete.failure, error.error);
        },
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.action.records.create, this.create.bind(this));
    this.bus.subscribe(this.events.requested.action.records.update, this.update.bind(this));
    this.bus.subscribe(this.events.requested.action.records.delete, this.delete.bind(this));
    this.bus.subscribe(this.events.requested.data.records.all, this.getAll.bind(this));
    this.bus.subscribe(this.events.requested.data.records.single, this.getSingle.bind(this));
  }
}
