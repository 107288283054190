import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { VendorSearchDialogComponent } from '../../pa-external-processors/vendor-search-dialog/vendor-search-dialog.component';
import { RpaExternallyProcessedService } from 'app/services/rpa/rpa.externally-processed.service';
import { VendorsService } from 'app/services/vendors/vendors.service';

@Component({
  selector: 'app-external-processing',
  templateUrl: './external-processing.component.html',
  styleUrls: ['./external-processing.component.scss']
})
export class ExternalProcessingComponent implements OnInit {
  @Input() paId: number;
  @Input() readonly = false;
  @Input() vendorAssociations = [];
  @Output() vendorAssociationsChange = new EventEmitter<any[]>();

  vendors = [];

  constructor(
    private dialog: MatDialog,
    private externallyProcessed: RpaExternallyProcessedService,
    private vendorsService: VendorsService,
  ) { }

  addVendor() {
    if (!this.readonly) {
      this.dialog.open(VendorSearchDialogComponent, {
        width: '512px',
      }).afterClosed().subscribe(vendor => {
        if (vendor) {
          if (vendor.added) {
            this.vendors.push(vendor);
          }

          this.externallyProcessed.associate({
            paId: this.paId,
            vendorId: vendor.id,
          }).subscribe(() => {
            if (!this.isAssociatedVendor(vendor)) {
              this.vendorAssociations = this.vendorAssociations.concat([{
                paId: this.paId,
                vendorId: vendor.id,
              }]);

              this.vendorAssociationsChange.emit(this.vendorAssociations);
            }
          });
        }
      });
    }
  }

  isAssociatedVendor(vendor) {
    return this.vendorAssociations.some(entry => entry.paId === this.paId && entry.vendorId === vendor.id);
  }

  get associatedVendors() {
    const associatedVendorIds = this.vendorAssociations
      .filter(entry => entry.paId === this.paId)
      .map(entry => entry.vendorId);
    return this.vendors.filter(vendor => associatedVendorIds.includes(vendor.id));
  }

  removeVendor(vendor) {
    if (!this.readonly) {
      this.externallyProcessed.disassociate({
        paId: this.paId,
        vendorId: vendor.id,
      }).subscribe(() => {
        this.vendorAssociations = this.vendorAssociations.filter(
          entry => entry.paId !== this.paId || entry.vendorId !== vendor.id);
        this.vendorAssociationsChange.emit(this.vendorAssociations);
      });
    }
  }

  ngOnInit() {
    this.externallyProcessed.paAssociations(this.paId).subscribe(response => {
      this.vendorAssociations = response;
      this.vendorAssociationsChange.emit(this.vendorAssociations);
    });

    this.vendorsService.get().subscribe(response => {
      this.vendors = response;
    });
  }

}
