import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material';

import { EventsService } from '../../../../services/events.service';
import { BusService } from '../../../../services/bus.service';


@Component({
  selector: 'app-vendor-add-dialog',
  templateUrl: './vendor-add-dialog.component.html',
  styleUrls: ['./vendor-add-dialog.component.scss']
})
export class VendorAddDialogComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;

  constructor(
    private bus: BusService,
    private events: EventsService,
    public dialogRef: MatDialogRef<VendorAddDialogComponent>,
  ) {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
      addressStreet: new FormControl('', [Validators.required]),
      addressPostcode: new FormControl('', [Validators.required]),
      addressCity: new FormControl('', [Validators.required]),
      addressCountry: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  save() {
    Object.values(this.formGroup.controls).forEach(control => control.markAsTouched());
    if (this.formGroup.valid) {
      this.bus.publish(this.events.requested.data.vendors.add, this.formGroup.value);
    }
  }

  saved(event) {
    this.dialogRef.close(event);
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.vendors.add.success, this.saved, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.vendors.add.success, this.saved);
  }
}
