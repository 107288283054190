import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BusService } from "./bus.service";
import {
  IGetOptions,
  RequestService
} from "./request.service";
import { EventsService } from "./events.service";


interface IGetResponse {

}

@Injectable()
export class AdminBroadcastService {

  private options: IGetOptions<IGetResponse>;
  private router: string;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: "/adminbroadcast",
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: IGetResponse): void {
    this.bus.publish(this.events.received.data.messageBroadcast.success, data);
    //console.log("data ===>>>",data )
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.data.messageBroadcast.failure, httpErrorResponse);
  }

  private setRouter(router: string): void {
    this.router = router
  }

  private request(): void {
    this.bus.publish(this.events.notified.request.starting);
    this.requestService.get<IGetResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.messageBroadcast, this.request.bind(this));
  }

}
