import {Injectable} from '@angular/core';
import {BusService} from '../services/bus.service';
import {EventsService} from '../services/events.service';
import * as decode from 'jwt-decode';

import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  // prevents unauthorized access to restricted routes

  constructor(
    private router: Router,
    private bus: BusService,
    private events: EventsService,
  ) {
    const token = localStorage.getItem('currentUser');
    if (token) {
      this.checkToken(token);

      /*
       * EE-1598: To prevent weird navigation bugs, we need to check if the supervisor's token is expired too.
       * Otherwise, they will see weird behaviour when leaving supervision (glitchy navigation).
       */
      if (localStorage.getItem('isInSupervisionMode')) {
        this.checkToken(localStorage.getItem('supervisorToken'));
      }
    }
  }

  private checkToken(token) {
    if (!token) {
      return;
    }

    const decodedToken = decode(token);

    if (decodedToken.exp) { // this is temporary because old token does not have exp in it
      if (decodedToken.exp < new Date().getTime()) { // check token expiry and current time
        console.log('token expired on', new Date(decodedToken.exp));
        this.bus.publish(this.events.notified.user.logout);
      }
    } else {
      this.bus.publish(this.events.notified.user.logout);
    }
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // his.bus.subscribe(this.events.received.data.isUserExpired.success, this.setIsUserExpired, this);
    if (localStorage.getItem('currentUser')) {

      // the user should be able to access the payment section
      if (route.routeConfig.path.indexOf('payment') != -1) {
        return true;
      }

      // the user might be expired on their own but still can join
      // another company.
      //
      if (route.routeConfig.path.indexOf('confirm-invitation') != -1) {
        return true;
      }

      // this.router.navigate(['/']);
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['login'], {
      queryParams: Object.assign({next: route.url.join('/')}, route.queryParams)
    });
    return false;
  }
}
