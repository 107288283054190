import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { BusService } from "../../services/bus.service";
import { EventsService } from "../../services/events.service";

import ForgotPasswordModel from './forgot-password.model';
import ForgotPasswordForm from './forgot-password.form';

@Component({
  selector: 'forgot-password-component',
  templateUrl: './forgot-password.template.html',
  styleUrls: ['./forgot-password.styles.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public model: ForgotPasswordModel;
  public form: ForgotPasswordForm;
  public state: string = 'initial';
  public sending: boolean = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
  ) {
    this.model = new ForgotPasswordModel();
    this.form = new ForgotPasswordForm(this.model);
  }

  private invalidForm(): void {
    this.form.validate();
  }

  private submit(): void {
    this.bus.publish(this.events.notified.validation.form.aggregation, this.form.isValid);
    this.bus.publish(this.events.notified.validation.form.action);
  }

  private sendEmail(): void {
    if (this.sending)
      return;

    this.sending = true;
    this.bus.publish(this.events.requested.action.resetPasswordEmail, this.model);

    setTimeout(()=>{
      this.state = 'sent';
      this.sending = false;
    }, 1000);
  }

  private sendEmailSuccess(): void {}

  private sendEmailFailure(): void {}

  public subscribe(): void {
    this.bus.subscribe(this.events.received.validation.failure, this.invalidForm, this);
    this.bus.subscribe(this.events.received.validation.success, this.sendEmail, this);
    this.bus.subscribe(this.events.received.action.resetPasswordEmail.success, this.sendEmailSuccess, this);
    this.bus.subscribe(this.events.received.action.resetPasswordEmail.failure, this.sendEmailFailure, this);
  }

  public unsubscribe(): void {
    this.bus.unsubscribe(this.events.received.validation.failure, this.invalidForm);
    this.bus.unsubscribe(this.events.received.validation.success, this.sendEmail);
    this.bus.unsubscribe(this.events.received.action.resetPasswordEmail.success, this.sendEmailSuccess);
    this.bus.unsubscribe(this.events.received.action.resetPasswordEmail.failure, this.sendEmailFailure);
  }

  public ngOnInit(): void {
    this.subscribe();
    this.bus.publish(this.events.ui.requested.sidenav.expand);
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
  }
}
