import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { BusService } from '../../../../services/bus.service';
import { EventsService } from '../../../../services/events.service';
import { DocumentResourceService } from '../../../../services/documents/document-resource.service';
import { AppConfig } from 'app/app.config';
import { UserService } from 'app/services/user/user.service';
import { UserManagementService } from 'app/services/user/user-management.service';


@Component({
  selector: 'app-document-history-dialog',
  templateUrl: './document-history-dialog.component.html',
  styleUrls: ['./document-history-dialog.component.scss']
})
export class DocumentHistoryDialogComponent implements OnInit, OnDestroy {

  document: any;

  isLoading = false;
  historyEntries = null;

  users = [];

  constructor(
    private dialogRef: MatDialogRef<DocumentHistoryDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private userService: UserManagementService,
    private docService: DocumentResourceService
  ) {
    this.document = data.document;
  }

  get isIntialized() {
    if (this.historyEntries) {
      return true;
    }

    return false;
  }

  get hasItems() {
    if (this.historyEntries) {
      return this.historyEntries.length > 0;
    }

    return false;
  }

  hasReason(historyItem) {
    return historyItem.reason != null;
  }

  receiveDocumentHistory(history) {
    this.isLoading = false;
    this.historyEntries = history;
    this.orderArray(false);
  }

  orderArray(ascending: boolean) {
    this.historyEntries = this.historyEntries.sort((a, b) => {
      if (ascending) {
        const c = a;
        a = b;
        b = c;
      }

      return (new Date(b.originallyUploadedAt) as any) - (new Date(a.originallyUploadedAt) as any);
    });
  }

  uploaderName(document) {
    const candidates = this.users.filter(user => user.id === document.uploaderId);
    if (candidates.length > 0) {
      return candidates[0].name;
    }
    return '---';
  }

  documentHistoryFailure() {
    this.isLoading = false;
    this.historyEntries = [];
  }

  downloadLink(historyItem) {
    return AppConfig.documentDownloadUrl + historyItem.documentId + `/history/${historyItem.id}`
            + `/file?token=${localStorage.getItem('currentUser')}`;
  }

  ngOnInit() {
    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });

    this.bus.subscribe(this.events.received.data.documents.history.success, this.receiveDocumentHistory, this);
    this.bus.subscribe(this.events.received.data.documents.history.failure, this.documentHistoryFailure, this);

    this.bus.publish(this.events.requested.data.documents.history, this.document.id);
  }

  ngOnDestroy() {

  }
}
