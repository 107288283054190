import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { ActionsService } from 'app/services/actions/actions.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { Event } from '../../event';
import { DepartmentService } from 'app/services/departments/department.service';

@Component({
  selector: 'app-actions-metadata-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class ActionsMetadataVendorsComponent implements OnInit {

  @Input() event: Event;
  vendor: any;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private userService: UserManagementService,
    private departmentService: DepartmentService
  ) {
  }


  private receiveVendors(vendors) {
    this.vendor = vendors.find((v) => v.id === parseInt(this.event.metadata.vendor, 10));
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.vendors.get.success, this.receiveVendors, this);

    this.bus.publish(this.events.requested.data.vendors.get);
  }
}
