import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { RecordsResourceService, RecordsModel } from 'app/services/records/record-resource.service';
import * as XLSX from 'xlsx';
import { MeasuresCreateDialogComponent } from '../create-dialog/create-dialog.component';
import { getRefNum } from '../../util';
import { TranslateService } from '@ngx-translate/core'
import { XlsxExportService } from 'app/services/report/xlsx-export.service';;

export const TYPE = 'measure';

@Component({
  selector: 'app-measure-records',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class MeasuresListComponent implements OnInit, OnDestroy {
  records: Array<RecordsModel> = [];
  users = [];

  columnsToDisplay = ['refNum', 'title', 'details', 'createdAt', 'createdBy', 'status'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private recordsService: RecordsResourceService,
    private translate: TranslateService,
    private xlsx: XlsxExportService,
  ) {

  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  private orderArray(ascending: boolean) {
    this.records = this.records.sort((a, b) => {
      if (ascending) {
        const c = a;
        a = b;
        b = c;
      }

      return (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any);
    });
  }

  private receiveData(items: Array<RecordsModel>) {
    this.records = items;
    this.records.map((r) => {
      r.refNum = getRefNum(r); // this allows MatSort to auto-sort by this property
    });
    const ascending = false;
    this.orderArray(ascending);
    this.dataSource = new MatTableDataSource<any>(this.records);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
      if (sortHeaderId === 'refNum') { // make sure sort order is ['XY-2', 'XY-13', 'XY-123'], not ['XY-123', 'XY-13', 'XY-2']
        return +data[sortHeaderId].split('-')[1]; // Drop the prefix for sorting. Attention: Assumes the convention 'XY-123'
      } else if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      } else if (sortHeaderId in data.metadata) { // make sure that nested properties are also checked
        return data.metadata[sortHeaderId];
      }
      return data[sortHeaderId];
    };

    const uniqueStatuses = Array.from(new Set(this.records.map(r => r.status)));
    this.translateStatusInDatasource(uniqueStatuses);
  }

  private translateStatusInDatasource(statuses: Array<string>) {
    // Add a hidden value to the data source so users can search by the translated word
    // E.g. Users can search for "geschl" and will see all 'closed' measures
    statuses.forEach(status => {
      this.translate.get('records.measure.status.' + status).subscribe(msg => {
        this.dataSource.data.forEach(d => {
          if (d.status === status) {
            d.statusTranslated = msg;
          }
        });
      });
    });
  }

  public createMeasure() {
    this.dialog.open(MeasuresCreateDialogComponent, {
      width: '700px',
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.records.all, TYPE);
      }
    });
  }

  public exportToExcel() {
    // only export useful fields, manually chosen:
    const data = this.dataSource.filteredData.map(inc => Object.assign({
      createdAt: new Date(inc.createdAt).toISOString().substr(0, 10),
      details: inc.details,
      createdBy: `${inc.firstName} ${inc.lastName}`,
      refNum: inc.refNum,
      status: inc.status,
      title: inc.title
    }));

    this.xlsx.export(data, 'Massnahmen');;
  }

  private handleError(error: string) {
    console.error(error);
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.records.all.success, this.receiveData.bind(this));
    this.bus.subscribe(this.events.received.data.records.all.failure, this.handleError.bind(this));
    this.bus.publish(this.events.requested.data.records.all, TYPE);
  }

  ngOnDestroy() {
  }
}
