import { Component, OnInit, Input } from '@angular/core';

import { DirectoryUser } from 'app/services/directory/directory-user';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'directory-user-list-display',
  templateUrl: './list-display.component.html',
  styleUrls: ['./list-display.component.scss']
})
export class DirectoryUserListDisplayComponent implements OnInit {

  @Input() user: any;
  @Input() emptyUserTitle = 'Unknown User';
  @Input() selected = false;
  @Input() compact = false;
  @Input() focused = false;
  @Input() disabled = false;
  @Input() multiple = false;

  constructor() { }

  ngOnInit() {
  }
}
