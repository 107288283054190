import { Injectable } from '@angular/core';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import { RequestService } from '../request.service';


@Injectable()
export class UserService {
  api = {
    root: '/account',
    info: '/info',
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  userInfo() {
    this.requestService.get<any>({
      uri: this.api.root + this.api.info,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.user.info.success, data),
        error: error => this.bus.publish(this.events.received.data.user.info.failure, error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.user.info, this.userInfo.bind(this));
  }
}
