import {Injectable, Injector } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";

import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";


@Injectable()
export class I18nInterceptor implements HttpInterceptor {
  private translate : TranslateService;

  constructor(
    private injector: Injector
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
    if (!this.translate)
      this.translate = this.injector.get(TranslateService);

    let lang = this.translate.currentLang || this.translate.defaultLang;
    if (lang) {
      return next.handle(
              req.clone({
                  params: req.params.set("lang", lang)
              }));
    }
    else {
      return next.handle(req);
    }
  }
}
