import { Component, OnInit, Inject } from '@angular/core';
import { Training, TrainingService } from 'app/services/training/training.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TrainingDialogComponent } from '../training-dialog/training-dialog.component';
import { SelectDirectoryUserDialogComponent } from 'app/components/directory/select-user/dialog/dialog.component';
import { DirectoryUser } from 'app/services/directory/directory-user';
import { TrainingConfirmationDialogComponent } from '../training-confirmation-dialog/confirmation-dialog.component';

export interface TrainingInfoDialogOptions {
  training: Training;
}


@Component({
  selector: 'app-training-info-dialog',
  templateUrl: './training-info-dialog.component.html',
  styleUrls: ['./training-info-dialog.component.scss']
})
export class TrainingInfoDialogComponent implements OnInit {

  training: Training;
  selectedParticipant: DirectoryUser;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: TrainingInfoDialogOptions,
    private ref: MatDialogRef<TrainingInfoDialogComponent>,
    private service: TrainingService,
    private dialog: MatDialog,
  ) {
    this.training = data.training;
  }

  ngOnInit() {
  }

  edit() {
    this.dialog.open(TrainingDialogComponent, {
      width: '700px',
      data: {
        training: this.training
      }
    }).afterClosed().subscribe(changes => {
      if (changes && changes.saved) {
        this.training.title = changes.saved.title;
        this.training.description = changes.saved.description;
        this.training.startingTime = changes.saved.startingTime;
        this.training.endingTime = changes.saved.endingTime;
      }
    });
  }

  addParticipant() {
    this.dialog.open(SelectDirectoryUserDialogComponent, {
      width: '512px',
    }).afterClosed().subscribe(result => {
      if (result.selected) {
        this.service.addParticipant(this.training, result.selected).subscribe(participant => {
          this.training.participants = this.training.participants || [];
          this.training.participants.push(participant);
        });
      }
    });
  }

  removeParticipant() {
    this.service.removeParticipant(this.training, this.selectedParticipant).subscribe(() => {
      this.training.participants = this.training.participants.filter(p => p.email !== this.selectedParticipant.email);
      this.selectedParticipant = undefined;
    });
  }

  delete() {

    this.dialog.open(TrainingConfirmationDialogComponent, {
      width: '512px',
      data: {
        hint: 'training.actions.delete.hint',
        confirmButton: 'training.actions.delete.confirmButton',
        dialogTitle: 'training.actions.delete.dialogTitle'
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.service.delete(this.training).subscribe(() => this.ref.close({ deleted: this.training }));
      }
    });

  }
}
