import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';

import { DPIARiskConstants, DPIASeverityConstants, dpiaGridClass } from './dpia.consts';
import { DpiaRiskDialogComponent } from './dpia-risk-dialog/dpia-risk-dialog.component';
import { DpiaSeverityDialogComponent } from './dpia-severity-dialog/dpia-severity-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-dpia',
  templateUrl: './dpia.component.html',
  styleUrls: ['./dpia.component.scss']
})
export class DpiaComponent implements OnInit, OnChanges {
  @Input() paId: number;
  @Input() processedInternally: boolean;
  @Input() processedExternally: boolean;
  @Input() dataSubjectCategories: any;
  @Input() dataCategoriesDescription: any;
  @Input() departmentAssociations = [];
  @Input() vendorAssociations = [];
  @Input() readonly = true;
  @Output() paDpiaUpdated = new EventEmitter<any>();

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
  ) {
  }

  incidentProbability = 0;
  incidentSeverity = 0;
  incidentProbabilityReasons = [];
  incidentSeverityReasons = [];

  severityThresholds = DPIASeverityConstants.thresholds;
  riskThresholds = DPIARiskConstants.thresholds;
  severityRanges = DPIASeverityConstants.thresholds.map((item, index) => {
    if (index < DPIASeverityConstants.thresholds.length - 1) {
      return [item, DPIASeverityConstants.thresholds[index + 1]];
    }
  }).filter(_ => _);

  severityConstants = DPIASeverityConstants;
  manualSeverityAssessment = false;

  riskRanges = DPIARiskConstants.thresholds.map((item, index) => {
    if (index < DPIARiskConstants.thresholds.length - 1) {
      return [item, DPIARiskConstants.thresholds[index + 1]];
    }
  }).filter(_ => _);

  riskConstants = DPIARiskConstants;
  manualRiskAssessment = false;

  ngOnChanges(changes) {
    if (changes.dataSubjectCategories || changes.dataCategoriesDescription) {
      this.updateIncidentSeverity();
    }

    if (changes.departmentAssociations || changes.vendorAssociations ||
        changes.processedExternally || changes.processedInternally) {
      this.updateIncidentProbability();
    }
  }


  doAutoIncidentProb() {
    this.manualRiskAssessment = false;
    this.updateIncidentProbability();
    this.saveDpiaData();
  }

  updateIncidentProbability() {
    if (this.manualRiskAssessment) {
      return;
    }

    let vendorSafetyFactor =
      this.vendorAssociations
        .filter(entry => entry.paId === this.paId)
        .reduce((t, _) => t * DPIARiskConstants.vendorSafetyFactor, 1);

    if (this.processedExternally !== true) {
      vendorSafetyFactor = 1;
    }

    let departmentSafetyFactor =
      this.departmentAssociations
        .filter(entry => entry.paId === this.paId)
        .reduce((t, _) => t * DPIARiskConstants.departmentSafetyFactor, DPIARiskConstants.departmentSafetyFactor);

    if (this.processedInternally !== true) {
      departmentSafetyFactor = DPIARiskConstants.departmentSafetyFactor;
    }

    this.incidentProbability = 1 - vendorSafetyFactor * departmentSafetyFactor;

    const reasons = [];
    if (vendorSafetyFactor === 1) {
      reasons.push({ systematic: true, text: 'dpia.risk.reasons.no-external' });
    } else if (vendorSafetyFactor >= .9) {
      reasons.push({ systematic: true, text: 'dpia.risk.reasons.limited-external' });
    } else if (vendorSafetyFactor >= .8) {
      reasons.push({ systematic: true, text: 'dpia.risk.reasons.external' });
    } else if (vendorSafetyFactor >= .7) {
      reasons.push({ systematic: true, text: 'dpia.risk.reasons.multiple-external' });
    } else {
      reasons.push({ systematic: true, text: 'dpia.risk.reasons.excessive-external' });
    }

    if (departmentSafetyFactor === DPIARiskConstants.departmentSafetyFactor) {
      reasons.push({ systematic: true, text: 'dpia.risk.reasons.no-internal' });
    } else if (departmentSafetyFactor > .9) {
      reasons.push({ systematic: true, text: 'dpia.risk.reasons.limited-internal' });
    } else if (departmentSafetyFactor > .8) {
      reasons.push({ systematic: true, text: 'dpia.risk.reasons.multiple-internal' });
    } else {
      reasons.push({ systematic: true, text: 'dpia.risk.reasons.excessive-internal' });
    }

    this.incidentProbabilityReasons = reasons;

    if (!this.readonly) {
      this.saveDpiaData();
    }
  }

  doAutoSeverity() {
    this.manualSeverityAssessment = false;
    this.updateIncidentSeverity();
    this.saveDpiaData();
  }

  updateIncidentSeverity() {
    if (this.manualSeverityAssessment) {
      return;
    }

    if (this.dataSubjectCategories && this.dataCategoriesDescription) {
      const subjects = this.dataSubjectCategories;
      const categories = this.dataCategoriesDescription;

      if (subjects.length === 0 || categories.length === 0) {
        this.incidentSeverity = -1;
      } else {
        const scaleFactor = subjects
          .map(item => DPIASeverityConstants.scaleFactor[item] || 1)
          .reduce((s, i) => s + i, 0);

        const sensitivityFactor = categories
          .map(item => DPIASeverityConstants.sensitivityFactor[item] || 1)
          .reduce((s, i) => s + i, 0);

        this.incidentSeverity = scaleFactor * sensitivityFactor;

        const reasons = [];
        if (scaleFactor < 3) {
          reasons.push({ systematic: true, text: 'dpia.severity.reasons.limited-subjects' });
        } else if (scaleFactor < 7) {
          reasons.push({
            systematic: true,
            text: 'dpia.severity.reasons.many-subjects'
          });
        } else {
          reasons.push({
            systematic: true,
            text: 'dpia.severity.reasons.many-sensitive-subjects'
          });
        }

        if (sensitivityFactor < 5) {
          reasons.push({ systematic: true, text: 'dpia.severity.reasons.limited-nonsensitive-info' });
        } else if (sensitivityFactor < 10) {
          reasons.push({ systematic: true, text: 'dpia.severity.reasons.nonsensitive-info' });
        } else {
          const maxSensitivity = categories
            .map(item => DPIASeverityConstants.sensitivityFactor[item] || 1)
            .reduce((s, i) => Math.max(s, i), 0);

          if (maxSensitivity >= 40) {
            reasons.push({ systematic: true, text: 'dpia.severity.reasons.extra-sensitive-info' });
          } else if (maxSensitivity >= 20) {
            reasons.push({ systematic: true, text: 'dpia.severity.reasons.highly-sensitive-info' });
          } else if (maxSensitivity >= 10) {
            reasons.push({ systematic: true, text: 'dpia.severity.reasons.sensitive-info' });
          }

          if (categories.length > 10) {
            reasons.push({ systematic: true, text: 'dpia.severity.reasons.excessive-range' });
          } else if (categories.length > 5) {
            reasons.push({ systematic: true, text: 'dpia.severity.reasons.wide-range' });
          }
        }

        this.incidentSeverityReasons = reasons;
      }

      this.saveDpiaData();
    }
  }

  dpiaGridClass(riskIndex: number, severityIndex: number) {
    return dpiaGridClass(riskIndex, severityIndex);
  }

  openDpiaRiskDialog() {
    this.dialog.open(DpiaRiskDialogComponent, {
      width: '700px',
      data: {
        probability: this.incidentProbability,
        probabilityReasons: this.incidentProbabilityReasons,
      }
    }).afterClosed().subscribe(data => {
      if (data && data.mutated) {
        this.incidentProbability = data.probability;
        this.incidentProbabilityReasons = data.reasons;
        this.manualRiskAssessment = true;

        this.saveDpiaData();
      }
    });
  }

  openDpiaSeverityDialog() {
    this.dialog.open(DpiaSeverityDialogComponent, {
      width: '700px',
      data: {
        severity: this.incidentSeverity,
        severityReasons: this.incidentSeverityReasons,
      }
    }).afterClosed().subscribe(data => {
      if (data && data.mutated) {
        this.incidentSeverity = data.severity;
        this.incidentSeverityReasons = data.reasons;
        this.manualSeverityAssessment = true;

        this.saveDpiaData();
      }
    });
  }

  saveDpiaData() {
    const data = {
      risk: {
        value: this.incidentProbability,
        reasons: this.incidentProbabilityReasons || [],
        manual: this.manualRiskAssessment,
      },
      severity: {
        value: this.incidentSeverity,
        reasons: this.incidentSeverityReasons || [],
        manual: this.manualSeverityAssessment,
      }
    };

    this.paDpiaUpdated.emit(data);
  }

  public loadData(data) {
    if (data) {
      if (data.risk) {
        this.incidentProbability = data.risk.value;
        this.incidentProbabilityReasons = data.risk.reasons;
        this.manualRiskAssessment = data.risk.manual;
      }

      if (data.severity) {
        this.incidentSeverity = data.severity.value;
        this.incidentSeverityReasons = data.severity.reasons;
        this.manualSeverityAssessment = data.severity.manual;
      }
    }

    this.updateIncidentProbability();
    this.updateIncidentSeverity();
  }

  ngOnInit() {
  }

}
