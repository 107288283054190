import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';

@Injectable()
export class SupervisionClientTokenService {

  api = {
    root: '/supervision/client-management',
    token: {
      getSingle: '/client/{0}/token',
      getAll: '/clients/tokens'
    }
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getClientToken(supervisionId: number) {
    // console.log('client token requested');
    return this.requestService.get<any>({
      uri: this.api.root + `/client/${supervisionId}/token`,
      handlers: {
        success: token => {
          // console.log('got token', token);
          this.bus.publish(this.events.received.data.supervision.clientToken.single.success, token.token);
        },
        error: error =>  {
          // console.error(error);
          this.bus.publish(this.events.received.data.supervision.clientToken.single.failure, error.error);
        },
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.supervision.clientToken.single, this.getClientToken.bind(this));
  }
}
