import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from './app.config';
// import { IntercomService } from 'app/modules/intercom/intercom.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    // private intercom: IntercomService,
    private router: Router,
  ) {
    // IETF short form, i.e. 'en' instead of 'en-US':
    if (!localStorage.getItem('lang')) {
      let lang = (navigator.language).slice(0, 2);

      const supportedLanguages = AppConfig.supportedLanguages;
      if (!supportedLanguages.includes(lang)) {
        lang = AppConfig.defaultLang; // default
      }

      translate.setDefaultLang(lang);
      localStorage.setItem('lang', lang);

    }
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd)
        window.scrollTo(0, 0);
    })
  }
}
