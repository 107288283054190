
import { Component, OnInit, OnDestroy, ViewChild, Input, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusService } from '../../../../services/bus.service';
import { EventsService } from '../../../../services/events.service';
import { ActionsService } from 'app/services/actions/actions.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { state, trigger, style, transition, animate } from '@angular/animations';
import { Event, AvailableEvents, FilterRequest, EntityTypes } from '../event';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-event-path',
  templateUrl: './event-path.component.html',
  styleUrls: ['./event-path.component.scss']
})
export class EventPathComponent implements OnInit, OnDestroy {
  @Input() event: Event;
  @Input() shouldLoadEntity: boolean;
  @Input() canFilter: boolean;

  @Output() makeFilterSelection = new EventEmitter();

  eventMeta: any;
  category: string;

  entityIdentifier: string;
  entityAction: string;
  entityName: string;
  entityId: number;

  loadedName = false;

  constructor(
    private bus: BusService,
    private eventsService: EventsService,
    private route: ActivatedRoute,
    private actionRequest: ActionsService,
    private userService: UserManagementService,
    private router: Router,
    private renderer: Renderer2
  ) {
  }

  requestFilter(field: string, event: any) {
    if (event) {
      event.stopPropagation();
    }

    const filterRequest: FilterRequest = { category: null, action: null, item: null };

    if (!this.canFilter)
      return;
    
    if (field === 'category') {
      filterRequest.category = this.event.category;
    }

    if (field === 'action') {
      filterRequest.action = this.event.category + '.' + this.event.eventName;
      filterRequest.category = this.event.category;
    }

    if (field === 'item') {
      filterRequest.item = this.entityIdentifier;
    }

    if (field === 'itemaction') {
      filterRequest.item = this.entityIdentifier;
      filterRequest.action = this.event.category + '.' + this.event.eventName;
      filterRequest.category = this.event.category;
    }

    this.makeFilterSelection.emit(filterRequest);
  }

  /*
  get isInteractive() {
    if (!this.event)
        return false;

    if (this.eventMeta && this.eventMeta.canLoadName) return true;

    return false;
  }
  */

  setCategory() {
    const splitted = this.event.category.split('.');

    if (splitted.length > 1) {
      this.category = splitted[1].toLowerCase();
    } else {
      this.category = splitted[0].toLowerCase();
    }
  }

  setEntityIdentifier() {
    const identifiers = EntityTypes.find((e) => e.category === this.category);
    if (identifiers) {
      let itemId = 0;

      if (this.event.metadata.format && this.event.metadata.format === 'v2') {
        itemId = this.event.metadata.itemId;
      } else {
        // provide a fallback for existing metadata
        for (let field in this.event.metadata) {
          if (field.toLowerCase().includes('id')) {
            const value = this.event.metadata[field]; 
            
            if (value) {
              itemId = value;
            }
            break; // continue if id has been found
          }
        }
      }
      
      if (itemId) {
        if (this.event.metadata.format === 'v2') {
          this.entityIdentifier = this.event.metadata.prefix + itemId;  
        } else {
          this.entityIdentifier = identifiers.shortCode + itemId;
        }
        this.entityId = itemId;
      } else {
        this.entityIdentifier = null;
      }
    }
  }

  setEventMeta() {
    const ev = AvailableEvents.find((e) => e.fullName === `${this.event.category}.${this.event.eventName}`);
    this.eventMeta = ev;
  }

  ngOnInit() {
    this.setEventMeta();
    this.setCategory();
    this.setEntityIdentifier();
  }

  ngOnDestroy() {
    
  }
}

