import { DirectoryUser } from '../directory/directory-user';
import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


export interface Training {
  id?: number;
  title?: string;
  description?: string;
  startingTime?: Date | string;
  endingTime?: Date | string;
  createdAt?: Date | string;
  participants?: DirectoryUser[];
}


export interface TrainingSearchCriteria {
  keyword?: string;
  participant?: DirectoryUser;
  startingTime?: {
    before?: Date;
    after?: Date;
  };
}


@Injectable()
export class TrainingService {
  api = {
    root: '/training',
    participants: '/participant',
    stats: {
      root: '/stats',
      numOfEmployeesWithATrainingThisYear: '/num-of-employees-with-a-training-this-year',
      numOfTrainingsThisYear: '/num-of-trainings-this-year',
      percentageOfEmployeesWithoutTrainingThisYear: '/percentage-of-employees-without-training-this-year',
    }
  };

  constructor(private request: RequestService) { }

  public save(training: Training) {
    if (training.id) {
      return this.update(training);
    } else {
      return this.insert(training);
    }
  }

  public insert(training: Training) {
    return this.request.post<Training, Training>({
      uri: this.api.root,
      body: training,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  public update(training: Training) {
    return this.request.put<Training, Training>({
      uri: this.api.root + `/${training.id}`,
      body: training,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  public get(id: number | string) {
    return this.request.get({
      uri: this.api.root + `/${id}`,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  public delete(training: Training) {
    return this.request.delete({
      uri: this.api.root + `/${training.id}`,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  _paramsFromCriteria(criteria?: TrainingSearchCriteria) {
    let params = new HttpParams();

    if (criteria.keyword) {
      params = params.set('keyword', criteria.keyword);
    }

    if (criteria.participant) {
      params = params.set('participant', encodeURIComponent(criteria.participant.email));
    }

    if (criteria.startingTime) {
      if (criteria.startingTime.before) {
        params = params.set('before', criteria.startingTime.before.toISOString());
      }

      if (criteria.startingTime.after) {
        params = params.set('after', criteria.startingTime.after.toISOString());
      }
    }

    return params;
  }

  public find(criteria?: TrainingSearchCriteria) {
    return this.request.get<Training[]>({
      uri: this.api.root,
      parameters: this._paramsFromCriteria(criteria),
      handlers: {
        success: () => { },
        error: () => { },
      }
    }).pipe(map(trainings => {
      trainings.forEach(training => {
        if (training.startingTime) {
          training.startingTime = new Date(training.startingTime as string);
        }

        if (training.endingTime) {
          training.endingTime = new Date(training.endingTime as string);
        }

        if (training.participants) {
          training.participants.forEach(participant => {
            if (!participant.username) {
              participant.username = (participant as any).directoryId;
            }
          });
        }
      });

      return trainings;
    }));
  }

  public addParticipant(training: Training, user: DirectoryUser) {
    return this.request.post<DirectoryUser, DirectoryUser>({
      uri: this.api.root + `/${training.id}` + this.api.participants,
      body: user,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  public removeParticipant(training: Training, user: DirectoryUser) {
    return this.request.delete({
      uri: this.api.root + `/${training.id}` + this.api.participants + `/${user._id}`,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }


  private _stat(stat): Observable<{stat: number}> {
    return this.request.get({
      uri: this.api.root + this.api.stats.root + this.api.stats[stat],
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  public get statistics() {
    return {
      numOfEmployeesWithATrainingThisYear: () => this._stat('numOfEmployeesWithATrainingThisYear'),
      numOfTrainingsThisYear: () => this._stat('numOfTrainingsThisYear'),
      percentageOfEmployeesWithoutTrainingThisYear: () => this._stat('percentageOfEmployeesWithoutTrainingThisYear'),
    };
  }
}
