import { Component, OnInit, OnDestroy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { EventsService } from '../../../../services/events.service';
import { BusService } from '../../../../services/bus.service';

import { AppConfig } from '../../../../app.config';

import { DpaRequestEmailSentDialogComponent }
  from './dpa-request-email-sent-dialog/dpa-request-email-sent-dialog.component';


@Component({
  selector: 'app-dpa-dialog',
  templateUrl: './dpa-dialog.component.html',
  styleUrls: ['./dpa-dialog.component.scss']
})
export class DpaDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  public vendor: any;
  public uploaded: boolean = false;
  public contacting: boolean = false;

  @ViewChild('offered', { static: false }) offered;
  @ViewChild('upload', { static: false }) upload;

  constructor(
    private events: EventsService,
    private bus: BusService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DpaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any,
  ) {
    this.vendor = data.vendor;
  }

  ngOnInit() {
    this.subscribe();
  }

  ngAfterViewInit() {
    if (this.vendor.docPath) {
      this.upload.load(
        `${AppConfig.apiUrl}/vendors/${this.vendor.id}/dpa/`+
        `?token=${localStorage.getItem('currentUser')}`);
    }
    else if (this.vendor.offeredDocPath) {
      this.offered.load(
        `${AppConfig.apiUrl}/vendors/${this.vendor.id}/dpa/offered/`+
        `?token=${localStorage.getItem('currentUser')}`);
    }
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  contact() {
    this.contacting = true;
    this.bus.publish(this.events.requested.action.vendors.contactForDPA, {
      vendorId: this.vendor.id,
    });
  }

  contactDateReadable() {
    return new Date(Date.parse(this.vendor.lastRequestDate)).toDateString();
  }

  contactSuccess() {
    this.contacting = false;
    this.dialog.open(DpaRequestEmailSentDialogComponent, {
      width: '480px',
      data: {
        vendor: this.vendor,
      }
    }).afterClosed().subscribe(() => {
      this.dialogRef.close();
    });
  }

  toggleRequired() {
    this.bus.publish(this.events.requested.action.vendors.toggleDPARequired, {
      vendorId: this.vendor.id,
      notRequired: this.vendor.notRequired,
    });
  }

  contactFailure() {
    //TODO: something else whatever
    this.contacting = false;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.vendors.contactForDPA.success, this.contactSuccess, this);
    this.bus.subscribe(this.events.received.action.vendors.contactForDPA.failure, this.contactFailure, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.vendors.contactForDPA.success, this.contactSuccess);
    this.bus.unsubscribe(this.events.received.action.vendors.contactForDPA.failure, this.contactFailure);
  }
}
