export const DPIARiskConstants = {
    vendorSafetyFactor: .95,
    departmentSafetyFactor: .98,
    thresholds: [0, .15, .25, .35, 1.00],
    thresholdLabels: [
        'dpia.risk.labels.negligible',
        'dpia.risk.labels.low',
        'dpia.risk.labels.medium',
        'dpia.risk.labels.high'],
};

export const DPIASeverityConstants = {
    thresholds: [0, 25, 50, 100, 100000000000],
    thresholdLabels: [
        'dpia.severity.labels.negligible',
        'dpia.severity.labels.low',
        'dpia.severity.labels.medium',
        'dpia.severity.labels.high'],
    scaleFactor: {
        'pa.dataSubject.categories.employees': 2,
        'pa.dataSubject.categories.applicants': 1.5,
        'pa.dataSubject.categories.trainees': .5,
        'pa.dataSubject.categories.temporaryWorkers': .3,
        'pa.dataSubject.categories.managers': .25,
        'pa.dataSubject.categories.commercialAgent': .1,
        'pa.dataSubject.categories.serviceProvider': .1,
        'pa.dataSubject.categories.suppliers': .2,
        'pa.dataSubject.categories.interestedParties': .2,
        'pa.dataSubject.categories.contractualPartner': .3,
        'pa.dataSubject.categories.customers': 6,
        'pa.dataSubject.categories.customersOfCustomers': 4,
        'pa.dataSubject.categories.employeesOfCustomers': 3,
        'pa.dataSubject.categories.members': 2,
        'pa.dataSubject.categories.debtor': .1,
        'pa.dataSubject.categories.investor': .1,
        'pa.dataSubject.categories.jointController': .1,
        'pa.dataSubject.categories.patients': 6,
    },

    sensitivityFactor: {
        'pa.dataSubject.dataCategories.name': 1.5,
        'pa.dataSubject.dataCategories.address': 1,
        'pa.dataSubject.dataCategories.email': 1,
        'pa.dataSubject.dataCategories.phone': 1,
        'pa.dataSubject.dataCategories.fax': 1,
        'pa.dataSubject.dataCategories.login': 3,
        'pa.dataSubject.dataCategories.messengerIdentity': 1,
        'pa.dataSubject.dataCategories.ageRanges': 1,
        'pa.dataSubject.dataCategories.birthInformation': 1.5,
        'pa.dataSubject.dataCategories.family': 2,
        'pa.dataSubject.dataCategories.maritalStatus': 1,
        'pa.dataSubject.dataCategories.likes': 10,
        'pa.dataSubject.dataCategories.citizenship': 1.5,
        'pa.dataSubject.dataCategories.passportId': 20,
        'pa.dataSubject.dataCategories.educationalInfo': 1.5,
        'pa.dataSubject.dataCategories.employmentInformation': 2,
        'pa.dataSubject.dataCategories.licensePlate': 2,
        'pa.dataSubject.dataCategories.insuranceInformation': 12,
        'pa.dataSubject.dataCategories.salaryInformation': 2,
        'pa.dataSubject.dataCategories.bankDetails': 12,
        'pa.dataSubject.dataCategories.orderHistory': 2,
        'pa.dataSubject.dataCategories.ip': 2,
        'pa.dataSubject.dataCategories.gps': 20,
        'pa.dataSubject.dataCategories.location': 20,
        'pa.dataSubject.dataCategories.social': 2,
        'pa.dataSubject.dataCategories.criminal': 40,
        'pa.dataSubject.dataCategories.biometricInfo': 40,
        'pa.dataSubject.dataCategories.geneticData': 40,
        'pa.dataSubject.dataCategories.physicalTraits': 3,
        'pa.dataSubject.dataCategories.imageData': 3,
        'pa.dataSubject.dataCategories.ethnicity': 20,
        'pa.dataSubject.dataCategories.healthData': 7,
        'pa.dataSubject.dataCategories.religion': 20,
        'pa.dataSubject.dataCategories.tradeUnion': 20,
        'pa.dataSubject.dataCategories.politicalInformation': 20,
        'pa.dataSubject.dataCategories.sex': 1,
    },
};


/**
 *
 * NOTICE: this function is tightly coupled with "./_dpia.grid.scss", as it
 *         returns class names that are picked up by mixins in that file.
 *
 */
export function dpiaGridClass(riskIndex: number, severityIndex: number) {
    if (severityIndex === 3) { return 'catastrophe'; }
    if (riskIndex + severityIndex <= 0) { return 'safe'; }
    if (riskIndex + severityIndex <= 1) { return 'mostly-safe'; }
    if (riskIndex + severityIndex <= 2) { return 'warning'; }
    if (riskIndex + severityIndex <= 3) { return 'serious-warning'; }
    if (riskIndex + severityIndex <= 4) { return 'danger'; }
    return 'catastrophe';
}


export function dpiaRiskIndex(riskValue: number) {
    return DPIARiskConstants.thresholds.findIndex(n => n > riskValue) - 1;
}


export function dpiaSeverityIndex(severityValue: number) {
    return DPIASeverityConstants.thresholds.findIndex(n => n > severityValue) - 1;
}


export function dpiaGridClassByValues(riskValue: number, severityValue: number) {
    return dpiaGridClass(dpiaRiskIndex(riskValue), dpiaSeverityIndex(severityValue));
}
