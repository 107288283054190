import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';

interface AcceptInvitationRequest {
  token: string;
}

@Injectable()
export class SupervisionClientInvitationService {

  api = {
    root: '/supervision/client-invitation',
    invitation: {
      supervisor: '/supervisor',
      request: '',
      accept: '/accept'
    }
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getSupervisor(token) {
    this.requestService.get<any>({
      uri: this.api.root + this.api.invitation.supervisor + `/${token}`,
      handlers: {
        success: supervisor => this.bus.publish(this.events.received.data.supervision.clientInvitation.supervisor.success, supervisor),
        error: error => this.bus.publish(this.events.received.data.supervision.clientInvitation.supervisor.failure, error.error),
      }
    });
  }

  getRequest(token) {
    this.requestService.get<any>({
      uri: this.api.root + this.api.invitation.request + `/${token}`,
      handlers: {
        success: request => this.bus.publish(this.events.received.data.supervision.clientInvitation.request.success, request),
        error: error => this.bus.publish(this.events.received.data.supervision.clientInvitation.request.failure, error.error),
      }
    });
  }

  accept(data: AcceptInvitationRequest) {
    this.requestService.post<AcceptInvitationRequest, void>({
      uri: this.api.root + this.api.invitation.accept,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.clientInvitation.accept.success),
        error: error => this.bus.publish(this.events.received.action.supervision.clientInvitation.accept.failure, error.error),
      }
    });
  }

  getSupervision() {
    this.requestService.get<any>({
      uri: this.api.root,
      handlers: {
        success: supervision => this.bus.publish(this.events.received.data.supervision.clientInvitation.info.success, supervision),
        error: error => this.bus.publish(this.events.received.data.supervision.clientInvitation.info.failure, error.error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.supervision.clientInvitation.supervisor, this.getSupervisor.bind(this));
    this.bus.subscribe(this.events.requested.data.supervision.clientInvitation.request, this.getRequest.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.clientInvitation.accept, this.accept.bind(this));
    this.bus.subscribe(this.events.requested.data.supervision.clientInvitation.info, this.getSupervision.bind(this));
  }
}
