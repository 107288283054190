import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import {
  MatDialogModule,
  MatButtonModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatCardModule,
  MatRadioModule,
  MatToolbarModule,
  MatTooltipModule,
  MatMenuModule,
  MatIconModule,
  MatTableModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatTabsModule,
  MatSidenavModule,
  MatPaginatorModule,
  MatAutocompleteModule
} from '@angular/material';

import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { AppConfig } from './app.config';

import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ClipboardModule } from 'ngx-clipboard';

import { NgSelectModule } from '@ng-select/ng-select';

// loader module
import { LoadingModule } from 'ngx-loading';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { MentionModule } from 'angular-mentions';


// ngx-translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgxFileDropModule } from 'ngx-file-drop';

// ngx-chips (Tag selection)
import { TagInputModule } from 'ngx-chips';

import { MarkdownModule } from 'ngx-markdown';

import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';

// import { CdkTableModule, DataSource } from '@angular/cdk/table';

// import { ChartsModule } from 'ng2-charts';
// Routes
import { RoutingConfig } from './app.routes';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RegistrationComponent, RegistrationDialogComponent } from './components/registration/registration.component';
import { VerificationComponent } from './components/registration/verification/verification.component';
import { LoginComponent, LoginDialogComponent } from './components/login/login.component';
import { EmailLoginComponent } from './components/login/email/email.login.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { BasicInfoComponent } from './components/rpa/basic-info/basic-info.rpa.component';
import { PaSelectionComponent } from './components/rpa/pa-selection/pa-selection.rpa.component';
import { ReportComponent } from './components/rpa/report/report.rpa.component';
import {
  ChangePasswordComponent,
  ChangePasswordDialogComponent
} from './components/settings/change-password/change-password.settings.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { EmailNotificationComponent } from './components/settings/email-notification/email-notification.settings.component';
import { ProfilePictureComponent } from './components/settings/profile-picture/profile-picture.settings.component';
import { ImprintComponent } from './components/footer/imprint/imprint.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { UserExpiredComponent } from './components/user-expired/user-expired.component';
import { CustomPaDialogConfirmationComponent } from './components/rpa/custom-pa-dialog-confirmation/custom-pa-dialog-confirmation.component';

// Supervision
import { SupervisionClientListComponent } from './components/supervision/client-list/client-list.component';
import { ConfirmSupervisionInvitationComponent } from './components/supervision/invitation/invitation.component';

// Interlayer
import { AuthenticationService } from './services/authentication.service';
import { BasicInfoGetService } from './services/basic-info/basic-info.get.service';
import { BasicInfoPostService } from './services/basic-info/basic-info.post.service';
import { RpaGetAllService } from './services/rpa/rpa.get-all.service';
import { RpaGetService } from './services/rpa/rpa.get.service';
import { RpaPostService } from './services/rpa/rpa.post.service';
import { RpaUpdateStatusService } from './services/rpa/rpa.update-status.service';
import { ReportGetService } from './services/report/report.get.service';
import { ChangePasswordService } from './services/settings/change-password.service';
import { EmailNotificationService } from './services/settings/email-notification.service';
import { ProfilePictureService } from './services/settings/profile-picture.service';

// Services
import { AuthenticationRequestService } from './services/authentication-request/authentication-request.service';
import { EmailAuthService } from './services/authentication-request/email-auth.service';
import { ForgotPasswordService } from './services/authentication-request/forgot-password.service';
import { SendVerificationEmailService } from './services/authentication-request/send-verification.service';
import { RegistrationRequestService } from './services/authentication-request/registration.service';
import { RequestService } from './services/request.service';
import { BusService } from './services/bus.service';
import { EventsService } from './services/events.service';
import { ValidationService } from './services/validation.service';
import { VerificationService } from './services/authentication-request/verification.service';
import { AdminBroadcastService } from './services/admin-broadcast.service';
import { CustomPaService } from './services/rpa/rpa.custom-pa.service';
import { DepartmentService } from './services/departments/department.service';
import { EmailCheckService } from './services/authentication-request/email-check.service';
import { UploadService } from './services/upload.service';
import { RpaService } from './services/rpa/rpa.service';
import { RpaInternallyProcessedService } from './services/rpa/rpa.internally-processed.service';
import { RpaExternallyProcessedService } from './services/rpa/rpa.externally-processed.service';



import { DpoService } from './services/dpo/dpo.service';
import { UserService } from './services/user/user.service';
import { VendorsService } from './services/vendors/vendors.service';
import { UserManagementService } from './services/user/user-management.service';
import { TomsService } from './services/toms/toms.service';
import { BadgeService } from './services/badge/badge.service';
import { ClientManagementService } from './services/supervision/client-management.service';
import { SupervisionClientInvitationService } from './services/supervision/client-invitation.service';
import { SupervisionTemplatesService } from './services/supervision/templates.service';
import { SupervisionTemplatesBackendService } from './services/supervision/templates-backend.service';
import { CommentService } from './services/comment/comment.service';
import { SupervisorProfileService } from './services/supervision/supervisor-profile.service';
import { DocumentResourceService } from './services/documents/document-resource.service';
import { ActionsService } from './services/actions/actions.service';

// Guards
import { AuthenticationGuard } from './guards/authentication.guard';
import { NotAuthenticatedGuard } from './guards/not-authenticated.guard';
import { ProductFeaturesGuard } from './guards/product-features.guard';

// Interceptors
import { I18nInterceptor } from './interceptors/interceptors.i18n';
import { AuthenticationInterceptor } from './interceptors/interceptors.authentication';
import { ExpiryInterceptor } from './interceptors/interceptors.expiry';

import { LanguageComponent } from './components/language/language.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component';
import { DepartmentsComponent, EditDepartmentDialogComponent } from './components/organisation/department/departments.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { StepComponent } from './components/stepper/step/step.component';
import { GdprAppliesComponent } from './components/welcome/gdpr-applies/gdpr-applies.component';
import { ControllerComponent } from './components/organisation/controller/controller.component';
import { _ControllerService } from './services/basic-info/controller.service';
import { ControllerService } from './services/controller/controller.service';
import { SetupDpmsComponent } from './components/welcome/setup-dpms/setup-dpms.component';
import { CoolUploadComponent } from './components/cool-upload/cool-upload.component';
import { DpGoalsComponent } from './components/organisation/dp-goals/dp-goals.component';
import { DeclareDpoComponent } from './components/organisation/declare-dpo/declare-dpo.component';
import { MilestoneOneComponent } from './components/milestones/milestone-one/milestone-one.component';
import { BigRoundButtonComponent } from './components/big-round-button/big-round-button.component';
import { OrganisationComponent } from './components/organisation/organisation.component';
import { RpaComponent } from './components/rpa/rpa.component';
import { RpaTutorialComponent } from './components/rpa/tutorial/tutorial.component';
import { PaDetailsComponent } from './components/rpa/pa-details/pa-details.component';
import { PaExternalProcessorsComponent } from './components/rpa/pa-external-processors/pa-external-processors.component';
import { VendorSearchDialogComponent } from './components/rpa/pa-external-processors/vendor-search-dialog/vendor-search-dialog.component';
import { VendorAddDialogComponent } from './components/rpa/pa-external-processors/vendor-add-dialog/vendor-add-dialog.component';
import { MilestoneTwoComponent } from './components/milestones/milestone-two/milestone-two.component';
import { DpasComponent } from './components/vendors/dpas/dpas.component';
import { DpaDialogComponent } from './components/vendors/dpas/dpa-dialog/dpa-dialog.component';
import {
  DpaRequestEmailSentDialogComponent
} from './components/vendors/dpas/dpa-dialog/dpa-request-email-sent-dialog/dpa-request-email-sent-dialog.component';
import { OfferDpaComponent } from './components/vendors/offer-dpa/offer-dpa.component';
import { AuthTokenLoginComponent } from './components/auth-token-login/auth-token-login.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { EditUserDialogComponent } from './components/user-management/edit-user-dialog/edit-user-dialog.component';
import { InviteUserDialogComponent } from './components/user-management/invite-user-dialog/invite-user-dialog.component';
import { ManageInviteDialogComponent } from './components/user-management/manage-invite-dialog/manage-invite-dialog.component';
import { InvitationResultDialogComponent } from './components/user-management/invitation-result-dialog/invitation-result-dialog.component';
import { ConfirmInvitationComponent } from './components/user-management/confirm-invitation/confirm-invitation.component';
import { PrivacyPolicyEnComponent } from './components/footer/privacy-policy-en/privacy-policy-en.component';
import { PrivacyPolicyDeComponent } from './components/footer/privacy-policy-de/privacy-policy-de.component';
import { TosDeComponent } from './components/footer/tos-de/tos-de.component';
import { TosEnComponent } from './components/footer/tos-en/tos-en.component';
import { CookiePolicyDeComponent } from './components/footer/cookie-policy-de/cookie-policy-de.component';
import { CookiePolicyEnComponent } from './components/footer/cookie-policy-en/cookie-policy-en.component';
import { TomsComponent } from './components/toms/toms.component';
import { TomsCategoryComponent } from './components/toms/toms-category/toms-category.component';
import { BigRoundCheckboxComponent } from './components/big-round-checkbox/big-round-checkbox.component';
import { GetBadgeComponent } from './components/badge/get-badge/get-badge.component';
import { CopiedDialogComponent } from './components/badge/get-badge/copied-dialog/copied-dialog.component';
import { BadgeInfoComponent } from './components/badge/badge-info/badge-info.component';
import { AddClientDialogComponent } from './components/supervision/add-client-dialog/add-client-dialog.component';
import { DeleteClientDialogComponent } from './components/supervision/delete-client-dialog/delete-client-dialog.component';
import { SupervisionClientDetailsComponent } from './components/supervision/client-details/client-details.component';
import { SupervisionClientTokenService } from './services/supervision/client-token.service';
import { ClientSupervisionService } from './services/supervision/client-supervision.service';
import { CommentBoxComponent } from './components/comment-box/comment-box.component';
import { CommentBoxDialogComponent } from './components/comment-box/comment-box-dialog/comment-box-dialog.component';
import { SupervisorProfileComponent } from './components/supervision/profile/supervisor-profile.component';
import { WebsiteComplianceComponent } from './components/website-compliance/website-compliance.component';
import { AddClientEmailComponent } from './components/supervision/add-client/email/add-client-email.component';
import { AddClientControllerComponent } from './components/supervision/add-client/controller/add-client-controller.component';
import { DocumentsMiscListComponent } from './components/documents/misc-list/documents-misc-list.component';
import { DocumentsUploadDialogComponent } from './components/documents/modals/upload/documents-upload-dialog.component';
import { DeleteDocumentDialogComponent } from './components/documents/modals/delete/delete-document-dialog.component';
import { DocumentTaglistDialogComponent } from './components/documents/modals/taglist/document-taglist-dialog.component';
import { DocumentsDashboardComponent } from './components/documents/dashboard/documents-dashboard.component';
import { ApplyTemplateDialogComponent } from './components/supervision/templates/apply-template-dialog/apply-template-dialog.component';
import { CreateTemplateDialogComponent } from './components/supervision/templates/create-template-dialog/create-template-dialog.component';
import { SelectTemplateDialogComponent } from './components/supervision/templates/select-template-dialog/select-template-dialog.component';
import { TemplateInfoDialogComponent } from './components/supervision/templates/template-info-dialog/template-info-dialog.component';
import { DeleteTemplateDialogComponent } from './components/supervision/templates/delete-template-dialog/delete-template-dialog.component';
import { ActionsComponent } from './components/actions/actions.component';
import { CdkDetailRowDirective } from './components/actions/cdk-detail-row.directive';
import { ActionsMetadataUsersComponent } from './components/actions/list/metadata-info/users/users.component';
import { ActionsMetadataDepartmentComponent } from './components/actions/list/metadata-info/department/department.component';
import { ActionsMetadataDocumentComponent } from './components/actions/list/metadata-info/document/document.component';
import { ActionsMetadataRpaProcessorsComponent } from './components/actions/list/metadata-info/rpa-processors/rpa-processors.component';
import { ActionsMetadataRpaComponent } from './components/actions/list/metadata-info/rpa/rpa.component';
import { ActionsMetadataVendorsComponent } from './components/actions/list/metadata-info/vendors/vendors.component';
import { ActionsMetadataTomsComponent } from './components/actions/list/metadata-info/toms/toms.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { DocumentsContainerComponent } from './components/documents/container/documents-container.component';
import { ConfirmationDialogComponent } from './components/rpa/pa-details/confirmation-dialog/confirmation-dialog.component';
import { TodoableBoxComponent } from './components/todos/todoable-box/todoable-box.component';
import { TodoDialogComponent } from './components/todos/todo-dialog/todo-dialog.component';

import { TodosService } from './services/todos/todos.service';
import { TodosComponent } from './components/todos/todos/todos.component';
import { PaListComponent } from './components/rpa/pa-list/pa-list.component';

import { AccessControlComponent } from './components/access-control/access-control.component';
import { AccessControlService } from './services/access-control.service';
import { RpaWorkflowService } from './services/rpa/rpa.workflow.service';

import { AuthorityListComponent } from './components/records/authority/list/list.component';
import { AuthorityDetailsComponent } from './components/records/authority/details/details.component';
import { AuthorityCreateDialogComponent } from './components/records/authority/create-dialog/create-dialog.component';

import { ComplaintsListComponent } from './components/records/complaints/list/list.component';
import { ComplaintsDetailsComponent } from './components/records/complaints/details/details.component';
import { ComplaintsCreateDialogComponent } from './components/records/complaints/create-dialog/create-dialog.component';

import { DsarListComponent } from './components/records/dsar/list/list.component';
import { DsarDetailsComponent } from './components/records/dsar/details/details.component';
import { DsarCreateDialogComponent } from './components/records/dsar/create-dialog/create-dialog.component';

import { IncidentsListComponent } from './components/records/incidents/list/list.component';
import { IncidentsDetailsComponent } from './components/records/incidents/details/details.component';
import { IncidentsCreateDialogComponent } from './components/records/incidents/create-dialog/create-dialog.component';

import { MeasuresListComponent } from './components/records/measures/list/list.component';
import { MeasuresDetailsComponent } from './components/records/measures/details/details.component';
import { MeasuresCreateDialogComponent } from './components/records/measures/create-dialog/create-dialog.component';

import { RpaXLSXExportService } from './services/rpa/rpa.xlsx-export.service';

import { RecordsResourceService } from './services/records/record-resource.service';
import { SelectDirectoryUserComponent } from './components/directory/select-user/select-user.component';
import { SelectDirectoryUserDialogComponent } from './components/directory/select-user/dialog/dialog.component';
import { DirectoryUserListDisplayComponent } from './components/directory/list-display/list-display.component';

import { DirectoryService } from './services/directory/directory.service';
import { TodoCardComponent } from './components/todos/todo-card/todo-card.component';
import { ActionsListComponent } from './components/actions/list/list.component';
import { DpiaRiskDialogComponent } from './components/rpa/pa-details/dpia/dpia-risk-dialog/dpia-risk-dialog.component';
import { DpiaSeverityDialogComponent } from './components/rpa/pa-details/dpia/dpia-severity-dialog/dpia-severity-dialog.component';
import { DpiaComponent } from './components/rpa/pa-details/dpia/dpia.component';
import { InternalProcessingComponent } from './components/rpa/pa-details/internal-processing/internal-processing.component';
import { ExternalProcessingComponent } from './components/rpa/pa-details/external-processing/external-processing.component';
import { TodoListComponent } from './components/todos/todo-list/todo-list.component';
import { TodoDetailDialogComponent } from './components/todos/todo-detail-dialog/todo-detail-dialog.component';
import { UserInlineDisplayComponent } from './components/directory/inline-display/inline-display.component';
import { LongSelectDirective } from './directives/long-select.directive';
import { LongSelectDialogComponent } from './components/long-select-dialog/long-select-dialog.component';
import { TrainingListComponent } from './components/training/training-list/training-list.component';
import { TrainingStatsComponent } from './components/training/training-stats/training-stats.component';
import { TrainingDialogComponent } from './components/training/training-dialog/training-dialog.component';
import { TrainingInfoDialogComponent } from './components/training/training-info-dialog/training-info-dialog.component';
import { TrainingConfirmationDialogComponent } from './components/training/training-confirmation-dialog/confirmation-dialog.component';

import { TrainingService } from './services/training/training.service';
import { XlsxExportService } from './services/report/xlsx-export.service';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { DocumentHistoryDialogComponent } from './components/documents/modals/history/document-history-dialog.component';
import { SubjectTodosDialogComponent } from './components/todos/subject-todos-dialog/subject-todos-dialog.component';
import { EventPathComponent } from './components/actions/list/event-path/event-path.component';
import { ActionDetailsDialogComponent } from './components/actions/list/details/details-dialog.component';
import { CommentButtonComponent } from './components/comment-box/comment-button/comment-button.component';
import { CustomPaDialogComponent } from './components/rpa/custom-pa-dialog/custom-pa-dialog.component';
import { TodoTreeComponent } from './components/todos/todo-tree/todo-tree.component';
import { TodoTreeNodeComponent } from './components/todos/todo-tree/todo-tree-node/todo-tree-node.component';
import { UserInfoDialogComponent } from './components/directory/user-info-dialog/user-info-dialog.component';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { DatePipe } from '@angular/common';
import { LastChangeIndicatorComponent } from './components/rpa/pa-details/last-change-indicator/last-change-indicator.component';
import { PaDetailsChangesDialogComponent } from './components/rpa/pa-details/last-change-indicator/changes-dialog/changes-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    RegistrationComponent,
    RegistrationDialogComponent,
    VerificationComponent,
    LoginComponent,
    LoginDialogComponent,
    EmailLoginComponent,
    DialogComponent,
    BasicInfoComponent,
    PaListComponent,
    PaSelectionComponent,
    ReportComponent,
    ChangePasswordComponent,
    ChangePasswordDialogComponent,
    ForgotPasswordComponent,
    EmailNotificationComponent,
    ProfilePictureComponent,
    ImprintComponent,
    UserExpiredComponent,
    UnderConstructionComponent,
    CustomPaDialogConfirmationComponent,
    LanguageComponent,
    SideNavComponent,
    ProgressIndicatorComponent,
    DepartmentsComponent,
    EditDepartmentDialogComponent,
    WelcomeComponent,
    StepperComponent,
    StepComponent,
    GdprAppliesComponent,
    ControllerComponent,
    SetupDpmsComponent,
    CoolUploadComponent,
    DpGoalsComponent,
    DeclareDpoComponent,
    MilestoneOneComponent,
    BigRoundButtonComponent,
    OrganisationComponent,
    RpaComponent,
    RpaTutorialComponent,
    PaDetailsComponent,
    PaExternalProcessorsComponent,
    VendorSearchDialogComponent,
    VendorAddDialogComponent,
    MilestoneTwoComponent,
    DpasComponent,
    DpaDialogComponent,
    DpaRequestEmailSentDialogComponent,
    OfferDpaComponent,
    AuthTokenLoginComponent,
    UserManagementComponent,
    EditUserDialogComponent,
    InviteUserDialogComponent,
    ManageInviteDialogComponent,
    InvitationResultDialogComponent,
    ConfirmInvitationComponent,
    PrivacyPolicyEnComponent,
    PrivacyPolicyDeComponent,
    TosDeComponent,
    TosEnComponent,
    CookiePolicyDeComponent,
    CookiePolicyEnComponent,
    TomsComponent,
    TomsCategoryComponent,
    BigRoundCheckboxComponent,
    GetBadgeComponent,
    CopiedDialogComponent,
    BadgeInfoComponent,
    SupervisionClientListComponent,
    AddClientDialogComponent,
    ConfirmSupervisionInvitationComponent,
    DeleteClientDialogComponent,
    SupervisionClientDetailsComponent,
    CommentBoxComponent,
    CommentBoxDialogComponent,
    SupervisorProfileComponent,
    WebsiteComplianceComponent,
    AddClientEmailComponent,
    AddClientControllerComponent,
    DocumentsMiscListComponent,
    DocumentsUploadDialogComponent,
    DeleteDocumentDialogComponent,
    DocumentTaglistDialogComponent,
    DocumentsDashboardComponent,
    ApplyTemplateDialogComponent,
    CreateTemplateDialogComponent,
    SelectTemplateDialogComponent,
    TemplateInfoDialogComponent,
    DeleteTemplateDialogComponent,
    ActionsComponent,
    CdkDetailRowDirective,
    ActionsMetadataUsersComponent,
    ActionsMetadataDepartmentComponent,
    ActionsMetadataDocumentComponent,
    ActionsMetadataRpaProcessorsComponent,
    ActionsMetadataRpaComponent,
    ChangelogComponent,
    ActionsMetadataVendorsComponent,
    ActionsMetadataTomsComponent,
    DocumentsContainerComponent,
    ConfirmationDialogComponent,
    TodoableBoxComponent,
    TodoDialogComponent,
    TodosComponent,
    AccessControlComponent,
    SelectDirectoryUserComponent,
    SelectDirectoryUserDialogComponent,
    DirectoryUserListDisplayComponent,
    TodoCardComponent,

    AuthorityListComponent,
    AuthorityDetailsComponent,
    AuthorityCreateDialogComponent,

    ComplaintsListComponent,
    ComplaintsDetailsComponent,
    ComplaintsCreateDialogComponent,

    DsarListComponent,
    DsarDetailsComponent,
    DsarCreateDialogComponent,

    IncidentsListComponent,
    IncidentsDetailsComponent,
    IncidentsCreateDialogComponent,

    MeasuresListComponent,
    MeasuresDetailsComponent,
    MeasuresCreateDialogComponent,
    ActionsListComponent,
    DpiaRiskDialogComponent,
    DpiaSeverityDialogComponent,
    DpiaComponent,
    InternalProcessingComponent,
    ExternalProcessingComponent,
    TodoListComponent,
    TodoDetailDialogComponent,
    UserInlineDisplayComponent,
    LongSelectDirective,
    LongSelectDialogComponent,
    TrainingListComponent,
    TrainingStatsComponent,
    TrainingDialogComponent,
    TrainingInfoDialogComponent,
    TrainingConfirmationDialogComponent,
    InfiniteScrollComponent,
    DocumentHistoryDialogComponent,
    SubjectTodosDialogComponent,
    EventPathComponent,
    ActionDetailsDialogComponent,
    CommentButtonComponent,
    CustomPaDialogComponent,
    TodoTreeComponent,
    TodoTreeNodeComponent,
    UserInfoDialogComponent,
    LocalizedDatePipe,
    LastChangeIndicatorComponent,
    PaDetailsChangesDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RoutingConfig,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatCardModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    FlexLayoutModule,
    NgxFileDropModule,
    //    ChartsModule,
    LoadingModule,
    MarkdownModule.forRoot(),
    ScrollToModule.forRoot(),
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSidenavModule,
    ClipboardModule,
    MentionModule,
    MatPaginatorModule,
    MatAutocompleteModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TagInputModule,
    MatMomentDateModule,
    NgSelectModule,
    SatDatepickerModule,
    SatNativeDateModule
    // CdkTableModule,
    // DataSource
    // ,
    // OverlayContainer
  ],
  providers: [
    // Guards
    AuthenticationGuard,
    NotAuthenticatedGuard,
    ProductFeaturesGuard,

    // Interlayer
    AuthenticationService,
    BasicInfoGetService,
    BasicInfoPostService,
    RpaGetAllService,
    RpaGetService,
    RpaPostService,
    RpaUpdateStatusService,
    ReportGetService,
    ChangePasswordService,
    EmailNotificationService,
    ProfilePictureService,

    // Services
    ControllerService,
    RequestService,
    BusService,
    EventsService,
    AuthenticationRequestService,
    EmailAuthService,
    ForgotPasswordService,
    SendVerificationEmailService,
    RegistrationRequestService,
    ValidationService,
    VerificationService,
    AdminBroadcastService,
    EmailCheckService,
    CustomPaService,
    DepartmentService,
    UploadService,
    ControllerService,
    _ControllerService,
    DpoService,
    RpaService,
    RpaInternallyProcessedService,
    RpaExternallyProcessedService,
    UserService,
    VendorsService,
    UserManagementService,
    TomsService,
    BadgeService,
    ClientManagementService,
    SupervisionClientInvitationService,
    SupervisionClientTokenService,
    ClientSupervisionService,
    SupervisionTemplatesService,
    SupervisionTemplatesBackendService,
    CommentService,
    SupervisorProfileService,
    DocumentResourceService,
    ActionsService,
    TodosService,
    AccessControlService,
    RpaWorkflowService,
    RpaXLSXExportService,
    RecordsResourceService,
    DirectoryService,
    TrainingService,
    XlsxExportService,

    // Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: I18nInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExpiryInterceptor,
      multi: true,
    },
    DatePipe,
    LocalizedDatePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogComponent,
    RegistrationDialogComponent,
    LoginDialogComponent,
    ChangePasswordDialogComponent,
    CustomPaDialogConfirmationComponent,
    EditDepartmentDialogComponent,
    VendorSearchDialogComponent,
    VendorAddDialogComponent,
    DpaDialogComponent,
    DpaRequestEmailSentDialogComponent,
    EditUserDialogComponent,
    InviteUserDialogComponent,
    ManageInviteDialogComponent,
    InvitationResultDialogComponent,
    CopiedDialogComponent,
    AddClientDialogComponent,
    DeleteClientDialogComponent,
    CommentBoxDialogComponent,
    AddClientEmailComponent,
    AddClientControllerComponent,
    DocumentsUploadDialogComponent,
    DeleteDocumentDialogComponent,
    DocumentTaglistDialogComponent,
    ApplyTemplateDialogComponent,
    CreateTemplateDialogComponent,
    SelectTemplateDialogComponent,
    TemplateInfoDialogComponent,
    DeleteTemplateDialogComponent,
    ConfirmationDialogComponent,
    TodoDialogComponent,
    SelectDirectoryUserDialogComponent,
    AuthorityCreateDialogComponent,
    ComplaintsCreateDialogComponent,
    DsarCreateDialogComponent,
    IncidentsCreateDialogComponent,
    MeasuresCreateDialogComponent,
    DpiaRiskDialogComponent,
    DpiaSeverityDialogComponent,
    TodoDetailDialogComponent,
    LongSelectDialogComponent,
    TrainingDialogComponent,
    TrainingInfoDialogComponent,
    TrainingConfirmationDialogComponent,
    TrainingStatsComponent,
    DocumentHistoryDialogComponent,
    SubjectTodosDialogComponent,
    ActionDetailsDialogComponent,
    CustomPaDialogComponent,
    UserInfoDialogComponent,
    PaDetailsChangesDialogComponent,
  ]
})

export class AppModule {
  constructor(
    private overlayContainer: OverlayContainer,
    // Request Service
    private authenticationService: AuthenticationService,
    // Interlayer
    private authenticationRequestService: AuthenticationRequestService,
    private emailAuthService: EmailAuthService,
    private snedVerificationEmailService: SendVerificationEmailService,
    private forgotPasswordService: ForgotPasswordService,
    private registrationRequestService: RegistrationRequestService,
    private validationService: ValidationService,
    private verificationService: VerificationService,
    private basicInfoGetService: BasicInfoGetService,
    private basicInfoPostService: BasicInfoPostService,
    private rpaGetAllService: RpaGetAllService,
    private rpaGetService: RpaGetService,
    private rpaPostService: RpaPostService,
    private rpaUpdateStatusService: RpaUpdateStatusService,
    private reportGetService: ReportGetService,
    private changePasswordService: ChangePasswordService,
    private emailNotificationService: EmailNotificationService,
    private profilePictureService: ProfilePictureService,
    private adminBroadcastService: AdminBroadcastService,
    private customPaService: CustomPaService,
    private departmentService: DepartmentService,
    private uploadService: UploadService,
    private controllerService: ControllerService,
    private _controllerService: _ControllerService,
    private dpoService: DpoService,
    private rpaService: RpaService,
    private rpaInternallyProcessedService: RpaInternallyProcessedService,
    private rpaExternallyProcessedService: RpaExternallyProcessedService,
    private userService: UserService,
    private vendorsService: VendorsService,
    private userManagementService: UserManagementService,
    private tomsService: TomsService,
    private badgeService: BadgeService,

    private supervisionTemplatesService: SupervisionTemplatesService,
    private supervisionTemplatesBackendService: SupervisionTemplatesBackendService,
    private commentService: CommentService,
    private documentService: DocumentResourceService,
    // private paramsService: ParamsService,
  ) {
    overlayContainer.getContainerElement().classList.add('unicorn-dark-theme');
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
