import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'imprint-component',
  templateUrl: './imprint.template.html',
  styleUrls: ['./imprint.styles.scss']
})
export class ImprintComponent implements OnInit, OnDestroy {

  public contact: any = [
    {name: 'Telefon:', value: '+49 (0) 89 20 97 03 91'},
    {name: 'E-Mail:', value: '+hauke@ecomply.io'},
    {name: 'Website:', value: 'ecomply.io'}
  ];

  constructor() { }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {}

}
