import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from 'app/services/events.service';
import { BusService } from 'app/services/bus.service';
import { MatDialog } from '@angular/material';
import { AppConfig } from '../../../app.config';
import { DocumentsUploadDialogComponent } from '../modals/upload/documents-upload-dialog.component';
import { DocumentResourceService } from '../../../services/documents/document-resource.service';
import { UserService } from '../../../services/user/user.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { DeleteDocumentDialogComponent } from '../modals/delete/delete-document-dialog.component';
import { DocumentTaglistDialogComponent } from '../modals/taglist/document-taglist-dialog.component';


@Component({
  selector: 'app-documents-misc-list',
  templateUrl: './documents-misc-list.component.html',
  styleUrls: ['./documents-misc-list.component.scss']
})
export class DocumentsMiscListComponent implements OnInit, OnDestroy {
  appConfig = AppConfig;

  filter: string;

  allDocuments = [];
  historyDocuments = [];
  // filteredDocuments = [];

  users = [];

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private docService: DocumentResourceService,
    private userService: UserManagementService,
  ) {

  }

  get documents() {
    return this.filtered(this.allDocuments);
  }

  private filtered(documentList) {
    if (this.filter) {
      const match = key => key.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
      return documentList.filter(document => {
        if (document.fileName && match(document.fileName)) return true;

        const tags = document.tags.map((tag) => tag.value);
        const matches = tags.filter(s => s.includes(this.filter));

        if (matches.length > 0) return true;

        return false;
      });
    }
    else return documentList;
  }

  uploaderName(document) {
    const candidates = this.users.filter(user => user.id === document.uploaderId);
    if (candidates.length > 0) return candidates[0].name;
    return '---';
  }

  downloadLink(document) {
    return this.appConfig.documentDownloadUrl + document.id + `/file?token=${localStorage.getItem('currentUser')}`;
  }

  docImage(document) {
    if (document.extension) {
      let ext = document.extension.toLowerCase();
      if (ext == 'pdf') return 'assets/docs/pdf.svg';
      if (ext == 'doc' || ext == 'docx') return 'assets/docs/doc.svg';
      if (ext == 'xls' || ext == 'xlsx') return 'assets/docs/xls.svg';
    }

    return 'assets/docs/generic.svg';
  }

  limitTags(tags) {
    if (tags.length > 2) {
      return tags.slice(0, 2);
    }

    return tags;
  }

  showTags(document) {
    this.dialog.open(DocumentTaglistDialogComponent, {
      width: '350px',
      data: {
        document: document
      }
    });
  }

  editDocument(document, event) {
    if (event) event.stopPropagation();

    this.dialog.open(DocumentsUploadDialogComponent, {
      width: '700px',
      data: {
        isExistingDocument: true,
        document: document
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.documents.all);
      }
    });
  }

  deleteDocument(document, event) {
    if (event) event.stopPropagation();

    this.dialog.open(DeleteDocumentDialogComponent, {
      width: '450px',
      data: {
        document: document
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.documents.all);
      }
    });
  }

  public addDocument() {
    this.dialog.open(DocumentsUploadDialogComponent, {
      width: '700px',
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.documents.all);
      }
    });
  }

  receivedDocuments(documents) {
    this.allDocuments = documents.filter((d) => d.type === 'pa-history');
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.documents.all.success, this.receivedDocuments, this);
  }

  refresh() {
    this.bus.publish(this.events.requested.data.documents.all);
  }

  public ngOnInit(): void {
    this.subscribe();

    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });

    this.bus.publish(this.events.requested.data.documents.all, true);
  }

  public ngOnDestroy(): void {
  }

}
