import { Component, OnInit, OnDestroy } from '@angular/core';

import { HomeComponentModel } from './home.model';
import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';


@Component({
  selector: 'home-component',
  templateUrl: './home.template.html',
  styleUrls: ['./home.styles.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  private currentUser: HomeComponentModel;

  constructor(
    private bus : BusService,
    private events : EventsService,
  ) { }

  public ngOnInit(): void {
    this.bus.publish(this.events.ui.requested.sidenav.expand);
  }

  public ngOnDestroy(): void {
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
  }

}
