import {Injectable} from '@angular/core';
import {BusService} from './bus.service';
import {Router} from '@angular/router';
// import { NgXCookies } from "ngx-cookies";
import {EventsService} from './events.service';

@Injectable()
export class AuthenticationService {

  private _token: string;
  private _id: string;
  private _firstName: string;
  private _lastName: string;
  private _username: string;
  private _email: string;
  private _role: string;
  private _controllerId: number;
  public redirectUrl: string;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private router: Router
  ) {
    bus.subscribe(events.received.authentication.success, this.receivedAuthentication, this);
    // bus.subscribe(events.received.authentication.failure, this.receivedLogout);
    bus.subscribe(events.notified.user.logout, this.receivedLogout, this);
    bus.subscribe(events.received.data.user.info.success, this.updateUserInfo, this);
    bus.subscribe(events.notified.supervision.client.changed, this.clientChanged, this);
  }

  public get id(): string {
    return this._id || localStorage.getItem('currentUserId');
  }

  public get token(): string {
    if (this._token) {
      return this._token;
    }

    const tokenFromLocal = localStorage.getItem('currentUser');
    return tokenFromLocal ? tokenFromLocal : undefined;
  }

  public get firstName(): string {
    return this._firstName || localStorage.getItem('currentUserFirstName');
  }

  public get lastName(): string {
    return this._lastName || localStorage.getItem('currentUserLastName');
  }

  public get email(): string {
    return this._email || localStorage.getItem('currentUserEmail');
  }

  public get username(): string {
    return this._username || localStorage.getItem('currentUsername');
  }

  public get role(): string {
    return this._role || localStorage.getItem('currentUserRole');
  }

  public get controllerId(): number {
    return this._controllerId || parseInt(localStorage.getItem('currentUserControllerId'), 10);
  }

  private receivedAuthentication(data: any): void {
    this._token = data.token;
    this.updateUserInfo(data);
    this.redirect(data.next, data.nextParams);
  }

  private clientChanged(payload) {
    const { newToken } = payload;

    this._token = newToken || localStorage.getItem('currentUser');
  }

  updateUserInfo(data) {
    this._id = data._id || this._id;
    this._firstName = data.firstName || this._firstName;
    this._lastName = data.lastName || this._lastName;
    this._username = data.username || this._username;
    this._email = data.email || this._email;
    this._role = data.role || this._role;
    this._controllerId = data.controllerId || this._controllerId;

    localStorage.setItem('currentUserId', this._id);
    localStorage.setItem('currentUserFirstName', this._firstName);
    localStorage.setItem('currentUserLastName', this._lastName);
    localStorage.setItem('currentUserEmail', this._email);
    localStorage.setItem('currentUsername', this._username);
    localStorage.setItem('currentUserRole', this._role);
    localStorage.setItem('currentUserControllerId', `${this._controllerId}`);
  }

  private redirect(next?: string, nextParams?: any): void {
    if (next) {
      this.router.navigate([next], {queryParams: nextParams || {}});
    } else if (this.redirectUrl && !this.redirectUrl.startsWith('/todolist')) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = undefined;
    } else {
      this.router.navigate(['todolist']);
    }
  }

  private receivedLogout(): void {
    this._token = null;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserId');
    localStorage.removeItem('currentUserFirstName');
    localStorage.removeItem('currentUserLastName');
    localStorage.removeItem('currentUserEmail');
    localStorage.removeItem('currentUserIntercomHash');
    localStorage.removeItem('currentUserProductType');
    localStorage.removeItem('currentUserControllerId');
    localStorage.removeItem('currentUserDepId');
    localStorage.removeItem('currentUserAccess');

    if (localStorage.getItem('isInSupervisionMode')) {
      localStorage.removeItem('isInSupervisionMode');
      localStorage.removeItem('supervisorToken');
    }

    this.router.navigate(['home']);
  }

}
