import {Component, Input, OnInit} from '@angular/core';
import {LogbookAction} from "../../../../services/actions/actions.service";
import {MatDialog} from "@angular/material/dialog";
import {PaDetailsChangesDialogComponent} from "./changes-dialog/changes-dialog.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pa-last-change',
  templateUrl: './last-change-indicator.component.html',
  styleUrls: ['./last-change-indicator.component.scss']
})
export class LastChangeIndicatorComponent implements OnInit {
  @Input() changes: LogbookAction[];

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  get hasChanges() {
    return this.changes && this.changes.length > 0;
  }

  showChanges() {
    this.dialog.open(PaDetailsChangesDialogComponent, { data: { changes: this.changes.sort((a, b) => this.compareDate(a.timestampAt, b.timestampAt, false)) }, width: '450px' });
  }

  compareDate(a: Date, b: Date, isAsc: boolean) {
    if (a === null) {
      return (isAsc ? 1 : -1);
    }
    if (b === null) {
      return (isAsc ? -1 : 1);
    }

    return (new Date(a).getTime() < new Date(b).getTime() ? -1 : 1) * (isAsc ? 1 : -1);
  }


}
