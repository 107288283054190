import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';

import {BusService} from '../../../services/bus.service';
import {EventsService} from '../../../services/events.service';


@Component({
  selector: 'app-governance',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit, OnDestroy {

  public departments = [];

  constructor(
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  fetchDepartments() {
    this.bus.publish(this.events.requested.data.departments.get);
  }

  public progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_departments',
    });
  }

  public edit(department) {
    if (this.canWrite) {
      this.dialog.open(EditDepartmentDialogComponent, {
        width: '512px',
        data: department,
      }).afterClosed().subscribe(result => {
        if (result) {
          if (result.update) {
            Object.assign(department, result.update);
            this.bus.publish(this.events.requested.data.department.update, department);
          }
          else if (result.delete) {
            this.bus.publish(this.events.requested.data.department.delete, result.delete.id);
          }
        }
      });
    }
  }

  public add() {
    this.dialog.open(EditDepartmentDialogComponent, {
      width: '512px'
    }).afterClosed().subscribe(result => {
      if (result && result.add) {
        this.bus.publish(this.events.requested.data.department.add, result.add);
      }
    });
  }

  public done() {
    if (this.departments.length > 0) {
      this.progress(1);
      this.router.navigate(['']);
    }
  }

  public get canWrite() {
    return true;
  }

  private updateDepartments(data: any): void {
    this.departments = data;
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.received.data.department.add.success, this.fetchDepartments, this);
    this.bus.subscribe(this.events.received.data.department.delete.success, this.fetchDepartments, this);
    this.bus.subscribe(this.events.received.data.departments.get.success, this.updateDepartments, this);
  }

  public unSubscribe(): void {
    this.bus.unsubscribe(this.events.received.data.department.add.success, this.fetchDepartments);
    this.bus.unsubscribe(this.events.received.data.department.delete.success, this.fetchDepartments);
    this.bus.unsubscribe(this.events.received.data.departments.get.success, this.updateDepartments);
  }

  ngOnInit() {
    this.subscribe();
    this.fetchDepartments();
  }

  public ngOnDestroy(): void {
    this.unSubscribe();
  }
}

@Component({
  selector: 'edit-department-dialog',
  templateUrl: './edit-department.dialog.template.html',
  styleUrls: ['./departments.dialog.styles.scss'],
})
export class EditDepartmentDialogComponent {
  private syncHeadAndDpc: boolean = false;
  name = new FormControl('', [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public department: any,
    public dialogRef: MatDialogRef<EditDepartmentDialogComponent>,
  ){
    this.department = Object.assign({}, this.department || {
      name: "",
      headFirstName: "",
      headLastName: "",
      headEmail: "",
      dpCoordinatorFirstName: "",
      dpCoordinatorLastName: "",
      dpCoordinatorEmail: "",
    });

    this.syncHeadAndDpc = this.isHeadSameAsDpc;
  }

  get isHeadSameAsDpc() {
    return (this.department.headFirstName == this.department.dpCoordinatorFirstName &&
            this.department.headLastName == this.department.dpCoordinatorLastName &&
            this.department.headEmail == this.department.dpCoordinatorEmail) ||
            (!this.department.dpCoordinatorFirstName &&
            !this.department.dpCoordinatorLastName &&
            !this.department.dpCoordinatorEmail);
  }

  checkAndAutofillDpc() {
    if (this.syncHeadAndDpc) {
      this.department.dpCoordinatorFirstName = this.department.headFirstName;
      this.department.dpCoordinatorLastName = this.department.headLastName;
      this.department.dpCoordinatorEmail = this.department.headEmail;
    }
  }

  updateHeadAndDpcSync() {
    this.syncHeadAndDpc = this.isHeadSameAsDpc;
  }

  delete() {
    this.dialogRef.close({
      delete: this.department,
    });
  }

  save() {
    if (this.name.valid) {
      if (this.department.id)
        this.dialogRef.close({
          update: this.department,
        });
      else
        this.dialogRef.close({
          add: this.department,
        });
    }
  }
}
