import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {
  IPostOptions,
  RequestService
} from '../request.service';
import {EventsService} from '../events.service';


interface IPostCredentialsRequest {
}

interface IPostCredentialsResponse {
}

/*
  An interlayer service for rpa get request between request service and rpa component
*/
@Injectable()
export class VerificationService {

  private options: IPostOptions<IPostCredentialsRequest, IPostCredentialsResponse>;

  constructor(private bus: BusService,
              private events: EventsService,
              private requestService: RequestService) {
    this.options = {
      uri: '/account/verification',
      body: {},
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: IPostCredentialsResponse): void {
    this.bus.publish(this.events.received.action.verification.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.action.verification.failure, httpErrorResponse);
  }

  private request(): void {
    this.requestService.post<IPostCredentialsRequest, IPostCredentialsResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.action.verification, this.request.bind(this));
  }
}
