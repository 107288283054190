import { Component, OnInit } from '@angular/core';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Params } from '@angular/router';
import { RecordsResourceService, RecordsModel } from 'app/services/records/record-resource.service';
import { MeasuresCreateDialogComponent } from '../create-dialog/create-dialog.component';
import { getRefNum } from '../../util';


@Component({
  selector: 'app-measure-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class MeasuresDetailsComponent implements OnInit {
  record: RecordsModel;
  id: number;
  documents = [];

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private recordsService: RecordsResourceService // Needs to be here to catch direct URL visits
  ) { }

  get hasTitle() {
    return this.record.title && this.record.title.length > 0;
  }

  private receiveRecord(record: RecordsModel) {
    this.record = record;
    this.documents = record.documents;
  }

  getRefNumber(record: RecordsModel) {
    if (record) {
      return getRefNum(record);
    }
  }

  edit() {
    this.dialog.open(MeasuresCreateDialogComponent, {
      width: '700px',
      data: this.record
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.requestRefresh();
      }
    });
  }

  requestRefresh() {
    this.bus.publish(this.events.requested.data.records.single, this.record);
  }

  handleError(error: string) {
  }

  ngOnInit() {
    this.subscribe();
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.records.single.success, this.receiveRecord.bind(this));
    this.bus.subscribe(this.events.received.data.records.single.failure, this.handleError.bind(this));

    this.activatedRoute.params.subscribe((params: Params) => {
      const model = { id: params.id, type: 'measure' };
      this.id = params.id; // Masks console error while loading and passing to ViewChildren
      this.bus.publish(this.events.requested.data.records.single, model);
    });
  }

  /* DOCUMENTS */

  // each record has its own custom upload URL which handles associations
  get uploadUrl() {
    if (this.record) {
      return '/records/measure/' + this.record.id + '/file';
    }
  }
}
