import {Component, OnInit, OnDestroy} from '@angular/core';
import {BasicInfoControllerComponentModel} from './basic-info.rpa.model';
import {BusService} from '../../../services/bus.service';
import {EventsService} from '../../../services/events.service';
import BasicInfoForm from './basic-info.rpa.form';

@Component({
  selector: 'basic-info-component',
  templateUrl: './basic-info.rpa.template.html',
  styleUrls: ['./basic-info.rpa.styles.scss']
})
export class BasicInfoComponent implements OnInit, OnDestroy {
  public model: BasicInfoControllerComponentModel;
  public form: BasicInfoForm;
  public isFormErrorMessage: boolean = false;
  private timeout: any;

  private submitted = false;
  fieldsBeingSaved = {};

  public currentUser: BasicInfoControllerComponentModel;
  public users: BasicInfoControllerComponentModel[] = [];

  constructor(
    private bus: BusService,
    private events: EventsService
  ) {
    this.bus.publish(this.events.requested.data.basicInfo.get, localStorage.getItem('currentUserID'));
    this.model = new BasicInfoControllerComponentModel();
    this.form = new BasicInfoForm(this.model);
  }

  // set basic-info data
  private setData(data: BasicInfoControllerComponentModel): void {
    this.model = data;
    this.form.patchForm(data);
  }

  // start form validation (to show what is wrong)
  private invalidForm(): void {
    this.form.validate();
    this.isFormErrorMessage = true;
  }

  // auto-saving with validation(not yet available)
  public submit(field?): void {
    clearTimeout(this.timeout);
    if (field) {
      this.fieldsBeingSaved[field] = true;
    }
    this.timeout = setTimeout(() => {
      if (field)
        this.fieldsBeingSaved[field] = false;
      this.bus.publish(this.events.notified.validation.form.aggregation, this.form.isValid);
      this.bus.publish(this.events.notified.validation.form.action);
    }, 1000);
  }

  // start basic-info update request
  private basicInfoPost(): void {
    this.bus.publish(this.events.requested.data.basicInfo.update, {data: this.form.formData, id: localStorage.getItem('currentUserID')});
  }

  // subscribe on validation success/failure and get basic-info request
  public subscribe(): void {
    this.bus.subscribe(this.events.received.validation.failure, this.invalidForm, this);
    this.bus.subscribe(this.events.received.validation.success, this.basicInfoPost, this);
    this.bus.subscribe(this.events.received.data.basicInfo.get.success, this.setData, this);
  }

  // unsubscribe on validation success/failure and get basic-info request
  public unSubscribe(): void {
    this.bus.unsubscribe(this.events.received.validation.failure, this.invalidForm);
    this.bus.unsubscribe(this.events.received.validation.success, this.basicInfoPost);
    this.bus.unsubscribe(this.events.received.data.basicInfo.get.success, this.setData);
  }

  // make subscribe on a component initialization
  public ngOnInit(): void {
    this.subscribe();
  }

  // make unsubscribe before destroying component
  public ngOnDestroy(): void {
    this.unSubscribe();
  }

}
