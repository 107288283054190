import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

import { InvitationResultDialogComponent } from '../invitation-result-dialog/invitation-result-dialog.component';


@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss']
})
export class InviteUserDialogComponent implements OnInit, OnDestroy {

  firstName = new FormControl('');
  lastName = new FormControl('');
  email = new FormControl('', [Validators.required, Validators.email]);
  departmentId = new FormControl(undefined);
  access = new FormControl('users.access.readAll-writeAll');

  departments = [];

  constructor(
    private dialogRef: MatDialogRef<InviteUserDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.departments = data.departments;
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  invite() {
    this.email.markAsTouched();
    if (this.email.valid) {
      this.bus.publish(this.events.requested.action.controller.users.invite, {
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        email: this.email.value,
        departmentId: this.departmentId.value,
        access: this.access.value,
      });
    }
  }

  invited() {
    this.dialogRef.close(true);
  }

  inviteFailed(error) {
    this.dialog.open(InvitationResultDialogComponent, {
      width: '350px',
      data: error.error
    });
  }

  depSelected() {
    if (this.departmentId.value)
      this.access.setValue('users.access.readDep-writeDep');
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.controller.users.invite.success, this.invited, this);
    this.bus.subscribe(this.events.received.action.controller.users.invite.failure, this.inviteFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.controller.users.invite.success, this.invited);
    this.bus.unsubscribe(this.events.received.action.controller.users.invite.failure, this.inviteFailed);
  }
}
