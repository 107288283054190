/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';


// TODO: Refactor this into services/report/xls-export.service
@Injectable()
export class RpaXLSXExportService {
  transMessages = [
    'custom-pa.namePa',
    'custom-pa.responsibleDept',
    'pa.description.description',
    'pa.purpose.description',
    'pa.legalbasis.otherPlaceholder',
    'pa.legalbasis.description',
    'pa.legalbasis.consent',
    'pa.legalbasis.contractualNecessity',
    'pa.legalbasis.complianceWithOther',
    'pa.legalbasis.vitalInterest',
    'pa.legalbasis.publicInterest',
    'pa.legalbasis.legitimamteInterest',
    'pa.legalbasis.members',
    'pa.legalbasis.law',
    'pa.dataSubjectCategories.description',
    'pa.dataSubject.categories.employees',
    'pa.dataSubject.categories.students',
    'pa.dataSubject.categories.applicants',
    'pa.dataSubject.categories.trainees',
    'pa.dataSubject.categories.temporaryWorkers',
    'pa.dataSubject.categories.managers',
    'pa.dataSubject.categories.commercialAgent',
    'pa.dataSubject.categories.serviceProvider',
    'pa.dataSubject.categories.suppliers',
    'pa.dataSubject.categories.interestedParties',
    'pa.dataSubject.categories.contractualPartner',
    'pa.dataSubject.categories.customers',
    'pa.dataSubject.categories.customersOfCustomers',
    'pa.dataSubject.categories.employeesOfCustomers',
    'pa.dataSubject.categories.members',
    'pa.dataSubject.categories.debtor',
    'pa.dataSubject.categories.investor',
    'pa.dataSubject.categories.jointController',
    'pa.dataSubject.categories.other',
    'pa.dataSubjectCategories.otherPlaceholder',
    'pa.dataCategoriesDescription.description',
    'pa.dataSubject.dataCategories.name',
    'pa.dataSubject.dataCategories.address',
    'pa.dataSubject.dataCategories.email',
    'pa.dataSubject.dataCategories.phone',
    'pa.dataSubject.dataCategories.fax',
    'pa.dataSubject.dataCategories.login',
    'pa.dataSubject.dataCategories.messengerIdentity',
    'pa.dataSubject.dataCategories.ageRanges',
    'pa.dataSubject.dataCategories.birthInformation',
    'pa.dataSubject.dataCategories.family',
    'pa.dataSubject.dataCategories.maritalStatus',
    'pa.dataSubject.dataCategories.likes',
    'pa.dataSubject.dataCategories.citizenship',
    'pa.dataSubject.dataCategories.passportId',
    'pa.dataSubject.dataCategories.educationalInfo',
    'pa.dataSubject.dataCategories.employmentInformation',
    'pa.dataSubject.dataCategories.licensePlate',
    'pa.dataSubject.dataCategories.insuranceInformation',
    'pa.dataSubject.dataCategories.salaryInformation',
    'pa.dataSubject.dataCategories.bankDetails',
    'pa.dataSubject.dataCategories.orderHistory',
    'pa.dataSubject.dataCategories.ip',
    'pa.dataSubject.dataCategories.gps',
    'pa.dataSubject.dataCategories.location',
    'pa.dataSubject.dataCategories.social',
    'pa.dataSubject.dataCategories.criminal',
    'pa.dataSubject.dataCategories.biometricInfo',
    'pa.dataSubject.dataCategories.geneticData',
    'pa.dataSubject.dataCategories.physicalTraits',
    'pa.dataSubject.dataCategories.imageData',
    'pa.dataSubject.dataCategories.ethnicity',
    'pa.dataSubject.dataCategories.healthData',
    'pa.dataSubject.dataCategories.religion',
    'pa.dataSubject.dataCategories.tradeUnion',
    'pa.dataSubject.dataCategories.politicalInformation',
    'pa.dataSubject.dataCategories.sex',
    'pa.dataSubject.dataCategories.other',
    'pa.dataCategoriesDescription.other',
    'pa.dataSource.description',
    'pa.dataSource.otherPlaceholder',
    'pa.dataSource.tumOnline',
    'pa.dataSource.sap',
    'pa.dataSource.directoryServices',
    'pa.dataSource.publicInformation',
    'pa.dataSource.fromSubject',
    'pa.dataSource.processingOnBehalf',
    'pa.dataSource.purchased',
    'pa.dataSource.other',
    'pa.erasureTime.description',
    'pa.erasureTimeConsideration.description',
    'pa.toms.description',
    'pa.generalRemarks.description',
    'pa.dpoStatement.description',
    'pa.responsiblePerson.description'
  ];

  constructor(private trans: TranslateService) { }

  private _getTranslations(...messages: string[]) {
    return zip(...messages.map(msg => this.trans.get(msg)))
      .pipe(map(res => {
        const dict = {};
        // eslint-disable-next-line guard-for-in
        for (const i in messages) {
          dict[messages[i]] = res[i];
        }

        return dict;
      }));
  }

  export(pas) {
    this._getTranslations(...this.transMessages).subscribe(dict => {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(pas.map(pa => {
        console.log(pa);
        const res = {};
        res['ID'] = 'VT-' + pa.paId;
        res[dict['custom-pa.namePa']] = pa.paName;
        res[dict['custom-pa.responsibleDept']] = pa.dep ? pa.dep.name : '';
        res[dict['pa.responsiblePerson.description']] = `${pa.respFirstName} ${pa.respLastName} -- ${pa.respEmail}`;
        res[dict['pa.description.description']] = pa.paDescription || pa.paDescriptionOriginal;
        res[dict['pa.purpose.description']] = pa.paPurpose;
        res[dict['pa.legalbasis.description']] =
          pa.paLegalBasis ? pa.paLegalBasis.map(_ => dict[_]).join(',') : '';
        res[dict['pa.legalbasis.otherPlaceholder']] = pa.paLegalBasisOther;
        res[dict['pa.dataSubjectCategories.description']] =
          pa.paDataSubjectCategories ? pa.paDataSubjectCategories.map(_ => dict[_]).join(',') : '';
        res[dict['pa.dataSubjectCategories.otherPlaceholder']] = pa.paDataSubjectCategoriesOther;
        res[dict['pa.dataCategoriesDescription.description']] =
          pa.paDataCategoriesDescription ? pa.paDataCategoriesDescription.map(_ => dict[_]).join(',') : '';
        res[dict['pa.dataCategoriesDescription.other']] = pa.paDataCategoriesDescriptionOther;
        res[dict['pa.dataSource.description']] = pa.paDataSource ? pa.paDataSource.map(_ => dict[_]).join(',') : '';
        res[dict['pa.dataSource.otherPlaceholder']] = pa.paDataSourceOther
        res[dict['pa.erasureTime.description']] = pa.erasureTime;
        res[dict['pa.erasureTimeConsideration.description']] = pa.erasureTimeConsideration;
        res[dict['pa.toms.description']] = pa.paToms;
        res[dict['pa.generalRemarks.description']] = pa.paGeneralRemarks;
        res[dict['pa.dpoStatement.description']] = pa.paDpoStatement;

        // some notes from EC-264

        // paTags?

        // what about DPIA
        // paDpiaAdditionalRemarks

        // do we need this?
        // tumOrgAssociation
        // tumResponsibleOrgUnit
        // tumResponsiblePerson
        // tumJointControllerInformation
        // creatorDirectoryId
        // respDirectoryId

        return res;
      }));
      XLSX.utils.book_append_sheet(wb, ws);
      const dateString = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`;
      XLSX.writeFile(wb, `PA_Export_${dateString}.xlsx`);
    });
  }
}
