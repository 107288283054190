import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'big-round-button',
  templateUrl: './big-round-button.component.html',
  styleUrls: ['./big-round-button.component.scss']
})
export class BigRoundButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
