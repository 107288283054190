import {Injectable} from '@angular/core';
import {RequestService} from '../request.service';
import {EventsService} from '../events.service';
import {BusService} from '../bus.service';


interface GetRequest {
}

export interface ControllerModel {
  controllerName: string;
  controllerUrl: string;
  controllerAddressStreet: string;
  controllerAddressPostcode: string;
  controllerAddressCity: string;
  controllerAddressCountry: string;
  controllerContactName: string;
  controllerContactRole: string;
  controllerEmailAddress: string;
  controllerNumEmployees: string;
  controllerTelephoneNumber: string;
  httpsOn: boolean;
  httpsRedirect: boolean;
  cookiePopupEnabled: boolean;
  cookiePolicyInPlace: boolean;
  urlPrivacyPolicy: string;
  urlCookiePolicy: string;
  contactFormChecked: boolean;
}

@Injectable()
export class ControllerService {

  constructor(private bus: BusService,
              private events: EventsService,
              private requestService: RequestService) {

    this.subscribe();
  }

  private subscribe() {
    this.bus.subscribe(this.events.requested.data.controller.get, this.requestGet.bind(this));
    this.bus.subscribe(this.events.requested.data.controller.update, this.requestPut.bind(this));
  }

  requestGet(data: GetRequest) {
    this.requestService.get<GetRequest>({
      uri: `/controller/`,
      handlers: {
        success: result => this.bus.publish(this.events.received.data.controller.get.success, result),
        error: error =>
          this.bus.publish(this.events.received.data.controller.get.failure, error),
      }
    });
  }

  requestPut(data: ControllerModel) {
    this.requestService.put<ControllerModel, {}> ( {
      uri: '/controller/',
      body: data,
      handlers: {
        success: result => this.bus.publish(this.events.received.data.controller.update.success, result),
        error: error =>
          this.bus.publish(this.events.received.data.controller.update.failure, error),
      }
    });
  }
}
