import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { AppConfig } from '../../../app.config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'report-component',
  templateUrl: './report.rpa.template.html',
  styleUrls: ['./report.rpa.styles.scss']
})
export class ReportComponent implements OnInit, OnDestroy {
  // TODO: No hardcoded links!
  public link = `${AppConfig.apiReport}/rpa/pdf?token=${localStorage.getItem('currentUser')}&lang=${localStorage.getItem('lang')}`;

  public jsonLink = `${AppConfig.apiReport}/rpa/json?token=${localStorage.getItem('currentUser')}&lang=${localStorage.getItem('lang')}`;
  public xlsLink = `${AppConfig.apiReport}/rpa/xls?token=${localStorage.getItem('currentUser')}&lang=${localStorage.getItem('lang')}`;
  public tomsLink = `${AppConfig.apiReport}/toms/pdf?token=${localStorage.getItem('currentUser')}&lang=${localStorage.getItem('lang')}`;

  public hasGoals = false;
  public goalsLink = `${AppConfig.apiUrl}/controllers/goals/?token=${localStorage.getItem('currentUser')}`;

  public hasDpo = false;
  public dpoLink = `${AppConfig.apiUrl}/dpo/appointment/?token=${localStorage.getItem('currentUser')}`;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private translate: TranslateService
  ) {
    this.bus.publish(this.events.requested.data.controller.hasGoalsDoc);
    this.bus.publish(this.events.requested.data.dpo.hasAppointment);
  }

  // set report link
  private setLink(link: string): void {
    if (link) {
      this.link = link;
    }
  }

  // subscribe on report request success
  public subscribe(): void {
    this.bus.subscribe(this.events.received.data.report.success, this.setLink, this);
    this.bus.subscribe(this.events.received.data.controller.hasGoalsDoc.success, this.updateGoals, this);
    this.bus.subscribe(this.events.received.data.dpo.hasAppointment.success, this.updateDpo, this);
  }

  // unsubscribe on report request success
  public unSubscribe(): void {
    this.bus.unsubscribe(this.events.received.data.report.success, this.setLink);
    this.bus.unsubscribe(this.events.received.data.controller.hasGoalsDoc.success, this.updateGoals);
    this.bus.unsubscribe(this.events.received.data.dpo.hasAppointment.success, this.updateDpo);
  }

  // make subscribe on a component initialization
  public ngOnInit(): void {
    this.subscribe();
  }

  // make unsubscribe before destroying component
  public ngOnDestroy(): void {
    this.unSubscribe();
  }

  /*
   * EE-1256: Translated intercom messages can
   * only be passed through TranslateService module.
   * BUG: On Windows or Chrome, no message appears
   *      but intercom window still functions.
   */
  public notifyIntercomForDocumentCheck(): void {
    this.translate.get('report.intercomMessage').subscribe(translated => {
      this.notifyIntercom(translated);
    });
  }

  public updateGoals(data) {
    this.hasGoals = data.hasGoalsDoc;
  }

  public updateDpo(data) {
    this.hasDpo = data.hasAppointmentDoc;
  }

  private notifyIntercom(input: string): void {
    // Err ...
  }
}
