import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { AccessControlService } from 'app/services/access-control.service';


export enum AccessCheckResult {
  Match,
  TooStrong,
  TooWeak,
  NotDetermined,
}


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'access-control',
  templateUrl: './access-control.component.html',
  styleUrls: ['./access-control.component.scss']
})
export class AccessControlComponent implements OnInit, OnChanges {
  @Input() atLeast: string;
  @Input() atMost: string;
  @Input() exactly: string;

  public accessStatus: AccessCheckResult;

  constructor(
    private accessControl: AccessControlService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    if (this.exactly) {
      this.atLeast = this.exactly;
      this.atMost = this.exactly;
    }

    this.accessStatus = this.hasAccess;
  }

  public get hasAccess(): AccessCheckResult {
    let strongEnough = true;
    let weakEnough = true;

    if (this.atLeast) {
      strongEnough = this.accessControl.hasRoleOrStronger(this.atLeast);
    }

    if (this.atMost) {
      weakEnough = this.accessControl.hasRoleOrWeaker(this.atMost);
    }

    if (strongEnough && weakEnough) {
      return AccessCheckResult.Match;
    }

    if (!strongEnough) {
      return AccessCheckResult.TooWeak;
    }

    if (!weakEnough) {
      return AccessCheckResult.TooStrong;
    }

    return AccessCheckResult.NotDetermined;
  }

  public get match(): boolean { return this.accessStatus === AccessCheckResult.Match; }
  public get tooWeak(): boolean { return this.accessStatus === AccessCheckResult.TooWeak; }
  public get tooStrong(): boolean { return this.accessStatus === AccessCheckResult.TooStrong; }

  public get accessCheckResult() { return AccessCheckResult; }
}
