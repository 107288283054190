import { Injectable } from '@angular/core';

/*
  service with event names we are using at app
*/
@Injectable()
export class EventsService {
  public notified = {
    user: {
      logout: 'notified.user.logout',
      logoUpdate: 'notified.user.logoUpdate',
    },
    todos: {
      update: 'notified.todos.update',
      singleUpdate: 'notified.todos.singleUpdate',
      create: 'notified.todos.create',
    },
    validation: {
      form: {
        aggregation: 'notified.validation.form.aggregation',
        action: 'notified.validation.form.action'
      }
    },
    request: {
      starting: 'notified.request.starting',
      finished: 'notified.request.finished'
    },
    saving: {
      starting: 'notified.saving.starting',
      finished: 'notified.saving.finished'
    },
    supervision: {
      client: {
        started: 'notified.supervision.client.started',
        ended: 'notified.supervision.client.ended',
        changed: 'notified.supervision.client.changed'
      }
    }
  };
  public received = {
    authentication: {
      success: 'received.authentication.success',
      failure: 'received.authentication.failure',
    },
    registration: {
      success: 'received.registration.success',
      failure: 'received.registration.failure'
    },
    validation: {
      success: 'received.validation.success',
      failure: 'received.validation.failure'
    },
    action: {
      resetPasswordEmail: {
        success: 'received.action.resetPasswordEmail.success',
        failure: 'received.action.resetPasswordEmail.failure',
      },
      verification: {
        success: 'received.action.verification.success',
        failure: 'received.action.verification.failure'
      },
      checkEmail: {
        success: 'received.action.checkEmail.success',
        failure: 'received.action.checkEmail.failure'
      },
      tasks: {
        progressControllerTask: {
          success: 'received.action.tasks.progressControllerTask.success',
          failure: 'received.action.tasks.progressControllerTask.failure',
        }
      },
      rpa: {
        assign: {
          success: 'received.action.rpa.assign.success',
          failure: 'received.action.rpa.assign.failure',
        }
      },
      rpaOnBehalf: {
        workflow: {
          add: {
            success: 'received.action.rpaOnBehalf.workflow.add.success',
            failure: 'received.action.rpaOnBehalf.workflow.add.failure',
          },
          delete: {
            success: 'received.action.rpaOnBehalf.workflow.delete.success',
            failure: 'received.action.rpaOnBehalf.workflow.delete.failure',
          },
        },
      },
      vendors: {
        contactForDPA: {
          success: 'received.action.vendors.contactForDPA.success',
          failure: 'received.action.vendors.contactForDPA.failure',
        },
        toggleDPARequired: {
          success: 'received.action.vendors.toggleDPARequired.success',
          failure: 'received.action.vendors.toggleDPARequired.failure',
        },
      },
      toms: {
        updateEntry: {
          success: 'received.action.toms.updateEntry.success',
          failure: 'received.action.toms.updateEntry.failure',
        },
        markCategoryAsDone: {
          success: 'received.action.toms.markCategoryAsDone.success',
          failure: 'received.action.toms.markCategoryAsDone.failure',
        },
      },
      controller: {
        users: {
          update: {
            success: 'received.action.controller.users.update.success',
            failure: 'received.action.controller.users.update.failure',
          },
          invite: {
            success: 'received.action.controller.users.invite.success',
            failure: 'received.action.controller.users.invite.failure',
          },
          deleteInvite: {
            success: 'received.action.controller.users.deleteInvite.success',
            failure: 'received.action.controller.users.deleteInvite.failure',
          },
          resendInvite: {
            success: 'received.action.controller.users.resendInvite.success',
            failure: 'received.action.controller.users.resendInvite.failure',
          },
          confirmInvite: {
            success: 'received.action.controller.users.confirmInvite.success',
            failure: 'received.action.controller.users.confirmInvite.failure',
          },
        }
      },
      supervision: {
        clients: {
          add: {
            invite: {
              success: 'received.action.supervision.clients.add.invite.success',
              failure: 'received.action.supervision.clients.add.invite.failure'
            },
            controller: {
              success: 'received.action.supervision.clients.add.controller.success',
              failure: 'received.action.supervision.clients.add.controller.failure'
            }
          },
          delete: {
            success: 'received.action.supervision.clients.delete.success',
            failure: 'received.action.supervision.clients.delete.failure'
          }
        },
        clientInvitation: {
          accept: {
            success: 'received.action.supervision.clientInvitation.accept.success',
            failure: 'received.action.supervision.clientInvitation.accept.failure'
          },
          resend: {
            success: 'received.action.supervision.clientInvitation.resend.success',
            failure: 'received.action.supervision.clientInvitation.resend.failure',
          }
        },
        templates: {
          create: {
            success: 'received.action.supervision.templates.create.success',
            failure: 'received.action.supervision.templates.create.failure',
          },
          update: {
            success: 'received.action.supervision.templates.update.success',
            failure: 'received.action.supervision.templates.update.failure',
          },
          apply: {
            success: 'received.action.supervision.templates.apply.success',
            failure: 'received.action.supervision.templates.apply.failure',
          },
          delete: {
            success: 'received.action.supervision.templates.delete.success',
            failure: 'received.action.supervision.templates.delete.failure',
          }
        },
        supervisor: {
          profile: {
            update: {
              success: 'received.action.supervision.supervisor.profile.update.success',
              failure: 'received.action.supervision.supervisor.profile.update.failure'
            }
          }
        }
      },
      documents: {
        update: {
          success: 'received.action.documents.update.success',
          failure: 'received.action.documents.update.failure'
        },
        delete: {
          success: 'received.action.documents.delete.success',
          failure: 'received.action.documents.delete.failure'
        }
      },
      dsar: {
        update: {
          success: 'received.action.dsar.update.success',
          failure: 'received.action.dsar.update.failure'
        },
        create: {
          success: 'received.action.dsar.create.success',
          failure: 'received.action.dsar.create.failure'
        },
        delete: {
          success: 'received.action.dsar.delete.success',
          failure: 'received.action.dsar.delete.failure'
        }
      },
      incidents: {
        update: {
          success: 'received.action.incidents.update.success',
          failure: 'received.action.incidents.update.failure'
        },
        create: {
          success: 'received.action.incidents.create.success',
          failure: 'received.action.incidents.create.failure'
        },
        delete: {
          success: 'received.action.incidents.delete.success',
          failure: 'received.action.incidents.delete.failure'
        }
      },
      records: {
        update: {
          success: 'received.action.records.update.success',
          failure: 'received.action.records.update.failure'
        },
        create: {
          success: 'received.action.records.create.success',
          failure: 'received.action.records.create.failure'
        },
        delete: {
          success: 'received.action.records.delete.success',
          failure: 'received.action.records.delete.failure'
        }
      }
    },
    data: {
      badge: {
        get: {
          success: 'received.data.badge.get.success',
          failure: 'received.data.badge.get.failure',
        },
        info: {
          success: 'received.data.badge.info.success',
          failure: 'received.data.badge.info.failure',
        },
      },
      comments: {
        get: {
          success: 'received.data.comments.get.success',
          failure: 'received.data.comments.get.failure',
        },
        post: {
          success: 'received.data.comments.post.success',
          failure: 'received.data.comments.post.failure',
        }
      },
      user: {
        info: {
          success: 'received.data.user.info.success',
          failure: 'received.data.user.info.failure',
        },
        access: {
          success: 'received.data.user.access.success',
          failure: 'received.data.user.access.failure',
        }
      },
      basicInfo: {
        get: {
          success: 'received.data.basicInfo.get.success',
          failure: 'received.data.basicInfo.get.failure'
        },
        update: {
          success: 'received.data.basicInfo.update.success',
          failure: 'received.data.basicInfo.update.failure'
        }
      },
      controller: {
        get: {
          success: 'received.data.controller.get.success',
          failure: 'received.data.controller.get.failure'
        },
        update: {
          success: 'received.data.controller.update.success',
          failure: 'received.data.controller.update.failure'
        },
        name: {
          success: 'received.data.controller.name.success',
          failure: 'received.data.controller.name.failure',
        },
        updateName: {
          success: 'received.data.controller.updateName.success',
          failure: 'received.data.controller.updateName.failure',
        },
        hasLogo: {
          success: 'received.data.controller.hasLogo.success',
          failure: 'received.data.controller.hasLogo.failure',
        },
        hasGoalsDoc: {
          success: 'received.data.controller.hasGoalsDoc.success',
          failure: 'received.data.controller.hasGoalsDoc.failure',
        },
        users: {
          success: 'received.data.controller.users.success',
          failure: 'received.data.controller.users.failure',
        },
        invites: {
          success: 'received.data.controller.invites.success',
          failure: 'received.data.controller.invites.failure',
        },
        decodeInviteToken: {
          success: 'received.data.controller.invites.decode.success',
          failure: 'received.data.controller.invites.decode.failure',
        },
      },
      dpo: {
        get: {
          success: 'received.data.dpo.get.success',
          failure: 'received.data.dpo.get.failure',
        },
        put: {
          success: 'received.data.dpo.put.success',
          failure: 'received.data.dpo.put.failure',
        },
        hasAppointment: {
          success: 'received.data.dpo.hasAppointment.success',
          failure: 'received.data.dpo.hasAppointment.failure',
        },
      },
      report: {
        success: 'received.data.report.success',
        failure: 'received.data.report.failure'
      },
      rpa: {
        get: {
          success: 'received.data.rpa.get.success',
          failure: 'received.data.rpa.get.failure'
        },
        getAll: {
          success: 'received.data.rpa.getAll.success',
          failure: 'received.data.rpa.getAll.failure'
        },
        getApplicable: {
          success: 'received.data.rpa.getApplicable.success',
          failure: 'received.data.rpa.getApplicable.failure',
        },
        getComplex: {
          success: 'received.data.rpa.getComplex.success',
          failure: 'received.data.rpa.getComplex.failure',
        },
        answer: {
          success: 'received.data.rpa.answer.success',
          failure: 'received.data.rpa.answer.failure'
        },
        update: {
          success: 'received.data.rpa.update.success',
          failure: 'received.data.rpa.update.failure'
        },
        updateStatus: {
          success: 'received.data.rpa.updateStatus.success',
          failure: 'received.data.rpa.updateStatus.failure',
        },
        getAllStatus: {
          success: 'received.data.rpa.getAllStatus.success',
          failure: 'received.data.rpa.getAllStatus.failure'
        },
        customPa: {
          get: {
            success: 'received.data.rpa.customPa.get.success',
            failure: 'received.data.rpa.customPa.get.failure',
          },
          add: {
            success: 'received.data.rpa.customPa.add.success',
            failure: 'received.data.rpa.customPa.add.failure',
          },
          update: {
            success: 'received.data.rpa.customPa.update.success',
            failure: 'received.data.rpa.customPa.update.failure',
          },
          delete: {
            success: 'received.data.rpa.customPa.delete.success',
            failure: 'received.data.rpa.customPa.delete.failure',
          },
        },
        basics: {
          updateField: {
            success: 'received.data.rpa.basics.updateField.success',
            failure: 'received.data.rpa.basics.updateField.failure',
          },
          progress: {
            success: 'received.data.rpa.basics.progress.success',
            failure: 'received.data.rpa.basics.progress.failure',
          },
        },
        internallyProcessed: {
          pas: {
            success: 'received.data.rpa.internallyProcessed.pas.success',
            failure: 'received.data.rpa.internallyProcessed.pas.failure',
          },
          associations: {
            success: 'received.data.rpa.internallyProcessed.associations.success',
            failure: 'received.data.rpa.internallyProcessed.associations.failure',
          },
          associate: {
            success: 'received.data.rpa.internallyProcessed.associate.success',
            failure: 'received.data.rpa.internallyProcessed.associate.failure',
          },
          disassociate: {
            success: 'received.data.rpa.internallyProcessed.disassociate.success',
            failure: 'received.data.rpa.internallyProcessed.disassociate.failure',
          },
        },
        externallyProcessed: {
          pas: {
            success: 'received.data.rpa.externallyProcessed.pas.success',
            failure: 'received.data.rpa.externallyProcessed.pas.failure',
          },
          vendors: {
            success: 'received.data.rpa.externallyProcessed.vendors.success',
            failure: 'received.data.rpa.externallyProcessed.vendors.failure',
          },
          associate: {
            success: 'received.data.rpa.externallyProcessed.associate.success',
            failure: 'received.data.rpa.externallyProcessed.associate.failure',
          },
          disassociate: {
            success: 'received.data.rpa.externallyProcessed.disassociate.success',
            failure: 'received.data.rpa.externallyProcessed.disassociate.failure',
          },
        },
      },
      percentage: {
        success: 'received.data.percentage.success',
        failure: 'received.data.percentage.failure'
      },
      messageBroadcast: {
        success: 'received.data.messageBroadcast.success',
        failure: 'received.data.messageBroadcast.failure'
      },
      todos: {
        workflows: {
          success: 'received.data.todos.workflows.success',
          failure: 'received.data.todos.workflows.failure',
        },
        milestoneOne: {
          success: 'received.data.todos.milestoneOneProgress.success',
          failure: 'received.data.todos.milestoneOneProgress.failure',
        },
        milestoneTwo: {
          success: 'received.data.todos.milestoneTwoProgress.success',
          failure: 'received.data.todos.milestoneTwoProgress.failure',
        },
        controllerTaskProgress: {
          success: 'received.data.todos.controllerTaskProgress.success',
          failure: 'received.data.todos.controllerTaskProgress.failure',
        }
      },
      departments: {
        get: {
          success: 'received.data.departments.get.success',
          failure: 'received.data.departments.get.failure'
        },
      },
      department: {
        single: {
          success: 'received.data.department.single.success',
          failure: 'received.data.department.single.failure'
        },
        add: {
          success: 'received.data.department.add.success',
          failure: 'received.data.department.add.failure'
        },
        update: {
          success: 'received.data.department.update.success',
          failure: 'received.data.department.update.failure'
        },
        delete: {
          success: 'received.data.department.delete.success',
          failure: 'received.data.department.delete.failure',
        },
      },
      vendors: {
        get: {
          success: 'received.data.vendors.get.success',
          failure: 'received.data.vendors.get.failure',
        },
        add: {
          success: 'received.data.vendors.add.success',
          failure: 'received.data.vendors.add.failure',
        },
        controllerVendors: {
          success: 'received.data.vendors.controllerVendors.success',
          failure: 'received.data.vendors.controllerVendors.failure',
        },
        decodeDPAToken: {
          success: 'received.data.vendors.decodeDPAToken.success',
          failure: 'received.data.vendors.decodeDPAToken.failure',
        },
      },
      toms: {
        category: {
          success: 'received.data.toms.category.success',
          failure: 'received.data.toms.category.failure',
        },
        categories: {
          success: 'received.data.toms.categories.success',
          failure: 'received.data.toms.categories.failure',
        },
        categoryEntries: {
          success: 'received.data.toms.categoryEntries.success',
          failure: 'received.data.toms.categoryEntries.failure',
        },
        singleEntry: {
          success: 'received.data.toms.singleEntry.success',
          failure: 'received.data.toms.singleEntry.failure',
        }
      },
      settings: {
        changePassword: {
          success: 'received.data.settings.changePassword.success',
          failure: 'received.data.settings.changePassword.failure'
        },
        emailNotification: {
          success: 'received.data.settings.emailNotification.success',
          failure: 'received.data.settings.emailNotification.failure'
        },
        profilePicture: {
          success: 'received.data.settings.profilePicture.success',
          failure: 'received.data.settings.profilePicture.failure'
        }
      },
      supervision: {
        clients: {
          all: {
            success: 'received.data.supervision.clients.all.success',
            failure: 'received.data.supervision.clients.all.failure',
          },
          accepted: {
            success: 'received.data.supervision.clients.accepted.success',
            failure: 'received.data.supervision.clients.accepted.failure',
          },
          pending: {
            success: 'received.data.supervision.clients.pending.success',
            failure: 'received.data.supervision.clients.pending.failure',
          },
          single: {
            success: 'received.data.supervision.clients.single.success',
            failure: 'received.data.supervision.clients.single.failure',
          }
        },
        clientInvitation: {
          supervisor: {
            success: 'received.data.supervision.clientInvitation.supervisor.success',
            failure: 'received.data.supervision.clientInvitation.supervisor.failure'
          },
          request: {
            success: 'received.data.supervision.clientInvitation.request.success',
            failure: 'received.data.supervision.clientInvitation.request.failure'
          },
          info: {
            success: 'received.data.supervision.clientInvitation.info.success',
            failure: 'received.data.supervision.clientInvitation.info.failure'
          }
        },
        clientToken: {
          single: {
            success: 'received.data.supervision.clientToken.single.success',
            failure: 'received.data.supervision.clientToken.single.failure',
          }
        },
        templates: {
          all: {
            success: 'received.data.supervision.templates.all.success',
            failure: 'received.data.supervision.templates.all.failure',
          },
          get: {
            success: 'received.data.supervision.templates.get.success',
            failure: 'received.data.supervision.templates.get.failure',
          },
        },
        supervisor: {
          profile: {
            success: 'received.data.supervision.supervisor.profile.success',
            failure: 'received.data.supervision.supervisor.profile.failure'
          }
        }
      },
      documents: {
        all: {
          success: 'received.data.documents.all.success',
          failure: 'received.data.documents.all.failure',
        },
        history: {
          success: 'received.data.documents.history.success',
          failure: 'received.data.documents.history.failure',
        }
      },
      dsar: {
        all: {
          success: 'received.data.dsar.all.success',
          failure: 'received.data.dsar.all.failure',
        },
        single: {
          success: 'received.data.dsar.single.success',
          failure: 'received.data.dsar.single.failure',
        }
      },
      incidents: {
        all: {
          success: 'received.data.incidents.all.success',
          failure: 'received.data.incidents.all.failure',
        },
        single: {
          success: 'received.data.incidents.single.success',
          failure: 'received.data.incidents.single.failure',
        }
      },
      records: {
        all: {
          success: 'received.data.records.all.success',
          failure: 'received.data.records.all.failure',
        },
        single: {
          success: 'received.data.records.single.success',
          failure: 'received.data.records.single.failure',
        }
      },
      actions: {
        all: {
          success: 'received.data.actions.all.success',
          failure: 'received.data.actions.all.failure',
        },
        ranged: {
          success: 'received.data.actions.ranged.success',
          failure: 'received.data.actions.ranged.failure',
        },
        single: {
          success: 'received.data.actions.single.success',
          failure: 'received.data.actions.single.failure',
        },
        forRecords: {
          success: 'received.data.actions.forRecords.success',
          failure: 'received.data.actions.forRecords.failure',
        }
      },
    },
    payment: {
      subscribe: {
        success: 'received.payment.subscribe.success',
        failure: 'received.payment.subscribe.failure'
      },
      plans: {
        success: 'received.payment.plans.success',
        failure: 'received.payment.plans.failure'
      },
      coupon: {
        success: 'received.payment.coupon.success',
        failure: 'received.payment.coupon.failure'
      },
      info: {
        latest: {
          success: 'received.payment.info.latest.success',
          failure: 'received.payment.info.latest.failure'
        },
        all: {
          success: 'received.payment.info.all.success',
          failure: 'received.payment.info.all.failure'
        },
        subscriptionDetails: {
          success: 'received.payment.info.subscriptionDetails.success',
          failure: 'received.payment.info.subscriptionDetails.failure'
        }
      },
      upgrade: {
        preview: {
          success: 'received.payment.upgrade.preview.success',
          failure: 'received.payment.upgrade.preview.failure'
        },
        upgrade: {
          success: 'received.payment.upgrade.upgrade.success',
          failure: 'received.payment.upgrade.upgrade.failure'
        }
      },
      saasMantra: {
        coupon: {
          success: 'received.payment.saasMantra.coupon.success',
          failure: 'received.payment.saasMantra.coupon.failure',
        }
      },
      details: {
        updateCard: {
          success: 'received.payment.details.updateCard.success',
          failure: 'received.payment.details.updateCard.failure'
        }
      }
    }
  };
  public requested = {
    data: {
      badge: {
        get: 'requested.data.badge.get',
        info: 'requested.data.badge.info',
      },
      comments: {
        get: 'requested.data.comments.get',
        post: 'requested.data.comments.post',
      },
      user: {
        info: 'requested.data.user.info',
        access: 'requested.data.user.access',
      },
      authentication: 'requested.data.authentication',
      emailAuthentication: 'requested.data.emailAuthentication',
      registration: 'requested.data.registration',
      report: 'requested.data.report',
      basicInfo: {
        get: 'requested.data.basicInfo.get',
        update: 'requested.data.basicInfo.update'
      },
      controller: {
        get: 'requested.data.controller.get',
        update: 'requested.data.controller.update',
        name: 'requested.data.controller.name',
        updateName: 'requested.data.controller.updateName',
        hasLogo: 'requested.data.controller.hasLogo',
        hasGoalsDoc: 'requested.data.controller.hasGoalsDoc',
        users: 'requested.data.controller.users',
        invites: 'requrested.data.controller.invites',
        decodeInviteToken: 'requested.data.controller.invites.decode',
      },
      dpo: {
        get: 'requested.data.dpo.get',
        put: 'requested.data.dpo.put',
        hasAppointment: 'requested.data.dpo.hasAppointment',
      },
      rpa: {
        get: 'requested.data.rpa.get',
        getAll: 'requested.data.rpa.getAll',
        getApplicable: 'requested.data.rpa.getApplicable',
        getComplex: 'requested.data.rpa.getComplex',
        update: 'requested.data.rpa.update',
        updateStatus: 'requested.data.rpa.updateStatus',
        customPa: {
          get: 'requested.data.rpa.customPa.get',
          add: 'requested.data.rpa.customPa.add',
          update: 'requested.data.rpa.customPa.update',
          delete: 'requested.data.rpa.customPa.delete',
        },
        basics: {
          updateField: 'requested.data.rpa.basics.updateField',
          progress: 'requested.data.rpa.basics.progress',
        },
        internallyProcessed: {
          pas: 'requested.data.rpa.internallyProcessed.pas',
          associations: 'requested.data.rpa.internallyProcessed.associations',
          associate: 'requested.data.rpa.internallyProcessed.associate',
          disassociate: 'requested.data.rpa.internallyProcessed.disassociate',
        },
        externallyProcessed: {
          pas: 'requested.data.rpa.externallyProcessed.pas',
          vendors: 'requested.data.rpa.externallyProcessed.vendors',
          associate: 'requested.data.rpa.externallyProcessed.associate',
          disassociate: 'requested.data.rpa.externallyProcessed.disassociate',
        },
      },
      percentage: 'requested.data.percentage',
      messageBroadcast: 'requested.data.messageBroadcast',
      todos: {
        workflows: 'requested.data.todos.workflows',
        milestoneOneProgress: 'requested.data.todos.milestoneOneProgress',
        milestoneTwoProgress: 'requested.data.todos.milestoneTwoProgress',
        controllerTaskProgress: 'requested.data.todos.controllerTaskProgress',
      },
      department: {
        single: 'requested.data.department.single',
        add: 'requested.data.department.add',
        update: 'requested.data.department.update',
        delete: 'requested.data.department.delete',
      },
      departments: {
        get: 'requested.data.departments.get'
      },
      vendors: {
        get: 'requested.data.vendors.get',
        add: 'requested.data.vendors.add',
        controllerVendors: 'requested.data.vendors.controllerVendors',
        decodeDPAToken: 'requested.data.vendors.decodeDPAToken',
      },
      toms: {
        category: 'requested.data.toms.category',
        categories: 'requested.data.toms.categories',
        categoryEntries: 'requested.data.toms.categoryEntries',
        singleEntry: 'requested.data.toms.singleEntry',
      },
      settings: {
        changePassword: 'requested.data.settings.changePassword',
        emailNotification: 'requested.data.settings.emailNotification',
        profilePicture: 'requested.data.settings.profilePicture'
      },
      supervision: {
        clients: {
          all: 'requested.data.supervision.clients.all',
          accepted: 'requested.data.supervision.clients.accepted',
          pending: 'requested.data.supervision.clients.pending',
          single: 'requested.data.supervision.clients.single'
        },
        clientInvitation: {
          supervisor: 'requested.data.supervision.clientInvitation.supervisor',
          request: 'requested.data.supervision.clientInvitation.request',
          info: 'requested.data.supervision.clientInvitation.info'
        },
        clientToken: {
          single: 'requested.data.supervision.clientToken.getSingle'
        },
        templates: {
          get: 'requested.data.supervision.templates.get',
          all: 'requested.data.supervision.templates.all',
        },
        supervisor: {
          profile: 'requested.data.supervision.supervisor.profile'
        }
      },
      documents: {
        all: 'requested.data.documents.all',
        history: 'requested.data.documents.history'
      },
      dsar: {
        all: 'requested.data.dsar.all',
        single: 'requested.data.dsar.single'
      },
      incidents: {
        all: 'requested.data.incidents.all',
        single: 'requested.data.incidents.single'
      },
      records: {
        all: 'requested.data.records.all',
        single: 'requested.data.records.single'
      },
      actions: {
        all: 'requested.data.actions.all',
        ranged: 'requested.data.actions.ranged',
        single: 'requested.data.actions.single',
        forRecords: 'requested.data.actions.forRecords'
      }
    },
    action: {
      resetPasswordEmail: 'requested.action.resetPasswordEmail',
      resendVerificationEmail: 'requested.action.resendVerificationEmail',
      verification: 'requested.action.verification',
      checkEmail: 'requested.action.checkEmail',
      tasks: {
        progressControllerTask: 'requested.action.tasks.progressControllerTask',
      },
      rpa: {
        assign: 'requested.action.rpa.assign',
      },
      rpaOnBehalf: {
        workflow: {
          add: 'requested.action.rpaOnBehalf.workflow.add',
          delete: 'requested.action.rpaOnBehalf.workflow.delete',
        }
      },
      vendors: {
        contactForDPA: 'requested.action.vendors.contactForDPA',
        toggleDPARequired: 'requested.action.vendors.toggleDPARequired',
      },
      toms: {
        updateEntry: 'requested.action.toms.updateEntry',
        markCategoryAsDone: 'requested.action.toms.markCategoryAsDone',
      },
      controller: {
        users: {
          update: 'requested.action.controller.users.update',
          invite: 'requested.action.controller.users.invite',
          deleteInvite: 'requested.action.controller.users.deleteInvite',
          resendInvite: 'requested.action.controller.users.resendInvite',
          confirmInvite: 'requested.action.controller.users.confirmInvite',
        }
      },
      supervision: {
        clients: {
          add: {
            invite: 'requested.action.supervision.clients.add.invite',
            controller: 'requested.action.supervision.clients.add.controller'
          },
          delete: 'requested.action.supervision.clients.delete'
        },
        clientInvitation: {
          accept: 'requested.action.supervision.clientInvitation.accept',
          resend: 'requested.action.supervision.clientInvitation.resend'
        },
        templates: {
          create: 'requested.action.supervision.templates.create',
          delete: 'requested.action.supervision.templates.delete',
          update: 'requested.action.supervision.templates.update',
          apply: 'requested.action.supervision.templates.apply',
        },
        supervisor: {
          profile: {
            update: 'requested.action.supervision.supervisor.profile.update'
          }
        }
      },
      documents: {
        update: 'requested.action.documents.update',
        delete: 'requested.action.documents.delete'
      },
      dsar: {
        update: 'requested.action.dsar.update',
        create: 'requested.action.dsar.create',
        delete: 'requested.action.dsar.delete'
      },
      incidents: {
        update: 'requested.action.incidents.update',
        create: 'requested.action.incidents.create',
        delete: 'requested.action.incidents.delete'
      },
      records: {
        update: 'requested.action.records.update',
        create: 'requested.action.records.create',
        delete: 'requested.action.records.delete'
      }
    },
    payment: {
      subscribe: 'requested.payment.subscribe',
      plans: 'requested.payment.plans',
      coupon: 'requested.payment.coupon',
      info: {
        latest: 'requested.payment.info.latest',
        all: 'requested.payment.info.all',
        subscriptionDetails: 'requested.payment.info.subscriptionDetails'
      },
      upgrade: {
        preview: 'requested.payment.upgrade.preview',
        upgrade: 'requested.payment.upgrade.upgrade'
      },
      saasMantra: {
        coupon: 'requested.payment.saasMantra.coupon',
      },
      details: {
        updateCard: 'requested.payment.details.updateCard'
      }
    }
  };
  public ui = {
    requested: {
      sidenav: {
        expand: 'ui.requested.sidenav.expand',
        collapse: 'ui.requested.sidenav.collapse',
      }
    },
    occured: {
      sidenav: {
        expand: 'ui.occured.sidenav.expand',
        collapse: 'ui.occured.sidenav.collapse',
      }
    }
  };
}
