import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {BusService} from '../../../services/bus.service';
import {EventsService} from '../../../services/events.service';

@Component({
  selector: 'email-login-component',
  templateUrl: './email.login.template.html',
  styleUrls: ['./email.login.styles.scss']
})
export class EmailLoginComponent implements OnInit, OnDestroy {
  private next: string = null;
  private token: string;

  state: string = 'initial';

  constructor(
    private activatedRoute: ActivatedRoute,
    private bus: BusService,
    private events: EventsService,
  ) {
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.received.authentication.failure, this.failure, this);
  }

  private unsubscribe(): void {
    this.bus.unsubscribe(this.events.received.authentication.failure, this.failure);
  }

  public failure(): void {
    this.state = 'failed';
  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.next = params['next'];
      this.token = params['token'];
      setTimeout(() => {
        this.bus.publish(this.events.requested.data.emailAuthentication, {
          token: this.token,
          next: this.next,
        });
      }, 1000);
    });

    this.subscribe();
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }
}
