import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

import { ErrorStateMatcher } from '@angular/material/core';
import { MatInput } from '@angular/material/input';

import { TomsService, TomsCategory, TomsEntryAndData } from '../../../services/toms/toms.service';


class ExpErrorMatcher extends ErrorStateMatcher {
  constructor(
    private entry: TomsEntryAndData,
    private focused: (TomsEntryAndData) => boolean) {
    super();
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.entry.applicable
      && this.entry.requiresExplanation
      && !this.entry.explanation
      && !this.focused(this.entry);
  }
}

@Component({
  selector: 'app-toms-category',
  templateUrl: './toms-category.component.html',
  styleUrls: ['./toms-category.component.scss']
})
export class TomsCategoryComponent implements OnInit {

  category: TomsCategory;
  entries: { [tag: string]: TomsEntryAndData[] };

  @ViewChildren(MatInput) expFields: QueryList<MatInput>;

  openedTomsExpId: number;
  focusedTomsExpId: number;

  updateExpTimeouts: { [id: number]: any } = {};


  constructor(
    private toms: TomsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('catId')) {
        const catId = parseInt(params.get('catId'));

        this.toms.category(catId).subscribe(response => {
          this.category = response;
        });

        this.toms.categoryEntries(catId).subscribe(response => {
          this.entries = {};
          response.forEach(entry => {
            if (!this.entries[entry.tag]) {
              this.entries[entry.tag] = [];
            }
            this.entries[entry.tag].push(entry);
          });
        });
      }
    });
  }

  ngOnInit() {
  }

  public get canWrite() {
    return true;
  }

  toggleTomsEntryExp(entry) {
    if (!this.canWrite) {
      return;
    }

    if (this.openedTomsExpId === entry.id) this.openedTomsExpId = -1;
    else {
      this.openedTomsExpId = entry.id;
      this.updateApplicability(entry, true);
      this.focusEntryExpField(entry);
    }
  }

  updateApplicability(entry, value) {
    if (!this.canWrite) {
      return;
    }

    if (entry.applicable !== value) {
      entry.applicable = value;
      this.toms.updateEntry(entry);

      if (entry.requiresExplanation && entry.applicable) {
        this.focusEntryExpField(entry);
      }
    }
  }

  updateExplanation(entry, value) {
    if (!this.canWrite) {
      return;
    }

    if (entry.explanation !== value) {
      entry.explanation = value;

      if (entry.id in this.updateExpTimeouts) {
        clearTimeout(this.updateExpTimeouts[entry.id]);
      }
      this.updateExpTimeouts[entry.id] = setTimeout(() => {
        this.toms.updateEntry(entry);
      }, 500);
    }
  }

  focusEntryExpField(entry) {
    this.expFields.find(input => input.id === `entry-exp-${entry.id}`).focus();
  }

  expErrorMatcher(entry) {
    return new ExpErrorMatcher(entry, (entry: TomsEntryAndData) => (entry.id === this.focusedTomsExpId && this.canWrite));
  }

  get valid(): boolean {
    if (!this.entries) {
      return;
    }

    for (const [tag, entries] of Object.entries(this.entries)) {
      if (entries.some(entry => entry.applicable && entry.requiresExplanation && !entry.explanation)) {
        return false;
      }
    }

    return true;
  }

  done() {
    if (this.valid && this.canWrite) {
      this.toms.markCategoryAsDone(this.category.id)
        .subscribe(() => {
          this.router.navigate(['/toms']);
        });
    }
  }

  public get tags(): string[] {
    if (this.entries) {
      return Object.keys(this.entries);
    } else {
      return [];
    }
  }
}
