import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { BusService } from '../../../../services/bus.service';
import { EventsService } from '../../../../services/events.service';
import { DocumentResourceService } from '../../../../services/documents/document-resource.service';


@Component({
  selector: 'app-document-taglist-dialog',
  templateUrl: './document-taglist-dialog.component.html',
  styleUrls: ['./document-taglist-dialog.component.scss']
})
export class DocumentTaglistDialogComponent implements OnInit, OnDestroy {

  document: any;

  constructor(
    private dialogRef: MatDialogRef<DocumentTaglistDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private docService: DocumentResourceService
  ) {
    this.document = data.document;
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }
}
