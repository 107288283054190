import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';

import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

import { Training, TrainingService } from 'app/services/training/training.service';
import { Moment } from 'moment';
import * as moment from 'moment';


export interface TrainingDialogOptions {
  training: Training;
}


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-training-dialog',
  templateUrl: './training-dialog.component.html',
  styleUrls: ['./training-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [LOCALE_ID] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class TrainingDialogComponent implements OnInit {
  training: Training;
  startingTime: Moment;
  endingTime: Moment;
  startingTimeStr = '13:00';
  endingTimeStr = '14:00';

  constructor(
    @Inject(MAT_DIALOG_DATA) data: TrainingDialogOptions,
    private ref: MatDialogRef<TrainingDialogComponent>,
    private service: TrainingService,
  ) {
    this.training = {
      title: '',
      description: '',
    };

    if (data) {
      if (data.training) {
        this.training = Object.assign({}, data.training);
        this.startingTime = moment(this.training.startingTime);
        this.endingTime = moment(this.training.endingTime);

        this.startingTimeStr = this.startingTime.format('HH:mm');
        this.endingTimeStr = this.endingTime.format('HH:mm');
      }
    }
  }

  ngOnInit() {
  }

  save() {
    if (this.startingTime) {
      this.training.startingTime = this.startingTime.toDate();
      if (this.startingTimeStr) {
        const [hour, minute] = this.startingTimeStr.split(':');
        this.training.startingTime.setHours(parseInt(hour, 10));
        this.training.startingTime.setMinutes(parseInt(minute, 10));
      }
    }

    if (this.endingTime) {
      this.training.endingTime = this.endingTime.toDate();
      if (this.endingTimeStr) {
        const [hour, minute] = this.endingTimeStr.split(':');
        this.training.endingTime.setHours(parseInt(hour, 10));
        this.training.endingTime.setMinutes(parseInt(minute, 10));
      }
    }

    this.service.save(this.training).subscribe(res => {
      this.ref.close({
        saved: res
      });
    });
  }
}
