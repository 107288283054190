import { Component, AfterViewInit, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UserManagementService } from '../../../services/user/user-management.service';
import { Subject, NewComment, CommentService, CommentTypes } from '../../../services/comment/comment.service';


@Component({
  selector: 'app-comment-box-dialog',
  templateUrl: './comment-box-dialog.component.html',
  styleUrls: ['./comment-box-dialog.component.scss']
})
export class CommentBoxDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('content', { static: false }) content: ElementRef;

  commentTypes = CommentTypes;

  users: { id: number; name: string }[] = [];
  comment: NewComment;

  public mentionConfig = {
    maxItems: 3,
    labelKey: 'name',
    limit: undefined,
    allowSpace: true,
    mentionFilter: (searchString: string, items: { name: string }[]) => {
      return items.filter((u) => u.name ? u.name.toLowerCase().includes(searchString.toLowerCase()) : false);
    }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public subject: Subject,
    private dialogRef: MatDialogRef<CommentBoxDialogComponent>,
    private userService: UserManagementService,
    private comments: CommentService,
  ) {
    this.comment = {
      subject: subject,
      text: '',
      type: CommentTypes.text,
    };

    this.fetchDraft();
    this.dialogRef.afterClosed().subscribe(() => {
      if (this.comment.text) {
        this.storeDraft();
      } else {
        this.clearDraft();
      }
    });
  }

  ngOnInit() {
    this.userService.users(true).subscribe(response => {
      this.users = (!!this.mentionConfig.limit
        ? response.filter(user => this.mentionConfig.limit.includes(user.id))
        : response)
      .map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName} (${user.directoryId})`
      }));
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.content.nativeElement.scrollTo({
        top: this.content.nativeElement.scrollHeight,
        behavior: 'smooth'
      });
    }, 380);
  }

  send() {
    this.comment.mentions = [];
    this.users.forEach(user => {
      if (this.comment.text.indexOf(`@${user.name}`) !== -1 && !this.comment.mentions.includes(user)) {
        this.comment.mentions.push(user);
      }
    });

    this.clearDraft();
    this.comments.post(this.comment)
      .subscribe(() => this.updateSubject());
  }

  closeConversation() {
    this.comments.post({
      subject: this.subject,
      type: CommentTypes.close,
    }).subscribe(() => this.dialogRef.close());
  }

  updateSubject() {
    this.comments.get(this.subject.codename)
      .subscribe(subject => {
        if (subject) {
          this.subject = Object.assign(this.subject, subject);
          this.comment = {
            subject: subject,
            text: '',
            type: CommentTypes.text,
          };
        }
      });
  }

  authorName(comment) {
    const candidates = this.users.filter(user => user.id === comment.authorId);
    if (candidates.length > 0) {
      return candidates[0].name;
    }
    return '---';
  }

  formatDate(dateString) {
    try {
      return new Date(dateString).toLocaleString();
    } catch (err) {
      // Old Browser,
      return new Date(dateString).toString();
    }
  }

  private get _lskey() { return this.subject.codename + '-draft'; }

  storeDraft() {
    localStorage.setItem(this._lskey, this.comment.text);
  }

  fetchDraft() {
    if (this.comment) {
      this.comment.text = localStorage.getItem(this._lskey) || '';
    }
  }

  clearDraft() {
    localStorage.removeItem(this._lskey);
  }
}
