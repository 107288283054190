import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { BusService } from '../../../../services/bus.service';
import { EventsService } from '../../../../services/events.service';

import { InvitationResultDialogComponent } from 'app/components/user-management/invitation-result-dialog/invitation-result-dialog.component';
import { Router } from '../../../../../../node_modules/@angular/router';
import { ClientManagementService } from '../../../../services/supervision/client-management.service';


@Component({
  selector: 'app-add-client-email',
  templateUrl: './add-client-email.component.html',
  styleUrls: ['./add-client-email.component.scss']
})
export class AddClientEmailComponent implements OnInit, OnDestroy {

  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddClientEmailComponent>,
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private clientService: ClientManagementService
  ) {

  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  invite() {
    this.email.markAsTouched();
    if (this.email.valid) {
      this.bus.publish(this.events.requested.action.supervision.clients.add.invite, {
        email: this.email.value,
      });
    }
  }

  invited() {
    this.dialogRef.close(true);
  }

  inviteFailed(error) {
    this.dialog.open(InvitationResultDialogComponent, {
      width: '350px',
      data: {
        clientError: error.error,
        type: error.type,
        alreadyInvited: error.alreadyInvited
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.supervision.clients.add.invite.success, this.invited, this);
    this.bus.subscribe(this.events.received.action.supervision.clients.add.invite.failure, this.inviteFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.supervision.clients.add.invite.success, this.invited);
    this.bus.unsubscribe(this.events.received.action.supervision.clients.add.invite.failure, this.inviteFailed);
  }
}
