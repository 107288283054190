import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { AuthenticationService } from '../../../services/authentication.service';


@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent implements OnInit {

  user: any;
  departments = [];

  constructor(
    private bus: BusService,
    private events: EventsService,
    public auth: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.user = data.user;
    this.departments = data.departments;
  }

  depSelect() {
    if (this.auth.email !== this.user.email && this.user.departmentId) {
      this.user.access = 'users.access.readDep-writeDep';
    }
  }

  updateUser() {
    this.bus.publish(this.events.requested.action.controller.users.update, this.user);
  }

  ngOnInit() {}
}
