import { Injectable } from "@angular/core";
import { HttpRequest, HttpClient, HttpEventType, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap, last } from 'rxjs/operators';
import { Observable, from, throwError } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

import { AppConfig } from "../app.config";


@Injectable()
export class UploadService {
  private apiUrl: string = AppConfig.apiUrl;

  constructor(
    private http: HttpClient,
  ) {}

  public upload(url: string,
                key: string,
                file: File,
                //relativePath: string,
                progressHandler?: (loaded:number, total:number)=>any
              ): Observable<HttpEvent<{}>>  {
    let formData = new FormData();
    formData.append(key, file);

    let req = new HttpRequest('POST', this.apiUrl + url, formData, {
      reportProgress: true,
    });

    return this.http.request<any>(req).pipe(
      tap(event => this.handleEvent(event, progressHandler)),
      last(),
      catchError(this.handleError),
    ) as Observable<HttpEvent<{}>>;
  }

  public uploadMultiple(url: string, key: string, files: Array<File>,
    progressHandler?: (loaded: number, total: number) => any): Observable<HttpEvent<{}>>  {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append(key, file);
    }

    const req = new HttpRequest('POST', this.apiUrl + url, formData, {
      reportProgress: true,
    });

    return this.http.request<any>(req).pipe(
      tap(event => this.handleEvent(event, progressHandler)),
      last(),
      catchError(this.handleError),
    ) as Observable<HttpEvent<{}>>;
  }

  private handleEvent(event: HttpEvent<any>,
                    progressHandler?:(loaded:number, total:number)=>any
                  ) {
    switch(event.type) {
      case HttpEventType.UploadProgress:
        if (progressHandler) progressHandler(event.loaded, event.total);
        return;
      default:
        return;
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };
}
