import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

import { CopiedDialogComponent } from './copied-dialog/copied-dialog.component';


@Component({
  selector: 'app-get-badge',
  templateUrl: './get-badge.component.html',
  styleUrls: ['./get-badge.component.scss']
})
export class GetBadgeComponent implements OnInit, OnDestroy {

  imageUrl: string;
  infoUrl: string;
  error: any;

  constructor(
    private dialog: MatDialog,
    private bus: BusService,
    private events: EventsService,
  ) {
    this.bus.publish(this.events.requested.data.badge.get);
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  got(badge) {
    this.imageUrl = badge.imageUrl;
    this.infoUrl = badge.infoUrl;
  }

  didntGet(response) {
    this.error = response.error;
  }

  get embedCode() {
    return `<a href="${this.infoUrl}" target="_blank"><img style="width:80px!important;height:80px!important" src="${this.imageUrl}"/></a>`;
  }

  copied() {
    this.dialog.open(CopiedDialogComponent, {
      width: '360px',
      panelClass: 'copied-dialog',
    })
  }

  copy(element) {
    if ((document as any).selection) {
      let range = (document.body as any).createTextRange();
      range.moveToElementText(element);
      range.select().createTextRange();
      document.execCommand("copy");
      (document as any).selection.empty();
      this.copied();
    } else if (window.getSelection) {
      let range = document.createRange();
      range.selectNode(element);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      this.copied();
    }
  }

  cleanContent(element) {
    return element.innerHTML.replace(/_ng.*\=\".*\"/g, "").trim();
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.badge.get.success, this.got, this);
    this.bus.subscribe(this.events.received.data.badge.get.failure, this.didntGet, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.badge.get.success, this.got);
    this.bus.unsubscribe(this.events.received.data.badge.get.failure, this.didntGet);
  }
}
