import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestService } from '../request.service';
import { DirectoryUser } from '../directory/directory-user';


export type TodoType = 'todos.type.generic' | 'todos.type.yesno';

export interface Todo {
  id?: number;

  title: string;
  description?: string;
  link?: string;
  subject?: string;
  assignee?: DirectoryUser;

  done?: boolean;
  dueDate?: Date | '';
  type?: TodoType;
  response?: any;

  createdBy?: number;
  createdAt?: Date;
  lastChange?: Date;
  parent?: number;
  parentTodo?: {
    id: number;
    title: string;
  };

  issuer?: DirectoryUser;
  issuerId?: string;
  assigneeId?: string;
}


export interface TodoSearchCriteria {
  done?: boolean;
  limit?: number;
  order?: 'dueDate' | 'lastChange' | 'chronological';
  keyword?: string;
  subject?: string;
  parent?: number;
}


@Injectable()
export class TodosService {
  api = {
    root: '/todos',
    assigned: '/assigned',
    participating: '/participating',
    all: '/all',
  };

  constructor(private request: RequestService) { }

  insert(todo: Todo) {
    return this.request.post<Todo, any>({
      uri: this.api.root,
      body: todo,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  paramsFromCriteria(criteria?: TodoSearchCriteria): HttpParams {
    let params = new HttpParams();

    if (criteria) {
      if (criteria.done) {
        params = params.set('done', '1');
      }

      if (criteria.limit) {
        params = params.set('limit', criteria.limit.toString());
      }

      if (criteria.order) {
        params = params.set('order', criteria.order);
      }

      if (criteria.keyword) {
        params = params.set('keyword', criteria.keyword);
      }

      if (criteria.subject) {
        params = params.set('subject', criteria.subject);
      }

      if (criteria.parent) {
        params = params.set('parent', criteria.parent.toString());
      }
    }

    return params;
  }

  get(criteria?: TodoSearchCriteria) {
    return this.request.get<Todo[]>({
      uri: this.api.root,
      parameters: this.paramsFromCriteria(criteria),
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  getAssigned(criteria?: TodoSearchCriteria) {
    return this.request.get<Todo[]>({
      uri: this.api.root + this.api.assigned,
      parameters: this.paramsFromCriteria(criteria),
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  getParticipating(criteria?: TodoSearchCriteria) {
    return this.request.get<Todo[]>({
      uri: this.api.root + this.api.participating,
      parameters: this.paramsFromCriteria(criteria),
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  getAll(criteria?: TodoSearchCriteria) {
    return this.request.get<Todo[]>({
      uri: this.api.root + this.api.all,
      parameters: this.paramsFromCriteria(criteria),
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  polishReceivedTodos(...todos: Todo[]): Todo[] {
    todos.forEach(todo => {
      if (todo.dueDate) {
        todo.dueDate = new Date(todo.dueDate);
      }

      if (todo.lastChange) {
        todo.lastChange = new Date(todo.lastChange);
      }

      if (todo.createdAt) {
        todo.createdAt = new Date(todo.createdAt);
      }
    });

    return todos;
  }

  // the server responds with an Observable. But what we need is polished list of todos.
  polish = (src$: Observable<Todo | Todo[]>) => src$.pipe(map(received => {
    if (Array.isArray(received)) {
      return this.polishReceivedTodos(...received);
    } else {
      return this.polishReceivedTodos(received as any);
    }
  }));

  single(id) {
    return this.request.get<Todo>({
      uri: this.api.root + `/${id}`,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  update(todo: Todo) {
    // (todo as any).assigneeId = -1;

    return this.request.put<Todo, any>({
      uri: this.api.root + `/${todo.id}`,
      body: todo,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  done(todo: Todo) {
    todo.done = true;
    return this.request.put<Todo, any>({
      uri: this.api.root + `/${todo.id}`,
      body: todo,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  respond(todo: Todo, response: any) {
    todo.done = true;
    todo.response = response;

    return this.request.put<Todo, any>({
      uri: this.api.root + `/${todo.id}`,
      body: todo,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }

  reopen(todo: Todo) {
    todo.done = false;
    return this.request.put<Todo, any>({
      uri: this.api.root + `/${todo.id}`,
      body: todo,
      handlers: {
        success: () => { },
        error: () => { },
      }
    });
  }
}
