import { Component, OnInit, ViewChild, ElementRef, ContentChildren, QueryList, AfterViewInit, Input } from '@angular/core';

import { StepComponent } from './step/step.component';


@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, AfterViewInit {

  @Input() height: string = "85vh";
  @Input() navigation : boolean = true;
  @ViewChild('holder', { static: false }) holder : ElementRef;
  @ContentChildren(StepComponent) steps : QueryList<StepComponent>;

  private _current : StepComponent;
  private _idMap : any = {};
  private _firstIndex : number = 0;
  private _lastInedx : number = 0;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.holder.nativeElement.scrollLeft = 0;
    setTimeout(() => this.holder.nativeElement.scrollLeft = 0, 10);
    setTimeout(() => {
      let candidate;
      this.steps.forEach((step, index) => {
        if (index < this._firstIndex) this._firstIndex = index;
        if (index > this._lastInedx) this._lastInedx = index;

        this._idMap[index] = step;
        step.index = index;
        if (step.id) this._idMap[step.id] = step;

        if (step.initial && !candidate) candidate = step;
      });

      if (!candidate && this.steps.length > 0)
        candidate = this.steps.first;

      this.current = candidate;
      this.holder.nativeElement.scrollLeft = 0;
    }, 500);
  }

  public get current() { return this._current; }

  public set current(step) {
    if (this._current) {
      if (this._current.index < step.index) this._current.pushBack();
      if (this._current.index > step.index) this._current.pushForward();
    }

    this._current = step;
    this._current.activate();

    this.fixPositions();
  }

  public get onFirst() : boolean {
    return this.current && this.current.index == this._firstIndex;
  }

  public get onLast() : boolean {
    return this.current && this.current.index == this._lastInedx;
  }

  public switch(id) {
    if (id in this._idMap)
      this.current = this._idMap[id];
  }

  public next() {
    this.switch(this.current.index + 1);
  }

  public prev() {
    this.switch(this.current.index - 1);
  }

  fixPositions() {
    let current = this.current;
    Object.values<any>(this._idMap).forEach(step => {
      if (step.index < current.index) step.pushBack();
      if (step.index > current.index) step.pushForward();
    });
  }
}
