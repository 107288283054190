
import { Component, OnInit, OnDestroy, ViewChild, Input, ElementRef, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { ActionsService } from 'app/services/actions/actions.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { MatPaginator, MatDialog } from '@angular/material';
import { state, trigger, style, transition, animate } from '@angular/animations';
import { Event, FilterRequest, getEntityIdentifier, AvailableEvents, matchName } from './event';
import { BehaviorSubject } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS } from 'saturn-datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActionDetailsDialogComponent } from './details/details-dialog.component';
import { DirectoryUser } from 'app/services/directory/directory-user';

const TRACKING_START_DATE = '2018-12-15';

const METADATA_EVENTS = [
  { category: 'department', fullName: 'CONTROLLER.DEPARTMENT.ADD' },
  { category: 'department', fullName: 'CONTROLLER.DEPARTMENT.UPDATE' },
  { category: 'document', fullName: 'CONTROLLER.DOCUMENT.UPLOADED' },
  { category: 'document', fullName: 'CONTROLLER.DOCUMENT.REPLACE_FILE' },
  { category: 'document', fullName: 'CONTROLLER.DOCUMENT.UPDATE_DETAILS' },
  { category: 'users', fullName: 'CONTROLLER.USERS.CONFIRMED_INVITE' },
  { category: 'users', fullName: 'CONTROLLER.USERS.RESENT_INVITE' },
  { category: 'users', fullName: 'CONTROLLER.USERS.INVITED_USER' },
  { category: 'rpa', fullName: 'CONTROLLER.RPA.ASSIGN' },
  { category: 'rpa', fullName: 'CONTROLLER.RPA.UPDATE_STATUS' },
  { category: 'rpa', fullName: 'CONTROLLER.RPA.UPDATE_FIELD' },
  { category: 'rpa', fullName: 'CONTROLLER.RPA.CUSTOM.ADD' },
  { category: 'rpa', fullName: 'CONTROLLER.RPA.CUSTOM.UPDATE' },
  { category: 'rpa-processors', fullName: 'CONTROLLER.RPA.IP.ADD' },
  { category: 'rpa-processors', fullName: 'CONTROLLER.RPA.IP.DELETE' },
  { category: 'rpa-processors', fullName: 'CONTROLLER.RPA.EP.ADD' },
  { category: 'rpa-processors', fullName: 'CONTROLLER.RPA.EP.DELETE' },
  { category: 'vendors', fullName: 'CONTROLLER.VENDORS.ADD_DPA' },
  { category: 'vendors', fullName: 'CONTROLLER.VENDORS.REQUEST_DPA' },
  { category: 'vendors', fullName: 'CONTROLLER.VENDORS.DPA_TOGGLE_REQUIRED' },
  { category: 'toms', fullName: 'CONTROLLER.TOMS.ENTRY.SAVE' },
  { category: 'toms', fullName: 'CONTROLLER.TOMS.ENTRY.APPLICABLE_UPDATE' },
  { category: 'toms', fullName: 'CONTROLLER.TOMS.ENTRY.EXPLANATION_UPDATE' },
];

interface GroupedItems<T> {
  groupName: string;
  items: Array<T>;
}


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-actions-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [LOCALE_ID] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ActionsListComponent implements OnInit, OnDestroy {
  // How many items should get loaded on first hit and afterwards
  ItemsLimit = 50;

  // This component by default shows all events (globally). But it can be narrowed down to only show the
  // events pertaining to a single record.
  @Input() recordId: number;
  @Input() title: string;

  filters = { category: null, action: null, item: null, user: null, userItem: null, dateRange: null, keyword: '' };
  @Input() withFilter: FilterRequest = null;

  allEvents: Array<Event>;
  filteredEvents: Array<Event>;
  // events: Array<Event>;
  users = [];
  
  lastItem: Event;
  lastTimestamp: Date;
  firstTimestamp: Date;
  lastPosition = 0;

  // users are also able to show a date range
  isRangeSelection = false;
  startingDate: Date;
  endDate: Date;

  canLoadMore = false;
  isLoading = false;

  isFirstLoad = true;

  collapse = false;


  @ViewChild('itemsContainer', { static: false }) itemsContainer: ElementRef;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  grouped = new BehaviorSubject<Array<GroupedItems<Event>>>([]);

  state = 'showAll';

  maxDate = new Date();


  categories = [];

  constructor(
    private bus: BusService,
    private eventsService: EventsService,
    private route: ActivatedRoute,
    private actionRequest: ActionsService,
    private userService: UserManagementService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.filteredEvents = [];
    this.allEvents = [];

    this.route.queryParams.subscribe(this.handleQueryParamsChange.bind(this));
  }

  private handleQueryParamsChange(params: Params) {
    // handle 'go back' when someone wants to undo their filter
    if (!params.filtered && this.state === 'filtered' && !this.userFilteringDisabled) {
      this.changeState('showAll');
    }
  }

  get events() {
    if (this.state === 'showAll') {
      return this.allEvents;
    }
    if (this.state === 'filtered') {
      return this.filteredEvents;
    }
    if (this.state === 'pending') {
      return this.allEvents;
    }
    if (this.state === 'showRecordEvents') {
      return this.allEvents;
    }
  }

  get userFilteringDisabled() {
    return this.state === 'showRecordEvents' || this.withFilter;
  }

  hasMetadata(category: string, event: Event) {
    const fullName = this.getFullName(event);
    const results = METADATA_EVENTS.findIndex((e) => {
      if (category) {
        return e.category === category && e.fullName.toLowerCase() === fullName;
      }

      return e.fullName.toLowerCase() === fullName;
    });

    return results !== -1;
  }

  getUser(event: Event) {
    const candidates = this.users.filter(user => user.id === event.userId);
    if (candidates.length > 0) {
      // return candidates[0].directoryId || '---';
      return candidates[0];
    }
    // return '---';
  }

  getUserByUsername(username: string) {
    const candidates = this.users.filter(user => user.directoryId === username);
    if (candidates.length > 0) {
      // return candidates[0].directoryId || '---';
      return candidates[0];
    }
  }

  getFullName(event: Event) {
    return event.category.toLowerCase() + '.' + event.eventName.toLowerCase();
  }

  orderArray(events: Array<Event>, ascending: boolean) {
    return events.sort((a, b) => {
      if (ascending) {
        const c = a;
        a = b;
        b = c;
      }

      return (new Date(b.timestampAt) as any) - (new Date(a.timestampAt) as any);
    });
  }

  get canGoBack() {
    return this.lastPosition > this.ItemsLimit;
  }

  public previous() {
    // there are no previous items if latest are already shown
    if (this.isFirstLoad || this.lastPosition === this.ItemsLimit) {
      return;
    }

    let diff = this.lastPosition % this.ItemsLimit;
    diff = diff === 0 ? this.ItemsLimit : diff;

    let calculatedStart = this.lastPosition - this.ItemsLimit - diff;
    if (calculatedStart < 0) {
      calculatedStart = 0;
    }

    let availableDistance = this.ItemsLimit;
    if (this.events.length - calculatedStart < 0) {
      availableDistance = this.events.length - calculatedStart;
    }

    const previousEvents = this.events.slice(calculatedStart, calculatedStart + availableDistance);

    this.lastPosition = calculatedStart + availableDistance;

    this.display(previousEvents);
  }

  public next() {
    const needsToLoadMore = this.lastPosition === this.events.length;

    if (needsToLoadMore && this.canLoadMore) {
      this.loadMore();
      return;
    }

    let nextCount = this.ItemsLimit;
    if (this.lastPosition + this.ItemsLimit > this.events.length) {
      nextCount = this.events.length - this.lastPosition - 1;
    }

    const nextEvents = this.events.slice(this.lastPosition, this.lastPosition + nextCount);
    this.display(nextEvents);

    // update lastPosition to keep track of where we are
    this.lastPosition = this.lastPosition + nextEvents.length;
  }

  private compareTimestamp(a: Event, b: Event) {
    return (new Date(b.timestampAt) as any) - (new Date(a.timestampAt) as any);
  }

  private determineGroup(event: Event): string {
    const d = new Date(event.timestampAt);

    const formatted = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }).format(d);

    if (formatted) {
      return formatted;
    }

    return d.toLocaleDateString();
  }

  private getGroupIndex(items: Array<GroupedItems<Event>>, groupName: string) {
    const item = items.findIndex(i => i.groupName === groupName);

    return item;
  }

  private displayGrouped(events: Array<Event>) {
    const ordered = this.orderArray(events, false);
    let all = [];

    if (events.length === 0) {
      this.grouped.next([]);
    }

    for (const e of ordered) {
      const grouping = this.determineGroup(e);
      const index = this.getGroupIndex(all, grouping);

      if (index === -1) {
        const group = { groupName: grouping, items: new Array<Event>() };
        group.items.push(e);

        const current = all;
        const combined = current.concat(group);
        this.grouped.next(combined);
        all = combined;
      } else {
        const current = all;
        current[index].items.push(e);
        this.grouped.next(current);
        all = current;
      }
    }
  }

  private receiveEvents(events: Array<Event>) {
    this.allEvents.push(...events);
    this.display(events);

    this.lastPosition = this.lastPosition + events.length;
  }

  private display(events: Array<Event>) {
    this.canLoadMore = events.length === this.ItemsLimit;

    if (!this.canLoadMore) {
      // don't show on records because it's not needed
      if (this.state !== 'showRecordEvents') {
        // eslint-disable-next-line max-len
        /*const initial: Event = { eventName: 'TRACKING_START', category: 'SYSTEM', timestampAt: new Date(TRACKING_START_DATE), id: 0, userId: 0 };
        if (!events.find((e) => e.id === 0)) {
          events.push(initial);
        }*/
      }
    }

    this.displayGrouped(events);

    this.isLoading = false;

    if (events.length > 0) {
      const first = events.sort(this.compareTimestamp)[0];
      this.firstTimestamp = new Date(first.timestampAt);

      const last = events.sort(this.compareTimestamp)[events.length - 1];
      this.lastItem = last;
      this.lastTimestamp = new Date(last.timestampAt);
    }

    if (!this.isFirstLoad) {
      // this.setUrl(); handled in state change
    } else {
      this.isFirstLoad = false;
    }
  }

  private setUrl() {
    if (this.userFilteringDisabled)
      return;
    
    const queryParams: any = {};

    if (this.state === 'filtered') {
      queryParams.filtered = true;
    } else {
      queryParams.filtered = null;
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }

  loadMore() {
    this.isLoading = true;
    if (this.isRangeSelection) {
      this.bus.publish(this.eventsService.requested.data.actions.ranged, this.ItemsLimit, this.lastTimestamp, this.endDate);
    } else {
      this.bus.publish(this.eventsService.requested.data.actions.ranged, this.ItemsLimit, this.lastTimestamp);
    }
  }

  loadRangeSelection() {
    this.bus.publish(this.eventsService.requested.data.actions.ranged, this.ItemsLimit, this.startingDate, this.endDate);
  }

  private loadFirst() {
    this.lastTimestamp = new Date();

    this.loadMore();
  }

  // State Logic

  private changeState(_state: string) {
    this.state = _state;

    if (_state === 'pending') {
      // reset pagination
      this.lastPosition = 0;
      this.lastItem = null;

      return; // nothing else is needed for pending state
    }

    // here, we assume that the state will get changed to 'filtered'/'showAll'

    // reset pagination
    this.lastPosition = 0;
    this.lastItem = null;

    if (_state === 'showAll') {
      this.filters = { category: null, action: null, item: null, user: null, userItem: null, dateRange: null, keyword: '' };
    }

    if (_state === 'filtered') {
      this.canLoadMore = false;
    }

    // display events
    const length = this.events.length >= this.ItemsLimit ? this.ItemsLimit : this.events.length;
    const firstBatch = this.events.slice(0, length);
    this.display(firstBatch);
    this.lastPosition = this.lastPosition = this.lastPosition + length;
    this.setUrl();
  }

  // Filtering Logic

  notifyFilterChange() {
    if (this.userFilteringDisabled) {
      return;
    }

    // no need to apply filters if nothing has been selected
    if (!this.filters.action && !this.filters.category && !this.filters.dateRange && (this.filters.keyword.length === 0 && this.state !== 'filtered') && !this.filters.userItem) {
      return;
    }

    this.changeState('pending');

    this.applyFilters();
  }

  filterUser(user, event) {
    if (event) {
      event.stopPropagation();
    }
    
    if (this.userFilteringDisabled) {
      return;
    }
    
    this.filters = Object.assign(this.filters, { user: user, userItem: null });

    this.changeState('pending');

    this.applyFilters();
  }

  resetFilters() {
    this.changeState('showAll');
  }

  pathFilterSelected(event: FilterRequest) {
    this.filters = Object.assign(this.filters, event);

    this.changeState('pending');

    this.applyFilters();
  }

  /**
   * Note to future-self: Right now, we fetch ALL events at once, which will get slow eventually. Right now, hybrid pagination is not a problem, but it certainly will be at some point.
   * Eugene recommended utilizing the PA filtering on the server-side to reduce client and network load.
   */
  private applyFilters() {
    // first, load all events
    this.actionRequest.getAllActions().subscribe((actions) => {
      this.allEvents = this.orderArray(actions, false);
      // then, filter all events with selected filters
      this.filteredEvents = this.getFilteredEvents();

      // reset pagination
      this.canLoadMore = false;
      this.lastPosition = 0;
      this.lastItem = null;

      // then, change the state to 'filtered' to display the filtered items
      this.changeState('filtered');
    });
  }

  private getFilteredEvents() {
    let events = this.allEvents;

    if (this.filters.category) {
      events = events.filter((e) => e.category === this.filters.category);
    }

    if (this.filters.action) {
      events = events.filter((e) => `${e.category}.${e.eventName}` === this.filters.action);
    }

    /* 
       Note on user filtering:
       Our system basically has two sources of users: the database and LDAP. As we don't want to confuse the end-user, we need to show them LDAP users.
       But without creating separate roundtrips, it's easier to check the username than getting the ID of a user that may or may not exist, so we also compare this, hence the reason for two user filters.
     */

    if (this.filters.user) {
      events = events.filter((e) => e.userId === this.filters.user);
    }

    if (this.filters.userItem) {
      events = events.filter((e) => {
        const u = this.getUser(e);
        if (u) {
          return u.directoryId === this.filters.userItem.username;
        }

        return false;
      });
    }

    if (this.filters.dateRange) {
      events = events.filter((e) => {
        const { begin: min, end: max } = this.filters.dateRange;
        const eventTime = new Date(e.timestampAt).getTime();

        return eventTime >= min.toDate().getTime() && eventTime <=  max.toDate().getTime();
      });
    }

    // todo: add item-specific filtering

    if (this.filters.item) {
      events = events.filter((e) => getEntityIdentifier(e) === this.filters.item);
    }

    if (this.filters.keyword.length > 0) {
      events = events.filter((e) => matchName(e, this.filters.keyword));
    }

    return events;
  }

  // filtering helpers

  private setCategories() {
    this.categories = AvailableEvents
      .map(item => ({ category: item.category, fullCategory: item.fullCategory }))
      .filter((value, index, self) => self.findIndex((e) => e.category === value.category) === index);
  }

  getEventsForCategory(category: string) {
    if (!category || category.length === 0) {
      return [];
    }

    return AvailableEvents.filter((e) => e.fullCategory === category);
  }

  getEventShortName(event) {
    const split = (event.fullName as string).split('.');

    // remove category
    split.splice(0, 2);

    return split.join('.');
  }

  get isFilteredAndEmpty() {
    return this.state === 'filtered' && this.events.length === 0;
  }

  get isFilteredByUser() {
    return this.filters.user !== null;
  }

  get isFilteredByItem() {
    return this.filters.item !== null;
  }

  get isFilteredByKeyword() {
    return this.filters.keyword.length > 0;
  }

  setFilterUser(event: DirectoryUser) {
    const user = event;

    Object.assign(this.filters, { user: null, userItem: user });

    console.log(this.filters);

    this.notifyFilterChange();
  }

  get displayableFilterUser() {
    if (this.filters.user) {
      return this.getUser({ userId: this.filters.user } as any);
    }

    if (this.filters.userItem) {
      return this.filters.userItem;
    }

    return null;
  }

  // Show Details

  showDetails(event: Event, ev: any) {
    if (ev) {
      ev.stopPropagation();
    }
    this.dialog.open(ActionDetailsDialogComponent, {
      width: '700px',
      data: { event }
    }).afterClosed().subscribe(mutated => {

    });
  }

  ngOnInit() {
    this.setCategories();

    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });
    if (this.recordId) {
      this.bus.subscribe(this.eventsService.received.data.actions.forRecords.success, this.receiveEvents, this);
      this.bus.publish(this.eventsService.requested.data.actions.forRecords, this.recordId);

      this.state = 'showRecordEvents';
    } else {
      this.bus.subscribe(this.eventsService.received.data.actions.ranged.success, this.receiveEvents, this);
      this.loadFirst();
    }

    if (this.withFilter) {
      this.pathFilterSelected(this.withFilter);
    }
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.eventsService.received.data.actions.ranged.success, this.receiveEvents);
  }
}

