import { Todo } from 'app/services/todos/todos.service';


export interface TodoNode extends Todo {
  children?: TodoNode[];
  parentNode?: TodoNode;
}


export function prune(node: TodoNode): Todo {
  const todo = { ...node };
  delete todo.children;
  delete todo.parentNode;
  return todo;
}


export function isAncestor(ancestor: TodoNode, descendant: TodoNode) {
  let current = descendant;
  while (current) {
    if (current === ancestor || current.issuerId === ancestor.issuerId) {
      return true;
    }
    current = current.parentNode;
  }
  return false;
}


export type TodoTreeFilter = 'all' | 'issuedByMe';


export function nodePassesFilter(filter: TodoTreeFilter, me: any) {
  return (todo: TodoNode) => filter === 'all' || todo.issuerId.toString() === me.toString();
}

export function hasPassingChildren(filter: TodoTreeFilter, me: any) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return (todo: TodoNode) => todo.children && todo.children.filter(shouldNodeBeDisplayed(filter, me)).length > 0;
}

export function shouldNodeBeDisplayed(filter: TodoTreeFilter, me: any) {
  return (todo: TodoNode) => nodePassesFilter(filter, me)(todo) || hasPassingChildren(filter, me)(todo);
}
