import { Inject, Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Template } from '../types';
import { SupervisionTemplatesBackendService } from '../../../../services/supervision/templates-backend.service';


@Component({
  selector: 'app-delete-template-dialog',
  templateUrl: './delete-template-dialog.component.html',
  styleUrls: ['./delete-template-dialog.component.scss']
})
export class DeleteTemplateDialogComponent implements OnInit {

  constructor(
    private backend: SupervisionTemplatesBackendService,
    private dialogRef: MatDialogRef<DeleteTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public template: Template,
  ) { }

  ngOnInit() {
  }

  delete() {
    this.backend.delete(this.template.id).subscribe(response => {
      this.dialogRef.close(true);
    });
  }
}
