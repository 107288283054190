import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusService } from "../../../services/bus.service";
import { EventsService } from "../../../services/events.service";

@Component({
  selector: 'profile-picture-component',
  templateUrl: './profile-picture.settings.template.html',
  styleUrls: ['./profile-picture.settings.styles.scss']
})
export class ProfilePictureComponent implements OnInit, OnDestroy {

  constructor(
    private bus: BusService,
    private events: EventsService
  ) { }

  public subscribe(): void {
  }

  public unSubscribe(): void {
  }

  // make subscribe on a component initialization
  public ngOnInit(): void {
    this.subscribe();
  }

  // make unsubscribe before destroying component
  public ngOnDestroy(): void {
    this.unSubscribe();
  }
}
