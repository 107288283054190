import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Todo, TodoSearchCriteria } from 'app/services/todos/todos.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.scss']
})
export class TodoListComponent implements OnInit, OnChanges {

  @Input() issuer: boolean | ((todo: Todo) => boolean) = false; // whether to show the issuer in general or per todo
  @Input() assignee: boolean | ((todo: Todo) => boolean) = false; // same as issuer
  @Input() doneButton: boolean | ((todo: Todo) => boolean) = true; // same as issuer
  @Input() todos: Todo[];
  @Input() dontTouchURL = false;
  @Input() criteria: TodoSearchCriteria = {
    done: false,
    limit: 50,
    order: 'lastChange',
    keyword: '',
  };

  @Input() resetDone: Subject<any>;

  keywordChange = new Subject<void>();

  @Output() fetch = new EventEmitter<TodoSearchCriteria>();

  constructor() { }

  ngOnInit() {
    this.fetch.emit(this.criteria);
    this.keywordChange.pipe(debounceTime(500)).subscribe(() => this.fetch.emit(this.criteria));

    if (this.resetDone) {
      this.resetDone.subscribe(() => {
        this.criteria.done = false;
        this.fetchInProgress();
      });
    }
  }

  ngOnChanges(changes: { criteria?: TodoSearchCriteria }) {
    if (changes.criteria) {
      this.fetch.emit(this.criteria);
    }
  }

  // fetch those tasks that are in-progress
  fetchInProgress() {
    this.criteria.done = false;
    this.fetch.emit(this.criteria);
  }

  // fetch those tasks that are done
  fetchDone() {
    this.criteria.done = true;
    this.fetch.emit(this.criteria);
  }

  showIssuer(todo: Todo): boolean {
    if (typeof this.issuer === 'function') {
      return this.issuer(todo);
    } else {
      return this.issuer;
    }
  }

  showAssignee(todo: Todo): boolean {
    if (typeof this.assignee === 'function') {
      return this.assignee(todo);
    } else {
      return this.assignee;
    }
  }

  showDoneButton(todo: Todo): boolean {
    if (typeof this.doneButton === 'function') {
      return this.doneButton(todo);
    } else {
      return this.doneButton;
    }
  }
}
