import {
  Component, OnInit, OnDestroy,
  ViewChild, ElementRef, ViewChildren, QueryList,
  HostListener,
  AfterViewInit
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete } from '@angular/material';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';

import { Observable ,  Subject, combineLatest } from 'rxjs';
import { debounceTime, take, delay, pairwise } from 'rxjs/operators';

import { EventsService } from '../../../services/events.service';
import { BusService } from '../../../services/bus.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { TodosService } from '../../../services/todos/todos.service';
import { Action, Status, RpaWorkflowService } from '../../../services/rpa/rpa.workflow.service';

import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DpiaComponent } from './dpia/dpia.component';
import { RpaGetService } from 'app/services/rpa/rpa.get.service';
import { DirectoryUser } from 'app/services/directory/directory-user';
import { RpaService } from 'app/services/rpa/rpa.service';
import { CustomPaDialogComponent } from '../custom-pa-dialog/custom-pa-dialog.component';
import {ActionsService, LogbookAction} from '../../../services/actions/actions.service';
import {AccessControlService, roles} from "../../../services/access-control.service";


export interface ProcessingActivity {
  assignedDepartment: number;
  controllerId: number;
  createdAt: Date;
  createdBy: number;
  responsiblePerson: number;
  creatorDirectoryId: string;
  creatorEmail: string;
  creatorFirstName: string;
  creatorLastName: string;
  respDirectoryId: string;
  respEmail: string;
  respFirstName: string;
  respLastName: string;
  deprecationDate: Date;
  documents: [any];
  erasureTime: string;
  erasureTimeConsideration: string;
  lastStatusChange: string;
  paCreatedOn: Date;
  paDataCategoriesDescription: Array<string>;
  paDataCategoriesDescriptionOther: string;
  paDataSource: Array<string>;
  paDataSourceOther: string;
  paDataSubjectCategories: Array<string>;
  paDataSubjectCategoriesOther: string;
  paDescription: string;
  paDpia: any;
  paDpiaAdditionalRemarks: string;
  paDpoStatement: string;
  paGeneralRemarks: string;
  paToms: string;
  paId: number;
  paLastChangedOn: Date;
  paLegalBasis: Array<string>;
  paLegalBasisOther: string;
  paName: string;
  paOwner: number;
  paSensitiveData: string;
  paSensitiveDataLegalBasis: string;
  paSensitiveDataLegalJustification: string;
  paStatus: string;
  paTags: any;
  processedExternally: boolean;
  processedInternally: boolean;
  tumOrgAssociation: string;
  tumResponsibleOrgUnit: string;
  tumResponsiblePerson: string;
  tumJointControllerInformation: string;
  internalProcessorsOther: string;
}

@Component({
  selector: 'app-pa-details',
  templateUrl: './pa-details.component.html',
  styleUrls: ['./pa-details.component.scss']
})
export class PaDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('formField') formControlElements: QueryList<ElementRef>;
  @ViewChild('taginput', { static: true }) taginput: ElementRef;
  @ViewChild('auto', { static: false }) auto: MatAutocomplete;
  @ViewChild('dpia', { static: false }) dpia: DpiaComponent;

  pa: ProcessingActivity;
  paId: number;
  accessToken: string;             // --> access token to be used to load the pa
  providableAccessToken: string;   // --> access token to be given to comments and todos to create links
  paStatus: string = undefined;
  readonlyAccess = false;

  _assignee: any;
  creator: any;
  responsiblePerson: DirectoryUser;
  _dpo: any;
  departmentAssociations = [];
  vendorAssociations = [];
  _suggestedTags = [];

  formGroup: FormGroup;
  fieldsBeingSaved = {};

  actions: Action[] = [];
  scrolled = new Subject<void>();

  paStatusPinned = true;
  departmentHasOther = false;

  editors: DirectoryUser[] = [];

  paChanges: LogbookAction[] = [];
  changesSinceLastApproval: LogbookAction[] = null;

  lastCloseAction: LogbookAction;

  constructor(
    private route: ActivatedRoute,
    private router: Router, // needed for ActivatedRoute
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    public auth: AuthenticationService,
    private todos: TodosService, // needed in case of directly visited URL
    public location: Location, // needed for the back button
    public workflow: RpaWorkflowService,
    public rpaGet: RpaGetService,
    public rpaService: RpaService,
    private actionService: ActionsService,
    private accessControl: AccessControlService
  ) {
    combineLatest(this.route.paramMap, this.route.queryParamMap)
      .pipe(take(1))
      .subscribe(([params, queryParams]) => {
        // eslint-disable-next-line radix
        this.paId = parseInt(params.get('paId'));
        if (queryParams.has('access-token')) {
          this.accessToken = queryParams.get('access-token');
          this.providableAccessToken = this.accessToken; // if I came with a token, I cannot get a new one, but pass on the old one
        }
        this.fetchData();
      });

    this.formGroup = new FormGroup({
      paStatus: new FormControl(undefined),
      paDescription: new FormControl('', Validators.required),
      paPurpose: new FormControl('', Validators.required),
      paLegalBasis: new FormControl('', Validators.required),
      paLegalBasisOther: new FormControl(''),
      paDataSubjectCategories: new FormControl('', Validators.required),
      paDataSubjectCategoriesOther: new FormControl(''),
      paDataCategoriesDescription: new FormControl('', Validators.required),
      paDataCategoriesDescriptionOther: new FormControl(''),
      paDataSource: new FormControl(''),
      paDataSourceOther: new FormControl(''),
      processedInternally: new FormControl(undefined),
      processedExternally: new FormControl(undefined),
      erasureTime: new FormControl(''),
      erasureTimeConsideration: new FormControl(''),
      toms: new FormControl(''),
      paDpiaAdditionalRemarks: new FormControl(''),
      paGeneralRemarks: new FormControl(''),
      paToms: new FormControl(''),
      paTags: new FormControl(''),
      paDpoStatement: new FormControl(''),
      tumOrgAssociation: new FormControl('', [Validators.required, Validators.pattern(/^tu[a-z0-9]{5}|TU[A-Z0-9]{5}$/)]),
      tumResponsibleOrgUnit: new FormControl(''),
      tumResponsiblePerson: new FormControl(''),
      tumJointControllerInformation: new FormControl(''),
      internalProcessorsOther: new FormControl(''),
    });

    this.actionService.getUsersByPa(this.paId)
      .subscribe(users => {
        this.editors = users;
      });

    this.actionService.getActionsByPa(this.paId)
      .subscribe(actions => {
        this.paChanges = actions;
        this.lastCloseAction = this.paChanges.find(action => action.eventName === 'EXECUTE_ACTION.APPROVE');

        if (this.lastCloseAction) {
          this.changesSinceLastApproval = this.paChanges.filter(c =>
            (new Date(c.timestampAt) > new Date(this.lastCloseAction.timestampAt)));
        } else {
          this.changesSinceLastApproval = this.paChanges;
        }
      });
  }

  ngOnInit() {
    this.subscribe();
  }

  ngAfterViewInit() {
    // become able to highlight fields with error when the user scrolls past them
    this.scrolled.pipe(debounceTime(10)).subscribe(() => {
      this.formControlElements.forEach(el => {
        const native = this._getNative(el);
        const controlName = native.getAttribute('formControlName');
        if (controlName in this.formGroup.controls && !this.formGroup.controls[controlName].touched) {
          if (native.getBoundingClientRect().y < 200) { // if it is going up, mark as touched
            this.formGroup.controls[controlName].markAsTouched();
          }
        }
      });
    });

    this.subscribeToUpdateRequiredOtherFields();
  }

  ngOnDestroy() {
    this.scrolled.complete();
    this.unsubscribe();
  }

  loadData(data: ProcessingActivity) {
    this.pa = data;
    this.creator = {
      firstName: data.creatorFirstName,
      lastName: data.creatorLastName,
      email: data.creatorEmail,
      directoryId: data.creatorDirectoryId,
    };

    this.responsiblePerson = {
      _id: data.responsiblePerson,
      firstName: data.respFirstName,
      lastName: data.respLastName,
      email: data.respEmail,
      username: data.respDirectoryId
    };

    this.updateDisabledFields();

    const setDpiaInterval = setInterval(() => {
      if (this.dpia) {
        this.dpia.loadData(data.paDpia);
        clearInterval(setDpiaInterval);
      }
    }, 100);

    setTimeout(() => {
      this.formGroup.patchValue(data);
    }, 10);

    this.fetchPossibleActions();

    if (!this.providableAccessToken) {
      this.rpaGet.requestAccessToken(this.paId).subscribe(response => {
        this.providableAccessToken = (<any>response).token;
      });
    }
  }

  saveResponsiblePerson(user: DirectoryUser) {
    this.fieldsBeingSaved['responsiblePerson'] = true;
    this.rpaService.updateResponsiblePerson(this.paId, user)
      .pipe(delay(1000)) // imitate behavior of other fields
      .subscribe(() => {
        this.fieldsBeingSaved['responsiblePerson'] = false;
      });
  }

  saveDpia(data) {
    // otherwise, the component tries to save DPIA results every time, even when the PA is locked (no permission/closed)
    if (!this.canWrite()) {
      return;
    }

    this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
      paId: this.paId,
      fieldName: 'paDpia',
      value: data,
    });

    // in order to allow sorting by dpia results, we also store a 'dpia value' every time we update the dpia.
    // this could also be done on the backend.
    if (data && data.risk && data.severity) {
      const dpia_value = data.risk.value * data.severity.value;
      this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
        paId: this.paId,
        fieldName: 'paDpiaValue',
        value: dpia_value,
      });
    }
  }


  updateDisabledFields() {
    //  https://stackoverflow.com/questions/40494968/reactive-forms-disabled-attribute
    const affectedControls = ['paLegalBasis', 'paDataSubjectCategories', 'paDataCategoriesDescription', 'processedInternally',
      'processedExternally',
      'paDataSource'];
    affectedControls.forEach(ctrl => {
      if (this.canWrite()) {
        this.formGroup.get(ctrl).enable();
      } else {
        this.formGroup.get(ctrl).disable();
      }
    });
  }

  get uploadUrl() {
    return '/pa/basics/' + this.paId + '/file';
  }

  updateDocumentList() {
    this.fetchData();
  }

  // PROCESSING
  submit(fieldName) {
    // the field should either be empty (when information gets removed) or changed to be valid
    if (this.formGroup.get(fieldName).valid || !this.formGroup.get(fieldName).value) {
      if (this.fieldsBeingSaved[fieldName]) {
        clearTimeout(this.fieldsBeingSaved[fieldName]);
      }

      this.fieldsBeingSaved[fieldName] = setTimeout(() => {
        const value = this.convertField(fieldName);
        this.submitFieldValue(fieldName, value);
      }, 1000);
    }
  }

  convertField(fieldName) {
    const originalValue = this.formGroup.get(fieldName).value;

    if (fieldName === 'paTags') {
      return JSON.stringify(originalValue);
    }
    if (fieldName === 'tumOrgAssociation') {
      return originalValue.toUpperCase();
    }

    // return without mutation
    return originalValue;
  }

  submitted(fieldName) {
    delete this.fieldsBeingSaved[fieldName];
    this.fetchPossibleActions();
  }

  submitFieldValue(fieldName, value) {
    this.bus.publish(this.events.requested.data.rpa.basics.updateField, {
      paId: this.paId,
      fieldName: fieldName,
      value: value,
    });
  }

  get Status() { return Status; }

  canWrite() {
    if (this.readonlyAccess) {
      return false;
    }

    if (this.pa) {
      const permission = this.pa.paStatus === Status.draft || this.pa.paStatus === Status.open;
      // console.log(permission)
      return permission;
    }
  }

  // NOTE: this function does not check user's permissions,
  //       just that the PA's status would allow for modifying statement.
  canWriteStatement() {
    if (this.readonlyAccess) {
      return false;
    }

    if (this.pa) {
      return this.pa.paStatus === Status.draft ||
            this.pa.paStatus === Status.open ||
            this.pa.paStatus === Status.submitted;
    }

    return false;
  }

  legalBasisHasOther() {
    const lb = this.formGroup.get('paLegalBasis').value;
    if (lb) {
      return lb.indexOf('pa.legalbasis.other') > -1;
    } else {
      return false;
    }
  }

  dataSourceHasOther() {
    const ds = this.formGroup.get('paDataSource').value;
    if (ds) {
      return ds.indexOf('pa.dataSource.other') > -1;
    } else {
      return false;
    }
  }

  dataSubjectCategoriesHasOther() {
    const subjects = this.formGroup.get('paDataSubjectCategories').value;
    if (subjects) {
      return subjects.indexOf('pa.dataSubject.categories.other') > -1;
    } else {
      return false;
    }
  }

  dataCategoriesHasOther() {
    const descr = this.formGroup.get('paDataCategoriesDescription').value;
    if (descr) {
      return descr.indexOf('pa.dataSubject.dataCategories.other') > -1;
    } else {
      return false;
    }
  }

  setDepartmentHasOther(hasOther: boolean) {
    this.departmentHasOther = hasOther;
  }

  addtag(event: MatChipInputEvent) {
    if (!this.auto.isOpen) {
      if (!this.formGroup.get('paTags').value) {
        this.formGroup.get('paTags').setValue([]);
      }

      this.formGroup.get('paTags').value.push({
        display: event.value,
        value: event.value
      });
      this.submit('paTags');
      if (this.taginput) {
        this.taginput.nativeElement.value = '';
      }
    }
  }

  selecttag(event: MatAutocompleteSelectedEvent) {
    this.formGroup.get('paTags').value.push({
      display: event.option.viewValue,
      value: event.option.viewValue,
    });
    this.submit('paTags');
    if (this.taginput) {
      this.taginput.nativeElement.value = '';
    }
  }

  removetag(tag: any) {
    this.formGroup.get('paTags').setValue(this.formGroup.get('paTags').value.filter(t => t.value !== tag.value));
    this.submit('paTags');
  }

  get suggestedTags(): string[] {
    if (this.taginput) {
      const q = this.taginput.nativeElement.value.toLowerCase();
      if (q === '') {
        return [];
      }
      return this._suggestedTags.filter(tag => tag.toLowerCase().indexOf(q) !== -1);
    }
  }

  do(action: Action) {
    if (this.canDo(action)) {
      this.dialog.open(ConfirmationDialogComponent, {
        width: '512px',
        data: action
      }).afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.workflow.do(action, this.paId).subscribe((result: any) => {
            this.pa.paStatus = result.pa_status;
            this.fetchPossibleActions();
            this.updateDisabledFields();
          });
        }
      });
    }
  }

  canDo(action: Action) {
    return !action.disabled && (!action.requiresValidation || this.formGroup.valid);
  }

  showInvalidFields() {
    Object.entries(this.formGroup.controls).forEach(([_, control]) => {
      control.markAsTouched();
    });

    const target = this.formControlElements.find(el => {
      const native = this._getNative(el);
      const formControl = native.getAttribute('formControlName');
      return formControl in this.formGroup.controls && !this.formGroup.controls[formControl].valid;
    });

    window.scrollTo({
      top: this._getNative(target).getBoundingClientRect().y + window.pageYOffset - 300,
      behavior: 'smooth'
    });
  }

  // Angular does not provide the observable, so we take this detour
  @HostListener('window:scroll')
  onscroll() {
    this.scrolled.next();
  }

  _getNative(el: any) {
    if (el.nativeElement) {
      return el.nativeElement;
    }
    if (el._elementRef) {
      return el._elementRef.nativeElement;
    }
  }

  fetchPossibleActions() {
    this.workflow.possibleActions(this.pa).subscribe(actions => this.actions = actions);
  }

  fetchData() {
    if (this.readonlyAccess) { // we assume readonlyAccess came from an access-token based visit. So let's save one request.
      this.rpaGet.request(this.paId, this.accessToken).subscribe(data => this.loadData(data as any));
    } else {
      this.rpaGet.request(this.paId).subscribe( // try without the token, maybe it is not needed
        data => this.loadData(data as any),
        () => { // apparently the token IS needed, which implies read-only access
          this.readonlyAccess = true;
          this.rpaGet.request(this.paId, this.accessToken).subscribe(data => this.loadData(data as any));
        }
      );
    }
  }

  downloadArchiveLink() {
    return this.rpaGet.getArchiveLink(this.paId);
  }


  editCustomPa() {
    const ref = this.dialog.open(CustomPaDialogComponent, {
      width: '700px',
      data: {
        id: this.paId
      }
    });
    ref.afterClosed().subscribe((update: boolean) => {
      if (update) {
        this.fetchData();
      }
    });
  }

  getChangesForFieldSinceLastApproval(field: string) {
    return this.changesSinceLastApproval.filter(c => c.metadata && c.metadata.changes &&  c.metadata.changes.some(change => change.fieldName === field));
  }

  get shouldShowChanges() {
    return this.paChanges && this.paChanges.length > 0 && (this.pa.paStatus === Status.submitted) && this.accessControl.hasRoleOrStronger(roles.dst);
  }

  get hasNoChanges() {
    return this.changesSinceLastApproval !== null
      && !this.changesSinceLastApproval.some((c) => c.eventName === 'UPDATE_FIELD' ||c.eventName === 'CUSTOM.UPDATE');
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.rpa.basics.updateField.success, this.submitted, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.rpa.basics.updateField.success, this.submitted);
  }

  private subscribeToUpdateRequiredOtherFields() {
    this.formGroup.controls['paLegalBasis'].valueChanges
      .subscribe((_) => {
        this.formGroup.controls['paLegalBasisOther'].setValidators(this.legalBasisHasOther() ? [Validators.required] : []);
        this.formGroup.controls['paLegalBasisOther'].updateValueAndValidity();
      });

    this.formGroup.controls['paDataSubjectCategories'].valueChanges
      .subscribe((_) => {
        this.formGroup.controls['paDataSubjectCategoriesOther']
          .setValidators(this.dataSubjectCategoriesHasOther() ? [Validators.required] : []);
        this.formGroup.controls['paDataSubjectCategoriesOther'].updateValueAndValidity();
      });

    this.formGroup.controls['paDataCategoriesDescription'].valueChanges
      .subscribe((_) => {
        this.formGroup.controls['paDataCategoriesDescriptionOther']
          .setValidators(this.dataCategoriesHasOther() ? [Validators.required] : []);
        this.formGroup.controls['paDataCategoriesDescriptionOther'].updateValueAndValidity();
      });

    this.formGroup.controls['paDataSource'].valueChanges
      .subscribe((_) => {
        this.formGroup.controls['paDataSourceOther'].setValidators(this.dataSourceHasOther() ? [Validators.required] : []);
        this.formGroup.controls['paDataSourceOther'].updateValueAndValidity();
      });

    this.formGroup.controls['internalProcessors'].valueChanges
      .subscribe((_) => {
        this.formGroup.controls['internalProcessorsOther'].setValidators(this.departmentHasOther ? [Validators.required] : []);
        this.formGroup.controls['internalProcessorsOther'].updateValueAndValidity();
      });
  }
}
