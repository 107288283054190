import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Todo, TodoSearchCriteria, TodosService } from 'app/services/todos/todos.service';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationService } from 'app/services/authentication.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { tap, debounceTime, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-subject-todos-dialog',
  templateUrl: './subject-todos-dialog.component.html',
  styleUrls: ['./subject-todos-dialog.component.scss']
})
export class SubjectTodosDialogComponent implements OnInit, OnDestroy {

  todos: Todo[];
  assigned: Todo[];
  showIssuer: (todo: Todo) => boolean;

  todoCriteria: TodoSearchCriteria;
  assignedCriteria: TodoSearchCriteria;

  fetchTodos = new Subject<TodoSearchCriteria>();
  fetchAssigned = new Subject<TodoSearchCriteria>();
  subs = new Subscription();

  constructor(
    public auth: AuthenticationService,
    private service: TodosService,
    @Inject(MAT_DIALOG_DATA) public subject,
  ) {
    this.showIssuer = (todo: Todo) => auth.id.toString() !== todo.issuerId.toString();
    this.todoCriteria = { subject };
    this.assignedCriteria = { subject };
  }

  ngOnInit() {
    this.subs.add(
      this.fetchTodos.pipe(
        tap(criteria => this.todoCriteria = Object.assign(criteria, { subject: this.subject })),
        debounceTime(0),
        switchMap(criteria => this.service.get(criteria)),
        this.service.polish,
      ).subscribe(todos => this.todos = todos as Todo[])
    );

    this.subs.add(
      this.fetchAssigned.pipe(
        tap(criteria => this.assignedCriteria = Object.assign(criteria, { subject: this.subject })),
        debounceTime(0),
        switchMap(criteria => this.service.getAssigned(criteria)),
        this.service.polish,
      ).subscribe(assigned => this.assigned = assigned as Todo[])
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
