import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { Router } from '@angular/router';
import { RecordsResourceService, RecordsModel } from 'app/services/records/record-resource.service';
import * as moment from 'moment';
import { DepartmentService } from 'app/services/departments/department.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const TYPE = 'complaint';
const PREFIX = 'BS-';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [LOCALE_ID] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ComplaintsCreateDialogComponent implements OnInit, OnDestroy {
  title = new FormControl('', [Validators.required]);
  details = new FormControl('', []);
  receivedOn = new FormControl(moment(), []);
  status = new FormControl('', []);
  department = new FormControl(undefined, []);

  departments: any[] = [];

  id: number = undefined;
  isNew = true;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ComplaintsCreateDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private depService: DepartmentService,
    private router: Router,
    private recordsService: RecordsResourceService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.setExistingData();
  }

  isValid() {
    return this.title.valid && this.receivedOn.valid;
  }

  setExistingData() {
    if (this.data) {
      this.title.setValue(this.data.title);
      this.details.setValue(this.data.details);
      this.receivedOn.setValue(new Date(this.data.metadata.receivedOn));
      this.status.setValue(this.data.status);
      this.department.setValue(this.data.department);
      this.id = this.data.id;
      this.isNew = false;
    }
  }

  ngOnInit() {
    this.subscribe();
    this.status.setValue('open');
    this.depService.requestGetAll().subscribe(deps => this.departments = deps);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  update() {
    const record: RecordsModel = {
      title: this.title.value,
      details: this.details.value,
      status: this.status.value,
      department: this.department.value,
      type: TYPE,
      prefix: PREFIX,
      metadata: {
        receivedOn: this.receivedOn.value.toJSON()
      }
    };
    if (this.id) {
      record.id = this.id;
      this.bus.publish(this.events.requested.action.records.update, record);
    } else {
      this.bus.publish(this.events.requested.action.records.create, record);
    }
  }

  updateFinished() {
    const mutated = true;
    this.dialogRef.close(mutated);
  }

  handleError(error: string) {
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.records.create.success, this.updateFinished, this);
    this.bus.subscribe(this.events.received.action.records.create.failure, this.handleError, this);
    this.bus.subscribe(this.events.received.action.records.update.success, this.updateFinished, this);
    this.bus.subscribe(this.events.received.action.records.update.failure, this.handleError, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.records.create.success, this.updateFinished);
    this.bus.unsubscribe(this.events.received.action.records.create.failure, this.handleError);
    this.bus.unsubscribe(this.events.received.action.records.update.success, this.updateFinished);
    this.bus.unsubscribe(this.events.received.action.records.update.failure, this.handleError);
  }

}
