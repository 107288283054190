import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BusService } from '../bus.service';
import {
  IGetOptions,
  RequestService
} from '../request.service';
import { EventsService } from '../events.service';
import { AppConfig } from '../../app.config';
import { HttpParams } from '@angular/common/http';

interface IGetResponse {

}

/*
  An interlayer service for dashboard get request between request service and dashboard component
*/

@Injectable()
export class ReportGetService {

  private options: IGetOptions<IGetResponse>;
  private router: string;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: '/report',
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public openPasPdf(pas: any[]): void {
    // Directly call a backend route which will result in a PDF displayed in the browser. The user then has
    // to manually download that. TODO: Offer a document download directly.
    const paramsString = new HttpParams().append('pas', pas.join(',')).toString();

    const url =
      `${AppConfig.apiReport}/pas/pdf?${paramsString}&token=${localStorage.getItem('currentUser')}&lang=${localStorage.getItem('lang')}`;
    window.open(url, '_blank');
  }

  public success(data: IGetResponse): void {
    this.bus.publish(this.events.received.data.report.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.data.report.failure, httpErrorResponse);
  }

  private request(): void {
    this.bus.publish(this.events.notified.request.starting);
    this.requestService.get<IGetResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.report, this.request.bind(this));
  }
}
