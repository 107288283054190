import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { AuthenticationService } from 'app/services/authentication.service';
import { Todo, TodosService } from 'app/services/todos/todos.service';
import { TodoDialogComponent, TodoDialogParams } from '../todo-dialog/todo-dialog.component';
import { AccessControlService, roles } from 'app/services/access-control.service';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';


export interface TodoDetailsDialogOptions {
  todo: Todo;
  showGoto?: boolean;
  cleanUpURL?: boolean;
  dontTouchURL?: boolean;
}


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'todo-detail-dialog',
  templateUrl: './todo-detail-dialog.component.html',
  styleUrls: ['./todo-detail-dialog.component.scss']
})
export class TodoDetailDialogComponent implements OnInit {
  todo: Todo;
  showGoto = true;
  todoLink = undefined;
  cleanUpURL = true;
  todoLinkParams = {};

  _gotoTask = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TodoDetailsDialogOptions,
    public ref: MatDialogRef<TodoDetailDialogComponent>,
    private dialog: MatDialog,
    private auth: AuthenticationService,
    private access: AccessControlService,
    private service: TodosService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private bus: BusService,
    private events: EventsService,
  ) {
    this.todo = data.todo;

    if (this.todo.link) {
      this.todoLinkParams = Object.assign({}, {
        todo: this.todo.id,
        subject: this.todo.subject,
      }, router.parseUrl(this.todo.link).queryParams);
      this.todoLink = this.todo.link.split('?')[0];
    }

    if (data.showGoto !== undefined) {
      this.showGoto = data.showGoto;
    }

    if (data.cleanUpURL === false) {
      this.cleanUpURL = false;
    }

    if (this.todo.id && !data.dontTouchURL) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { todo: this.todo.id },
        queryParamsHandling: 'merge'
      });
    }

    this.ref.afterClosed().subscribe(() => {
      if (this._gotoTask && !this.data.dontTouchURL) {
        // we are leaving this page. pass relevant information.
        this.router.navigate([this.todoLink], {
          queryParams: this.todoLinkParams
        });
      } else {
        // the URL contains page state information. Clean it up.
        // except when we are told not to.
        if (this.cleanUpURL && !this.data.dontTouchURL) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { todo: undefined },
            queryParamsHandling: 'merge'
          });
        }
      }
    });
  }

  ngOnInit() {
  }

  gotoTask() {
    this._gotoTask = true;
    this.ref.close();
  }

  get now() {
    return new Date();
  }

  done() {
    this.service.done(this.todo).subscribe(() => {
      this.todo.done = true;
      this.ref.close(this.todo);
      this.bus.publish(this.events.notified.todos.singleUpdate, this.todo);
    });
  }

  respond(response: any) {
    this.service.respond(this.todo, response).subscribe(() => {
      this.todo.done = true;
      this.todo.response = response;
      this.ref.close(this.todo);
      this.bus.publish(this.events.notified.todos.singleUpdate, this.todo);
    });
  }

  get editable(): boolean {
    return (
      this.auth.id.toString() === this.todo.issuerId.toString() ||
      this.access.hasRoleOrStronger(roles.admin)
    );
  }

  edit() {
    this.dialog.open(TodoDialogComponent, {
      width: '700px',
      data: {
        todo: this.todo
      } as TodoDialogParams
    });
  }

  createFollowUp() {
    this.dialog.open(TodoDialogComponent, {
      width: '700px',
      data: {
        parent: this.todo.id,
        subject: this.todo.subject,
        suggestedTitle: this.todo.title
      } as TodoDialogParams
    });
  }

  reopen() {
    this.service.reopen(this.todo).subscribe(() => {
      this.todo.done = false;
      this.bus.publish(this.events.notified.todos.singleUpdate, this.todo);
      this.ref.close(this.todo);
    });
  }
}
