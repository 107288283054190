import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete, MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable ,  ReplaySubject, zip } from 'rxjs';
import { filter, debounceTime, tap, switchMap, delay } from 'rxjs/operators';
import { isEqual, cloneDeep } from 'lodash';

import { RpaService, PASearchCriteria, PASearchOrdering } from '../../../services/rpa/rpa.service';
import { RpaXLSXExportService } from '../../../services/rpa/rpa.xlsx-export.service';
import { DepartmentService } from '../../../services/departments/department.service';
import { Status } from '../../../services/rpa/rpa.workflow.service';
import { dpiaGridClassByValues } from '../pa-details/dpia/dpia.consts';
import { ReportGetService } from 'app/services/report/report.get.service';
import { CustomPaDialogComponent } from '../custom-pa-dialog/custom-pa-dialog.component';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pa-list',
  templateUrl: './pa-list.component.html',
  styleUrls: ['./pa-list.component.scss']
})
export class PaListComponent implements OnInit, OnDestroy {

  @ViewChild('taginput', { static: true }) taginput: ElementRef;
  @ViewChild('auto', { static: false }) auto: MatAutocomplete;

  Status = Status;

  deps: any[];
  userDeps: any[];
  pas: any[];
  collapse = false;
  loading = false;
  receiving = false;

  filters = <PASearchCriteria>{
    keyword: '',
    departments: [],
    status: {
      draft: true,
      open: true,
      submitted: true,
      approved: true,
      archived: false,
    },
    subjects: [],
    tags: [],
    order: PASearchOrdering.LastStatusChange,
  };

  lastFilter = <PASearchCriteria>{}; // --> this is the last filter we've queried with, to compare against.

  _suggestedTags: string[] = [];
  filters$ = new ReplaySubject(1); // --> this subject will emit when there is a change in filters

  constructor(
    private rpas: RpaService,
    private departments: DepartmentService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private xlsx: RpaXLSXExportService,
    private reportService: ReportGetService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    if (window.innerWidth < 1100) {
      this.collapse = true;
    }

    this.lastFilter = cloneDeep(this.filters);
    const data = zip(                       // --> we need departments to sanitize pa data, so we need to do both:
      this.rpas.getPAs(this.filters),     // ... getting all pas
      this.departments.requestGetAll(),   // ... getting all departments
      this.departments.userDepartments(), // ... getting user's departments
    ).subscribe(([pas, deps, userDeps]) => {
      this.deps = deps as any;
      this.userDeps = userDeps as any;
      this.receivePAs(pas as any);

      this.filters$                                         // --> when filters change
        .pipe(tap(() => {                                     // --> update the url based on the filters
          const tree = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: this._buildQueryParamsFromFilters(),
          });

          this.location.go(tree.toString());
        }))
        .pipe(tap(() => this.loading = true))                // --> now we are going into `loading` mode
        .pipe(debounceTime(700))                             // --> wait for user to finish setting filters
        .pipe(filter(f => {                                  // --> check if filter has really changed
          const different = !isEqual(f, this.lastFilter);
          if (!different) {
            setTimeout(() => this.loading = false, 300);     // --> if not, exit `loading` mode
          }
          return different;
        }))
        .pipe(tap(f => this.lastFilter = cloneDeep(f)))      // --> set the current filter as the last used filter
        .pipe(switchMap(f =>
          this.rpas.getPAs(f)                                // --> get pas based on the filter
            .pipe(tap(() => this.receiving = true))            // --> now we are in `reciving` mode
            .pipe(delay(300))                                  // --> wait for pas to fade
            .pipe(tap(received => this.receivePAs(received as [any])))  // --> set the new pas
            .pipe(delay(300))                                  // --> wait a bit (so transition is smooth)
            .pipe(tap(() => {                                  // --> turn off `loading` and `receiving` mode.
              this.loading = false;
              this.receiving = false;
            }))
        ))
        .subscribe();
    });

    this.route.queryParams.subscribe(queryParams => {
      this._buildFiltersFromQueryParams(queryParams);
      this.notifyFilterChange();
    });
  }

  ngOnDestroy() {
    this.filters$.complete();
  }

  get ordering() {
    return PASearchOrdering;
  }

  receivePAs(pas: [any]) {
    this.pas = pas;
    this.pas.forEach(pa => {
      pa.dep = this.deps.find(dep => dep.id === pa.assignedDepartment);
      pa.lastChanged = new Date(pa.lastStatusChange);
      if (pa.paTags) {
        pa.paTags.forEach(tag => {
          if (!this._suggestedTags.includes(tag.display)) {
            this._suggestedTags.push(tag.display);
          }
        });
      }
    });
  }

  notifyFilterChange() {
    this.filters$.next(this.filters);
  }

  addtag(event: MatChipInputEvent) {
    if (!this.auto.isOpen) {
      this.filters.tags.push(event.value);
      this.notifyFilterChange();
      this.taginput.nativeElement.value = '';
    }
  }

  selecttag(event: MatAutocompleteSelectedEvent) {
    this.filters.tags.push(event.option.viewValue);
    this.notifyFilterChange();
    this.taginput.nativeElement.value = '';
  }

  removetag(tag: string) {
    this.filters.tags = this.filters.tags.filter(t => t !== tag);
    this.notifyFilterChange();
  }

  dpiaValueClass(pa) {
    if (pa.paDpia) {
      return dpiaGridClassByValues(pa.paDpia.risk.value, pa.paDpia.severity.value);
    }
  }

  dpiaRiskClass(pa) {
    return '';
  }

  dpiaSeverityClass(pa) {
    return '';
  }

  get suggestedTags(): string[] {
    const q = this.taginput.nativeElement.value.toLowerCase();
    if (q === '') {
      return [];
    }
    return this._suggestedTags.filter(tag => tag.toLowerCase().indexOf(q) !== -1);
  }

  private _buildQueryParamsFromFilters() {
    const queryParams = {};

    if (this.filters.keyword.length > 0) {
      queryParams['fk'] = this.filters.keyword;
    }
    if (this.filters.departments.length > 0) {
      queryParams['fd'] = this.filters.departments.join(',');
    }
    if (!this.filters.status.draft) {
      queryParams['fsd'] = 0;
    }
    if (!this.filters.status.open) {
      queryParams['fso'] = 0;
    }
    if (!this.filters.status.submitted) {
      queryParams['fss'] = 0;
    }
    if (!this.filters.status.approved) {
      queryParams['fsa'] = 0;
    }
    if (this.filters.status.archived) {
      queryParams['fsv'] = 1;
    }
    if (this.filters.subjects.length > 0) {
      queryParams['fs'] = this.filters.subjects.join(',');
    }
    if (this.filters.tags.length > 0) {
      queryParams['ft'] = this.filters.tags.join(',');
    }
    if (this.filters.order) {
      queryParams['fo'] = this.filters.order;
    }

    return queryParams;
  }

  private _buildFiltersFromQueryParams(queryParams) {
    if (queryParams.fk) {
      this.filters.keyword = queryParams.fk;
    }
    if (queryParams.fd) {
      queryParams.fd.split(',').forEach(item => {
        try {
          // eslint-disable-next-line radix
          this.filters.departments.push(parseInt(item));
        } catch (err) { }
      });
    }

    if (queryParams.fsd === '0') {
      this.filters.status.draft = false;
    }
    if (queryParams.fso === '0') {
      this.filters.status.open = false;
    }
    if (queryParams.fss === '0') {
      this.filters.status.submitted = false;
    }
    if (queryParams.fsa === '0') {
      this.filters.status.approved = false;
    }
    if (queryParams.fsv === '1') {
      this.filters.status.archived = true;
    }
    if (queryParams.fs) {
      this.filters.subjects.push(...queryParams.fs.split(','));
    }
    if (queryParams.ft) {
      this.filters.tags.push(...queryParams.ft.split(','));
    }
    if (queryParams.fo) {
      this.filters.order = queryParams.fo;
    }
  }

  reset() {
    this.filters.keyword = '';
    this.filters.departments = [];
    this.filters.status.draft = true;
    this.filters.status.open = true;
    this.filters.status.submitted = true;
    this.filters.status.approved = true;
    this.filters.status.archived = false;
    this.filters.subjects = [];
    this.filters.tags = [];
    this.notifyFilterChange();
  }

  exportXls() {
    this.xlsx.export(this.pas);
  }

  exportPdf() {
    const ids = this.pas.map(pa => pa.paId);
    this.reportService.openPasPdf(ids);
  }

  newPa() {
    const ref = this.dialog.open(CustomPaDialogComponent, {
      width: '700px',
    });
  }
}
