import { Component, OnInit, QueryList, Inject } from '@angular/core';
import { MatOption, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


export interface LongSelectDialogConfig {
  readonly?: boolean;
  multiple?: boolean;
  value: any;
  options: QueryList<MatOption>;
  title: string;
}


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'long-select-dialog',
  templateUrl: './long-select-dialog.component.html',
  styleUrls: ['./long-select-dialog.component.scss']
})
export class LongSelectDialogComponent implements OnInit {

  keyword = '';

  constructor(
    private ref: MatDialogRef<LongSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: LongSelectDialogConfig
  ) { }

  ngOnInit() {
  }

  selected(option) {
    if (this.config.multiple) {
      return this.config.value.includes(option.value);
    } else {
      return this.config.value === option.value;
    }
  }

  // only use this for single-select
  pick(option) {
    if (!this.config.multiple) {
      if (this.selected(option)) {
        this.done();
      } else {
        this.config.value = option.value;
      }
    }
  }

  // only use this for multi-select
  toggle(option) {
    if (this.config.multiple) {
      if (this.config.value.includes(option.value)) {
        this.config.value = this.config.value.filter(v => v !== option.value);
      } else {
        this.config.value.push(option.value);
      }
    }
  }

  get options() {
    return this.config.options.filter(option => option.viewValue.toLowerCase().includes(this.keyword.toLowerCase()));
  }

  done() {
    this.ref.close({
      value: this.config.value
    });
  }
}
