import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AccessControlService, roles } from 'app/services/access-control.service';
import { AuthenticationService } from 'app/services/authentication.service';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { Todo, TodosService, TodoType } from 'app/services/todos/todos.service';
import { TodoDialogComponent } from '../todo-dialog/todo-dialog.component';
import { isAncestor, shouldNodeBeDisplayed, TodoNode, TodoTreeFilter } from './todo-tree.types';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'todo-tree',
  templateUrl: './todo-tree.component.html',
  styleUrls: ['./todo-tree.component.scss']
})
export class TodoTreeComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() subject: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('suggested-title') suggestedTitle: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('suggested-type') suggestedType: TodoType;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('access-token') accessToken: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('access-token-key') accessTokenKey = 'access-token';

  @Input() roleSelection: { role: string } = null;
  @Input() allowCreateMultiple = false;

  updateTodo: any;
  todos: TodoNode[];
  filter: TodoTreeFilter = 'all';

  constructor(
    private dialog: MatDialog,
    private auth: AuthenticationService,
    private service: TodosService,
    private bus: BusService,
    private events: EventsService,
    private access: AccessControlService,
  ) { }

  ngOnInit() {
    this.requestTodos();
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  requestTodos() {
    if (this.access.hasRoleOrStronger(roles.dst)) {
      this.service.getAll({ subject: this.subject, order: 'chronological' }).subscribe(todos => {
        this.receiveTodos(todos);
      });
    } else {
      this.service.getParticipating({ subject: this.subject, order: 'chronological' }).subscribe(todos => {
        this.receiveTodos(todos);
      });
    }
  }

  receiveTodos(todos: Todo[]) {
    const all: TodoNode[] = this.service.polishReceivedTodos(...todos);

    all.forEach(todo => {
      todo.children = [];

      all.filter(t => t.parent === todo.id).forEach(child => {
        child.parentNode = todo;
        todo.children.push(child);
      });

      all.filter(t => t.issuerId === todo.assigneeId).forEach(possibleChild => {
        if (
          !possibleChild.parentNode
          && !isAncestor(possibleChild, todo)
          && !(possibleChild.createdAt < todo.createdAt)
        ) {
          todo.children.push(possibleChild);
          possibleChild.parentNode = todo;
        }
      });
    });

    this.todos = all.filter(t => t.parentNode === undefined);

  }

  newTodo() {
    this.dialog.open(TodoDialogComponent, {
      width: '700px',
      data: {
        suggestedTitle: this.suggestedTitle,
        suggestedType: this.suggestedType,
        subject: this.subject,
        link: true,
        accessToken: this.accessToken,
        accessTokenKey: this.accessTokenKey,
        roleSelection: this.roleSelection
      }
    });
  }

  newTodoMultiple() {
    this.dialog.open(TodoDialogComponent, {
      width: '700px',
      data: {
        suggestedTitle: this.suggestedTitle,
        suggestedType: this.suggestedType,
        subject: this.subject,
        link: true,
        accessToken: this.accessToken,
        accessTokenKey: this.accessTokenKey,
        roleSelection: this.roleSelection,
        multiple: true
      }
    });
  }

  handleNewTodo(subject: string) {
    if (subject === this.subject) {
      this.requestTodos();
    }
  }

  handleTodoUpdate(todo: Todo) {
    const _fix = (node: TodoNode) => {
      if (node.id === todo.id) {
        Object.assign(node, todo);
      } else if (node.children) {
        node.children.forEach(_fix);
      }
    };

    this.todos.forEach(_fix);
  }

  toggleFilter() {
    this.filter = this.filter === 'all' ? 'issuedByMe' : 'all';
  }

  filterActive() {
    return this.filter === 'issuedByMe';
  }

  subscribe() {
    this.bus.subscribe(this.events.notified.todos.create, this.handleNewTodo, this);
    this.bus.subscribe(this.events.notified.todos.singleUpdate, this.handleTodoUpdate, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.notified.todos.create, this.handleNewTodo);
    this.bus.unsubscribe(this.events.notified.todos.singleUpdate, this.handleTodoUpdate);
  }

  empty() {
    return !this.todos || this.todos.filter(shouldNodeBeDisplayed(this.filter, this.auth.id)).length === 0;
  }
}
