import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Subject ,  Subscription, Observable } from 'rxjs';
import { debounceTime, switchMap, tap, take, filter } from 'rxjs/operators';

import { TodoDialogComponent } from '../todo-dialog/todo-dialog.component';
import { TodosService, Todo, TodoSearchCriteria } from '../../../services/todos/todos.service';
import { AuthenticationService } from 'app/services/authentication.service';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { ActivatedRoute, RouterEvent, NavigationEnd, Router } from '@angular/router';
import { TodoDetailDialogComponent } from '../todo-detail-dialog/todo-detail-dialog.component';
import { CustomPaDialogComponent } from 'app/components/rpa/custom-pa-dialog/custom-pa-dialog.component';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'todos',
  templateUrl: './todos.component.html',
  styleUrls: ['./todos.component.scss']
})
export class TodosComponent implements OnInit, OnDestroy {

  todos: Todo[];
  assigned: Todo[];
  showIssuer: (todo: Todo) => boolean;

  todoCriteria: TodoSearchCriteria;
  assignedCriteria: TodoSearchCriteria;

  fetchTodos = new Subject<TodoSearchCriteria>();
  fetchAssigned = new Subject<TodoSearchCriteria>();
  subs = new Subscription();

  resetViews = new Subject();

  constructor(
    private bus: BusService,
    private events: EventsService,
    private service: TodosService,
    public auth: AuthenticationService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.showIssuer = (todo: Todo) => auth.id.toString() !== todo.issuerId.toString();

    this.route.queryParamMap.pipe(take(1)).subscribe(params => {
      if (params.has('todo')) {
        this.service.single(params.get('todo')).subscribe(todo => {
          this.dialog.open(TodoDetailDialogComponent, {
            width: '700px',
            data: { todo }
          }).afterClosed().subscribe(mutated => {
            if (mutated) {
              this.fetchTodos.next(this.todoCriteria);
              this.fetchAssigned.next(this.assignedCriteria);
            }
          });
        });
      }
    });
  }

  ngOnInit() {
    // since this is the 'home page', the menu should be expanded to make further navigation easy
    this.bus.publish(this.events.ui.requested.sidenav.expand);

    this.bus.subscribe(this.events.notified.todos.update, this.refetch, this);

    this.subs.add(
      this.fetchTodos.pipe(
        tap(criteria => this.todoCriteria = criteria),
        debounceTime(0),
        switchMap(criteria => this.service.get(criteria)),
        this.service.polish,
      ).subscribe(todos => this.todos = todos as Todo[])
    );

    this.subs.add(
      this.fetchAssigned.pipe(
        tap(criteria => this.assignedCriteria = criteria),
        debounceTime(0),
        switchMap(criteria => this.service.getAssigned(criteria)),
        this.service.polish,
      ).subscribe(assigned => this.assigned = assigned as Todo[])
    );

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.resetViews.next();
    });
  }

  ngOnDestroy() {
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
    this.bus.unsubscribe(this.events.notified.todos.update, this.refetch);
    this.subs.unsubscribe();
  }

  refetch() {
    this.fetchTodos.next(this.todoCriteria);
    this.fetchAssigned.next(this.assignedCriteria);
  }

  new() {
    const ref = this.dialog.open(TodoDialogComponent, {
      width: '700px',
    });

    ref.afterClosed().subscribe(update => {
      if (update) {
        this.refetch();
      }
    });
  }

  newPa() {
    const ref = this.dialog.open(CustomPaDialogComponent, {
      width: '700px',
    });
  }
}
