import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BusService } from "../bus.service";
import {
  IPostOptions,
  RequestService
} from "../request.service";
import { EventsService } from "../events.service";

interface IPostEmailAuthRequest {
  token: string;
}

interface IPostEmailAuthResponse {
  _id: string;
  token: string;
  firstName: string;
  lastName: string;
  intercomHash: string;
  email: string;
  productType: string;
}

@Injectable()
export class EmailAuthService {
  private options: IPostOptions<IPostEmailAuthRequest,
                              IPostEmailAuthResponse>;
  private next: string = null;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: "/account/email-auth",
      body: {
        token: "",
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: IPostEmailAuthResponse) {
    localStorage.setItem('currentUser', data.token);
    localStorage.setItem('currentUserID', data._id);

    this.bus.publish(this.events.received.authentication.success, {
      token: data.token,
      _id: data._id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      intercomHash: data.intercomHash,
      next: this.next,
      productType: data.productType
    });
  }

  public error(httpErrorResponse: HttpErrorResponse) {
    this.bus.publish(this.events.received.authentication.failure, httpErrorResponse);
  }

  public request(data: any) {
    this.bus.publish(this.events.notified.request.starting);
    this.options.body.token = data.token;

    this.next = data.next;

    this.requestService.post<IPostEmailAuthRequest, IPostEmailAuthResponse>(this.options);
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.requested.data.emailAuthentication, this.request.bind(this));
  }
}
