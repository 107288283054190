import { FormBuilder,
          FormControl,
          FormGroup,
          Validators,
          AbstractControl } from '@angular/forms';
import ForgotPasswordModel from './forgot-password.model';

export default class ForgotPasswordForm {
  private formBuilder: FormBuilder;
  public formGroup: FormGroup;
  public model: ForgotPasswordModel;

  constructor(model: ForgotPasswordModel) {
    this.model = model;
    this.formBuilder = new FormBuilder();
    this.createForm();
  }

  public createForm(): void {
    this.formGroup = this.formBuilder.group({
      email: new FormControl(this.model.email, [Validators.required, Validators.email]),
    });
    this.formGroup.valueChanges.subscribe(data=>{
      this.model.email = data.email;
    });
  }

  public patchForm(data: any): void {
    this.formGroup.patchValue(data);
    if (data.email)
      this.model.email = data.email;
  }

  public getControl(name: string): AbstractControl {
    return this.formGroup.get(name);
  }

  get isValid(): boolean {
    return this.formGroup.valid;
  }

  get formData(): ForgotPasswordModel {
    return this.formGroup.getRawValue();
  }

  public validate(): void {
    this.validateFormFields(this.formGroup);
  }

  public validateFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateFormFields(control);
      }
    });
  }
}
