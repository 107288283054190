import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { BusService } from '../../services/bus.service';


@Component({
  selector: 'footer-component',
  templateUrl: './footer.template.html',
  styleUrls: ['./footer.styles.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  pushed: boolean = false;

  constructor(
    private events : EventsService,
    private bus : BusService,
  ) { }

  push() { this.pushed = true; }
  pull() { this.pushed = false; }

  public ngOnInit(): void {
    this.bus.subscribe(this.events.ui.occured.sidenav.expand, this.push, this);
    this.bus.subscribe(this.events.ui.occured.sidenav.collapse, this.pull, this);
  }

  public ngOnDestroy(): void {
    this.bus.unsubscribe(this.events.ui.occured.sidenav.expand, this.push);
    this.bus.unsubscribe(this.events.ui.occured.sidenav.collapse, this.pull);
  }

}
