import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { SupervisionClientInvitationService } from 'app/services/supervision/client-invitation.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-confirm-supervision-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class ConfirmSupervisionInvitationComponent implements OnInit, OnDestroy {

  inviteToken: string = null;
  supervision: any;
  isSupervised: boolean;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private clientInvitationService: SupervisionClientInvitationService,
    private translate: TranslateService
  ) {
    this.route.queryParams.subscribe(params => {
      this.inviteToken = params.clientInvite;
      this.bus.publish(this.events.requested.data.supervision.clientInvitation.request, this.inviteToken);
      this.bus.publish(this.events.requested.data.supervision.clientInvitation.info);
    });
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  updateSupervision(response) {
    this.supervision = response;
  }

  get supervisor() {
    return this.supervision.supervisor;
  }

  get isSameUser() {
    return parseInt(localStorage.getItem('currentUserID'), 10) === this.supervision.supervisorId;
  }

  confirm() {
    if (this.supervision.isAdmin) {
      this.bus.publish(this.events.requested.action.supervision.clientInvitation.accept, { token: this.inviteToken });
    }
  }

  onConfirmed() {
    this.router.navigate(['']);
  }

  infoSuccess() {
    this.isSupervised = true;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.supervision.clientInvitation.request.success, this.updateSupervision, this);
    this.bus.subscribe(this.events.received.action.supervision.clientInvitation.accept.success, this.onConfirmed, this);
    this.bus.subscribe(this.events.received.data.supervision.clientInvitation.info.success, this.infoSuccess, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.supervision.clientInvitation.request.success, this.updateSupervision);
    this.bus.unsubscribe(this.events.received.action.supervision.clientInvitation.accept.success, this.onConfirmed);
    this.bus.unsubscribe(this.events.received.data.supervision.clientInvitation.info.success, this.infoSuccess);
  }
}
