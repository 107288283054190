import { Component, OnInit, OnDestroy } from '@angular/core';

import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { TomsCategory } from '../../services/toms/toms.service';
import { Router } from '../../../../node_modules/@angular/router';


@Component({
  selector: 'app-toms',
  templateUrl: './toms.component.html',
  styleUrls: ['./toms.component.scss']
})
export class TomsComponent implements OnInit, OnDestroy {

  cats: TomsCategory[];

  constructor(
    private bus: BusService,
    private events: EventsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.toms.categories);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  update(cats) {
    this.cats = cats;
  }

  get allFinished() {
    if (this.cats) {
      const unfinished = this.cats.filter(cat => cat.progress !== 1);
      if (unfinished.length === 0) {
        return true;
      }
    }

    return false;
  }

  public progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_toms',
    });
  }

  continue() {
    this.progress(1);
    this.router.navigate(['']);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.toms.categories.success, this.update, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.toms.categories.success, this.update);
  }
}
