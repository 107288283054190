import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { EventsService } from '../../../services/events.service';
import { BusService } from '../../../services/bus.service';


@Component({
  selector: 'app-offer-dpa',
  templateUrl: './offer-dpa.component.html',
  styleUrls: ['./offer-dpa.component.scss']
})
export class OfferDpaComponent implements OnInit, OnDestroy {

  private dpaToken : string;
  badToken : boolean = false;

  vendor: any;
  controller: any;

  constructor(
    private events: EventsService,
    private bus: BusService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.subscribe();

    this.activatedRoute.queryParams.subscribe((params : Params)=> {
      this.dpaToken = params['dpa-token'] || '';
      this.bus.publish(this.events.requested.data.vendors.decodeDPAToken, this.dpaToken);
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  tokenDecoded(response) {
    this.vendor = response.vendor;
    this.controller = response.controller;
  }

  tokenDecodingFailed(error) {
    this.badToken = true;
  }

  public get docs() {
    return `assets/documents/${localStorage['lang'] || 'en'}`;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.vendors.decodeDPAToken.success, this.tokenDecoded, this);
    this.bus.subscribe(this.events.received.data.vendors.decodeDPAToken.failure, this.tokenDecodingFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.vendors.decodeDPAToken.success, this.tokenDecoded);
    this.bus.unsubscribe(this.events.received.data.vendors.decodeDPAToken.failure, this.tokenDecodingFailed);
  }
}
