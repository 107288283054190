import { ControllerType, SettingsType, Template } from './types';

/*
 *
 * various events that might happen during templating processes.
 *
 */

export abstract class TemplatingEvent {
  constructor(public controller: ControllerType){}
}

export abstract class CancellationEvent extends TemplatingEvent {}
export abstract class TemplateAbstractionEvent extends TemplatingEvent {}
export abstract class TemplateApplicationEvent extends TemplatingEvent {}

/** template creating/updating events **/

export class CreationCanceledEvent extends CancellationEvent {}

export class CreatingTemplateEvent extends TemplateAbstractionEvent {
  constructor(
    controller: ControllerType,
    public templateName: string,
    public settings: SettingsType,
  ) {
    super(controller);
  }
}

export class UpdatingTemplateEvent extends TemplateAbstractionEvent {
  constructor(
    controller: ControllerType,
    public template: Template,
    public newTemplateName: string,
    public settings: SettingsType,
  ) {
    super(controller);
  }
}

export class TemplateCreatedEvent extends TemplateAbstractionEvent {
  constructor(
    public templateId: number,
    public initiationEvent: CreatingTemplateEvent,
  ) {
    super(initiationEvent.controller);
  }
}

export class TemplateUpdatedEvent extends TemplateAbstractionEvent {
  constructor(
    public initiationEvent: UpdatingTemplateEvent,
  ) {
    super(initiationEvent.controller);
  }
}

/** template application events **/

export class ApplicationCanceledEvent extends CancellationEvent {
  constructor(controller: ControllerType) { super(controller); }
}

export class ApplyingTemplateEvent extends TemplateApplicationEvent {
  constructor(
    controller: ControllerType,
    public template: Template,
    public settings: SettingsType,
  ) {
    super(controller);
  }
}

export class TemplateAppliedEvent extends TemplateApplicationEvent {
  constructor(
    public initiationEvent: ApplyingTemplateEvent,
  ) {
    super(initiationEvent.controller);
  }
}
