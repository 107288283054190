import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { share } from 'rxjs/operators';

import { AppConfig } from '../app.config';

export interface IHandlers<TResponseBody> {
  success(data: TResponseBody);
  error(httpErrorResponse: HttpErrorResponse);
}

export interface IGetOptions<TResponseBody> {
  uri: string;
  parameters?: HttpParams;
  handlers: IHandlers<TResponseBody>;
  headers?: HttpHeaders;
}

export interface IPutOptions<TRequestBody, TResponseBody> {
  uri: string;
  parameters?: HttpParams;
  body: TRequestBody;
  handlers?: IHandlers<TResponseBody>;
}

export interface IPatchOptions<TRequestBody, TResponseBody> {
  uri: string;
  parameters?: HttpParams;
  body: TRequestBody;
  handlers?: IHandlers<TResponseBody>;
}

export interface IPostOptions<TRequestBody, TResponseBody> {
  uri: string;
  parameters?: HttpParams;
  body: TRequestBody;
  handlers?: IHandlers<TResponseBody>;
}

export interface IDeleteOptions<TResponseBody> {
  uri: string;
  parameters?: HttpParams;
  handlers?: IHandlers<TResponseBody>;
}

@Injectable()
export class RequestService {

private apiUrl: string = AppConfig.apiUrl;

constructor(private httpClient: HttpClient) {}

  post<TRequestBody, TResponseBody>(options: IPostOptions<TRequestBody, TResponseBody>) {
    const observable = this.httpClient
      .post<TResponseBody>(this.apiUrl + options.uri, options.body).pipe(share());
    observable.subscribe(options.handlers.success, options.handlers.error);
    return observable;
  }

  get<TResponseBody>(options: IGetOptions<TResponseBody>) {
    const observable = this.httpClient
      .get<TResponseBody>(this.apiUrl + options.uri, {params: options.parameters, headers: options.headers}).pipe(share());
    observable.subscribe(options.handlers.success, options.handlers.error);
    return observable.pipe(share());;
  }

  put<TRequestBody, TResponseBody>(options: IPutOptions<TRequestBody, TResponseBody>) {
    const observable = this.httpClient
      .put<TResponseBody>( this.apiUrl + options.uri, options.body).pipe(share());
    observable.subscribe(options.handlers.success, options.handlers.error);
    return observable;
  }

  // When updating an entity, we mostly want to use PATCH instead of PUT
  // See: https://stackoverflow.com/questions/28459418/rest-api-put-vs-patch-with-real-life-examples
  patch<TRequestBody, TResponseBody>(options: IPatchOptions<TRequestBody, TResponseBody>) {
    const observable = this.httpClient
      .patch<TResponseBody>( this.apiUrl + options.uri, options.body).pipe(share());
    observable.subscribe(options.handlers.success, options.handlers.error);
    return observable;
  }

  delete<TResponseBody>(options: IDeleteOptions<TResponseBody>) {
    const observable = this.httpClient
      .delete<TResponseBody>(this.apiUrl + options.uri, {params: options.parameters}).pipe(share());
    observable.subscribe(options.handlers.success, options.handlers.error);
    return observable;
  }
}
