import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from 'app/services/events.service';
import { BusService } from 'app/services/bus.service';
import { MatDialog } from '@angular/material';
import { AddClientDialogComponent } from '../add-client-dialog/add-client-dialog.component';
import { ClientManagementService } from 'app/services/supervision/client-management.service';
import { DeleteClientDialogComponent } from '../delete-client-dialog/delete-client-dialog.component';
import { AuthenticationService } from '../../../services/authentication.service';
import { SupervisionClientTokenService } from '../../../services/supervision/client-token.service';
import { ClientSupervisionService } from '../../../services/supervision/client-supervision.service';
import { SupervisionTemplatesService } from '../../../services/supervision/templates.service';
import { AppConfig } from '../../../app.config';
import { DialogComponent } from '../../dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'supervision-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class SupervisionClientListComponent implements OnInit, OnDestroy {
  appConfig = AppConfig;

  acceptedSupervisions = [];
  pendingSupervisions = [];

  loadingPending = true;
  loadingAccepted = true;

  controllerId: number = undefined;
  filter: string = undefined;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private clientService: ClientManagementService,
    private authService: AuthenticationService,
    private clientToken: SupervisionClientTokenService,
    private supervision: ClientSupervisionService,
    private templates: SupervisionTemplatesService,
    private translate: TranslateService
  ) {

  }

  private ordered(clientList) {
    return clientList.sort((a, b) => {
      const controllerNameA = a.controllerName.toLowerCase(),
            controllerNameB = b.controllerName.toLowerCase();

      if (controllerNameA < controllerNameB) {
        return -1;
      }

      if (controllerNameA > controllerNameB) {
        return 1;
      }

      return 0;
     });
  }

  private filtered(clientList) {
    if (this.filter) {
      const match = key => key.toLowerCase().indexOf(this.filter.toLowerCase()) != -1;
      return clientList.filter(client => {
        if (client.controllerName && match(client.controllerName)) return true;
        if (client.email && match(client.email)) return true;
        if (client.controllerContactName && match(client.controllerContactName)) return true;
        if (client.controllerEmailAddress && match(client.controllerEmailAddress)) return true;

        return false;
      });
    }
    else return clientList;
  }

  get clients() {
    if (this.acceptedSupervisions)
      return this.filtered(this.ordered(this.acceptedSupervisions));
    else
      return [];
  }

  get pendingClients() {
    if (this.pendingSupervisions)
      return this.filtered(this.pendingSupervisions);
    else return [];
  }

  public isEmpty(list: Array<any>) {
    return list.length === 0;
  }

  public supervise(client) {
    this.clientToken.getClientToken(client.id).subscribe(response => {
      if (response.token)
        this.supervision.startSupervision(response.token);
    });
  }

  public addClient() {
    this.dialog.open(AddClientDialogComponent, {
      width: '700px',
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        this.bus.publish(this.events.requested.data.supervision.clients.accepted);
        this.bus.publish(this.events.requested.data.supervision.clients.pending);
      }
    });
  }

  public applyTemplate(client) {
    this.templates.applyTemplate(client);
  }

  public createTemplate(client) {
    this.templates.createTemplate(client);
  }

  public deleteClient(client, isAccepted: boolean, event) {
    if (event) event.stopPropagation();

    this.dialog.open(DeleteClientDialogComponent, {
      width: '350px',
      data: {
        client: client
      }
    }).afterClosed().subscribe(mutated => {
      if (mutated) {
        if (isAccepted) {
          this.bus.publish(this.events.requested.data.supervision.clients.accepted);
        } else {
          this.bus.publish(this.events.requested.data.supervision.clients.pending);
        }
      }
    });
  }

  public resendInvitation(client) {
    this.bus.publish(this.events.requested.action.supervision.clientInvitation.resend, client.id);
  }

  public async invitationResent() {
    const header = await this.translate.get('supervision.clientList.client.resendInvitation.dialog.header').toPromise();
    const message = await this.translate.get('supervision.clientList.client.resendInvitation.dialog.message').toPromise();

    this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        header,
        message
      }
    });
  }

  updatePendingSupervisions(supervisions) {
    this.pendingSupervisions = supervisions;
    this.loadingPending = false;
  }

  updateAcceptedSupervisions(supervisions) {
    this.acceptedSupervisions = supervisions;
    this.loadingAccepted = false;
  }

  private setControllerId() {
    this.controllerId = parseInt(localStorage.getItem('currentUserControllerId'), 10);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.supervision.clients.pending.success, this.updatePendingSupervisions, this);
    this.bus.subscribe(this.events.received.data.supervision.clients.accepted.success, this.updateAcceptedSupervisions, this);
    this.bus.subscribe(this.events.received.action.supervision.clientInvitation.resend.success, this.invitationResent, this);
  }

  public ngOnInit(): void {
    this.subscribe();
    this.setControllerId();
    this.bus.publish(this.events.requested.data.supervision.clients.pending);
    this.bus.publish(this.events.requested.data.supervision.clients.accepted);
  }

  public ngOnDestroy(): void {
  }

}
