import { Component, OnInit } from '@angular/core';
import { TrainingService } from 'app/services/training/training.service';

@Component({
  selector: 'app-training-stats',
  templateUrl: './training-stats.component.html',
  styleUrls: ['./training-stats.component.scss']
})
export class TrainingStatsComponent implements OnInit {

  numOfEmployeesWithATrainingThisYear = 0;
  numOfTrainingsThisYear = 0;
  percentageOfEmployeesWithoutTrainingThisYear = 0;

  constructor(
    private service: TrainingService,
  ) { }

  ngOnInit() {
    this.service.statistics.numOfEmployeesWithATrainingThisYear()
      .subscribe(s => this.numOfEmployeesWithATrainingThisYear = s.stat);

    this.service.statistics.numOfTrainingsThisYear()
    .subscribe(s => this.numOfTrainingsThisYear = s.stat);

    this.service.statistics.percentageOfEmployeesWithoutTrainingThisYear()
    .subscribe(s => this.percentageOfEmployeesWithoutTrainingThisYear = s.stat);
  }

}
