import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BusService } from "../bus.service";
import {
  IGetOptions,
  RequestService
} from "../request.service";
import { EventsService } from "../events.service";

interface IForm {
  _id: number,
  paName: string,
}

interface IGetResponse {
  PAs: Array<IForm>
}

/*
  An interlayer service for rpa get all request between request service and rpa component
*/
@Injectable()
export class RpaGetAllService {

  private options: IGetOptions<IGetResponse>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: "/pa",
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: IGetResponse): void {
    this.bus.publish(this.events.received.data.rpa.getAll.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.data.rpa.getAll.failure, httpErrorResponse);
  }

  private request(id: string) {
    return this.requestService.get<IGetResponse>(this.options);
  }

  public all() {
    return this.request(undefined);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.rpa.getAll, this.request.bind(this));
  }
}
