import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

import RegistrationModel from './registration.model';
import RegistrationForm from './registration.form';
import {BusService} from '../../services/bus.service';
import {EventsService} from '../../services/events.service';
import {SupervisionClientInvitationService} from '../../services/supervision/client-invitation.service';

@Component({
  selector: 'registration-component',
  templateUrl: './registration.template.html',
  styleUrls: ['./registration.styles.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

  private model: RegistrationModel;
  private form: RegistrationForm;
  private loading = false;
  public isFormErrorMessage: boolean = false;

  public state: string = 'initial';
  private email: string = null;
  private resending: boolean = false;

  public inviteToken: string = null;
  public inviteData: any = null;

  public clientInviteToken: string = null;
  public clientInviteSupervisor: any = null;

  constructor(
    private router: Router,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private clientInvitationService: SupervisionClientInvitationService
  ) {
    this.model = new RegistrationModel();
    this.form = new RegistrationForm(this.model);

    route.queryParams.subscribe(params => {
      if (params.invite) {
        this.inviteToken = params.invite;
        this.bus.publish(this.events.requested.data.controller.decodeInviteToken, this.inviteToken);
      }
      if (params.clientInvite) {
        this.clientInviteToken = params.clientInvite;
        this.bus.publish(this.events.requested.data.supervision.clientInvitation.supervisor, this.clientInviteToken);
      }
    });
  }

  private invalidForm(): void {
    this.form.validate();
    this.isFormErrorMessage = true;
  }

  private submit(): void {
    this.bus.publish(this.events.notified.validation.form.aggregation, this.form.isValid);
    this.bus.publish(this.events.notified.validation.form.action);
  }

  private registration(): void {
    if (this.inviteToken) {
      this.model.inviteToken = this.inviteToken;
    }
    if (this.clientInviteToken) {
      this.model.clientInviteToken = this.clientInviteToken;
    }

    this.bus.publish(this.events.requested.data.registration, this.model);
  }

  private failure(error): void {
    let reason = 'bad-data';
    if (error.status === 500) {
      reason = 'internal';
    }
    if (error.status === 403) {
      reason = 'free-email';
    }

    this.dialog.open(RegistrationDialogComponent,
      {width: '350px', data: {'reason': reason}});
  }

  private success(result): void {
    if (this.inviteData)
      this.email = this.inviteData.invite.email;
    else
      this.email = result.email;

    this.state = 'success';

    // firstpromoter.com signup tracking
    window['$FPROM'].trackSignup({
        email: this.email
      },
    );
  }

  public resendEmail(): void {
    if (this.email && !this.resending) {
      this.bus.publish(this.events.requested.action.resendVerificationEmail, {email: this.email});
      this.resending = true;
      setTimeout(() => {
        this.resending = false;
      }, 1000);
    }
  }

  updateInvite(response) {
    this.inviteData = response;

    this.translate.get('registration.invited.heardAboutUsHow').subscribe(msg => {
      this.form.patchForm({
        email: response.invite.email,
        firstName: response.invite.firstName,
        lastName: response.invite.lastName,
        company: response.controller.controllerName,
        heardAboutUsHow: `${msg} ${response.inviter.first_name} ${response.inviter.last_name} (${response.inviter.email})`,
      });
    });
  }

  updateSupervisor(supervision) {
    this.clientInviteSupervisor = supervision.supervisor;

    this.translate.get('registration.invited.heardAboutUsHow').subscribe(msg => {
      this.form.patchForm({
        heardAboutUsHow: `${msg} ${supervision.supervisor.name} (${supervision.supervisor.email})`,
      });
    });
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.received.validation.failure, this.invalidForm, this);
    this.bus.subscribe(this.events.received.validation.success, this.registration, this);
    this.bus.subscribe(this.events.received.registration.failure, this.failure, this);
    this.bus.subscribe(this.events.received.registration.success, this.success, this);
    this.bus.subscribe(this.events.received.data.controller.decodeInviteToken.success, this.updateInvite, this);
    this.bus.subscribe(this.events.received.data.supervision.clientInvitation.supervisor.success, this.updateSupervisor, this);
  }

  public unSubscribe(): void {
    this.bus.unsubscribe(this.events.received.validation.failure, this.invalidForm);
    this.bus.unsubscribe(this.events.received.validation.success, this.registration);
    this.bus.unsubscribe(this.events.received.registration.failure, this.failure);
    this.bus.unsubscribe(this.events.received.registration.success, this.success);
    this.bus.unsubscribe(this.events.received.data.controller.decodeInviteToken.success, this.updateInvite);
    this.bus.unsubscribe(this.events.received.data.supervision.clientInvitation.supervisor.success, this.updateSupervisor);
  }

  public ngOnInit(): void {
    this.subscribe();
    this.bus.publish(this.events.ui.requested.sidenav.expand);
  }

  public ngOnDestroy(): void {
    this.unSubscribe();
    this.bus.publish(this.events.ui.requested.sidenav.collapse);
  }
}

@Component({
  selector: 'registration-dialog',
  templateUrl: './registration.dialog.template.html'
})
export class RegistrationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RegistrationDialogComponent>,
  ) {
  }
}
