import { Injectable } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";
import { BusService } from "../bus.service";
import {
  IPostOptions,
  RequestService
} from "../request.service";
import { EventsService } from "../events.service";
interface IPostCredentialsRequest {
}

interface IPostCredentialsResponse {}

@Injectable()
export class EmailCheckService {

  private options: IPostOptions<IPostCredentialsRequest, IPostCredentialsResponse>;

  constructor(private bus: BusService,
              private events: EventsService,
              private requestService: RequestService) {
    this.options = {
      uri: '/account/email-check',
      body: {},
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: IPostCredentialsResponse): void {
    // console.log('this.events.requested.action.checkEmail success');
    this.bus.publish(this.events.received.action.checkEmail.success, data);
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    // console.log('this.events.requested.action.checkEmail failure');
    this.bus.publish(this.events.received.action.checkEmail.failure, httpErrorResponse);
  }

  public request(payload): void {
    // console.log('sending check email request');
    this.options.uri = `/account/email-check`;
    this.options.body = payload;
    this.requestService.post<IPostCredentialsRequest, IPostCredentialsResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.action.checkEmail, this.request.bind(this));
  }

}
