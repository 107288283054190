import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

import { EventsService } from '../../../services/events.service';
import { BusService } from '../../../services/bus.service';

import { AuthenticationService } from '../../../services/authentication.service';

import { VendorSearchDialogComponent } from './vendor-search-dialog/vendor-search-dialog.component';
import { CustomPaDialogComponent } from '../custom-pa-dialog/custom-pa-dialog.component';


@Component({
  selector: 'app-pa-external-processors',
  templateUrl: './pa-external-processors.component.html',
  styleUrls: ['./pa-external-processors.component.scss']
})
export class PaExternalProcessorsComponent implements OnInit, OnDestroy {

  processingActivities = [];
  vendors = [];
  associations = [];
  selectedPa = undefined;
  showOther = false;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private dialog: MatDialog,
    private auth: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.user.info);
    this.bus.publish(this.events.requested.data.rpa.externallyProcessed.pas);
    this.bus.publish(this.events.requested.data.vendors.get);
    this.bus.publish(this.events.requested.data.rpa.externallyProcessed.vendors);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  get resolvedCount(): number {
    return this.processingActivities.filter(pa => this.resolved(pa)).length;
  }

  get done(): boolean {
    return this.resolvedCount === this.processingActivities.length;
  }

  updateProcessingActivities(data) {
    this.processingActivities = data || [];
  }

  updateAssociations(data) {
    this.associations = data || [];
  }

  updateVendors(data) {
    this.vendors = data;
  }

  public get canWrite() {
    return true;
  }

  canChange(pa) {
    return true;
  }

  resolved(pa) {
    return this.associations.some(entry => entry.paId === pa.paId);
  }

  associatedVendors(pa) {
    const associatedVendorIds = this.associations
          .filter(entry => entry.paId === pa.paId)
          .map(entry => entry.vendorId);
    return this.vendors.filter(vendor => associatedVendorIds.includes(vendor.id));
  }

  addVendor() {
    if (this.canChange(this.selectedPa)) {
      this.dialog.open(VendorSearchDialogComponent, {
        width: '512px',
      }).afterClosed().subscribe(vendor => {
        if (vendor) {
          if (vendor.added) {
            this.vendors.push(vendor);
          }

          this.bus.publish(this.events.requested.data.rpa.externallyProcessed.associate, {
            paId: this.selectedPa.paId,
            vendorId: vendor.id,
          });
        }
      });
    }
  }

  removeVendor(vendor) {
    if (this.canChange(this.selectedPa)) {
      this.bus.publish(this.events.requested.data.rpa.externallyProcessed.disassociate, {
        paId: this.selectedPa.paId,
        vendorId: vendor.id,
      });
    }
  }

  associated(event) {
    this.associations.push({ paId: event.paId, vendorId: event.vendorId });
  }

  disassociated(event) {
    this.associations =
      this.associations
        .filter(entry => entry.paId !== event.paId || entry.vendorId !== event.vendorId);
  }

  bypass() {
    if (this.processingActivities.length === 0) {
      this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
        progress: 1,
        workflowCodename: 'path_to_gdpr',
        taskCodename: 'ptg_pa_external_processors',
      });
    }
  }

  bypassed() {
    this.router.navigate(['']);
  }

  finish() {
    this.router.navigate(['']);
  }

  editCustomPa(paId) {
    const ref = this.dialog.open(CustomPaDialogComponent, {
      width: '700px',
      data: {
        id: paId
      }
    });
    ref.afterClosed().subscribe((update: boolean) => {
      if (update) {
        // do something
      }
    })
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.vendors.get.success, this.updateVendors, this);
    this.bus.subscribe(this.events.received.data.rpa.externallyProcessed.pas.success, this.updateProcessingActivities, this);
    this.bus.subscribe(this.events.received.data.rpa.externallyProcessed.vendors.success, this.updateAssociations, this);
    this.bus.subscribe(this.events.received.data.rpa.externallyProcessed.associate.success, this.associated, this);
    this.bus.subscribe(this.events.received.data.rpa.externallyProcessed.disassociate.success, this.disassociated, this);

    this.bus.subscribe(this.events.received.action.tasks.progressControllerTask.success, this.bypassed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.vendors.get.success, this.updateVendors);
    this.bus.unsubscribe(this.events.received.data.rpa.externallyProcessed.pas.success, this.updateProcessingActivities);
    this.bus.unsubscribe(this.events.received.data.rpa.externallyProcessed.vendors.success, this.updateAssociations);
    this.bus.unsubscribe(this.events.received.data.rpa.externallyProcessed.associate.success, this.associated);
    this.bus.unsubscribe(this.events.received.data.rpa.externallyProcessed.disassociate.success, this.disassociated);

    this.bus.unsubscribe(this.events.received.action.tasks.progressControllerTask.success, this.bypassed);
  }
}
