import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from 'app/services/events.service';
import { BusService } from 'app/services/bus.service';
import { MatDialog } from '@angular/material';
import { AppConfig } from '../../../app.config';
import { DocumentsUploadDialogComponent } from '../modals/upload/documents-upload-dialog.component';
import { DocumentResourceService } from '../../../services/documents/document-resource.service';
import { UserService } from '../../../services/user/user.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { DeleteDocumentDialogComponent } from '../modals/delete/delete-document-dialog.component';
import { DocumentTaglistDialogComponent } from '../modals/taglist/document-taglist-dialog.component';


@Component({
  selector: 'app-documents-dashboard',
  templateUrl: './documents-dashboard.component.html',
  styleUrls: ['./documents-dashboard.component.scss']
})
export class DocumentsDashboardComponent implements OnInit, OnDestroy {

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private docService: DocumentResourceService,
    private userService: UserManagementService,
  ) {

  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

}
