import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { RpaInternallyProcessedService } from 'app/services/rpa/rpa.internally-processed.service';
import { DepartmentService } from 'app/services/departments/department.service';
import { Observable, zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-internal-processing',
  templateUrl: './internal-processing.component.html',
  styleUrls: ['./internal-processing.component.scss']
})
export class InternalProcessingComponent implements OnInit {
  @Input() paId: number;
  @Input() placeholder: string;
  @Input() hint: string;
  @Input() mainDepartment: number;
  @Input() readonly = false;
  @Input() departmentAssociations = [];
  @Output() departmentAssociationsChange = new EventEmitter<any[]>();
  @Output() notifyOtherDepartmentSelected = new EventEmitter<boolean>();

  @ViewChild('select', { static: true }) select: MatSelect;

  constructor(
    private internallyProcessed: RpaInternallyProcessedService,
    private departmentsService: DepartmentService,
  ) { }

  departments = [];
  associated = [];

  get responsibleDepartment() {
    return this.departments.find(dep => dep.id === this.mainDepartment);
  }

  get nonResponsibleDepartments() {
    return this.departments.filter(dep => dep.id !== this.mainDepartment);
  }

  associate(department) {
    if (!this.readonly) {
      this.internallyProcessed.associate({
        paId: this.paId,
        departmentId: department.id,
      }).subscribe(() => {
        if (!this.isAssociated(department)) {
          this.departmentAssociations = this.departmentAssociations.concat([{
            paId: this.paId,
            departmentId: department.id,
          }]);
        }

        this.departmentAssociationsChange.emit(this.departmentAssociations);
        this.notifyIfOtherDepartment();
      });
    }
  }

  disassociate(department) {
    if (!this.readonly) {
      this.internallyProcessed.disassociate({
        paId: this.paId,
        departmentId: department.id,
      }).subscribe(() => {
        this.departmentAssociations =
          this.departmentAssociations
            .filter(entry => entry.paId !== this.paId || entry.departmentId !== department.id);

        this.departmentAssociationsChange.emit(this.departmentAssociations);
        this.notifyIfOtherDepartment();
      });
    }
  }

  isAssociated(department) {
    return this.departmentAssociations
      .some(entry => entry.paId === this.paId && entry.departmentId === department.id);
  }

  updateAssociations() {
    this.nonResponsibleDepartments.forEach(dep => {
      if (this.isAssociated(dep) && !this.associated.includes(dep)) {
        this.disassociate(dep);
      }

      if (!this.isAssociated(dep) && this.associated.includes(dep)) {
        this.associate(dep);
      }
    });
  }

  notifyIfOtherDepartment() {
    const otherDepartment = this.departments.find((d) => d.directoryId === 'TU00000');
    
    if (!otherDepartment)
      return;
    
    const other = this.departmentAssociations.find((d) => d.departmentId === otherDepartment.id);
    
    console.log(other);

    if (other) {
      return this.notifyOtherDepartmentSelected.emit(true);
    }

    return this.notifyOtherDepartmentSelected.emit(false);
  }

  ngOnInit() {
    zip(
      this.departmentsService.requestGetAll().pipe(tap(response => {
        this.departments = response;
      })),

      this.internallyProcessed.paAssociations(this.paId).pipe(tap(response => {
        this.departmentAssociations = response;

        this.departmentAssociationsChange.emit(this.departmentAssociations);
        this.notifyIfOtherDepartment();
      })),
    ).subscribe(() => {
      this.associated = this.nonResponsibleDepartments.filter(d => this.isAssociated(d));
    });

    this.select.disabled = this.readonly;
    
  }

}
