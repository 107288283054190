import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

import { AddClientEmailComponent } from '../add-client/email/add-client-email.component';
import { AddClientControllerComponent } from '../add-client/controller/add-client-controller.component';


@Component({
  selector: 'app-add-client-dialog',
  templateUrl: './add-client-dialog.component.html',
  styleUrls: ['./add-client-dialog.component.scss']
})
export class AddClientDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AddClientDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {}

  public close() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  addController() {
    this.dialog.open(AddClientControllerComponent, {
      width: '350px'
    }).afterClosed().subscribe(created => {
      if (created) this.dialogRef.close(true);
    });
  }

  addByEmail() {
    this.dialog.open(AddClientEmailComponent, {
      width: '350px'
    }).afterClosed().subscribe(invited => {
      if (invited) this.dialogRef.close(true);
    });
  }
}
