import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UserInfoDialogComponent } from '../user-info-dialog/user-info-dialog.component';


export interface User {
  firstName: string;
  lastName: string;
  email: string;
  username?: string;
  directoryId?: string;
}


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'user-inline-display',
  templateUrl: './inline-display.component.html',
  styleUrls: ['./inline-display.component.scss']
})
export class UserInlineDisplayComponent implements OnInit {

  @Input() user: User;
  @Input() showInfo = false;
  @Input() todoSubject: string | undefined = undefined;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  openInfoDialog(event) {
    if (this.showInfo) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }

      this.dialog.open(UserInfoDialogComponent, {
        width: '512px',
        data: {
          user: this.user,
          todoSubject: this.todoSubject,
        },
      });
    }
  }
}
