import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { AppConfig } from '../../../app.config';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';


@Component({
  selector: 'app-setup-dpms',
  templateUrl: './setup-dpms.component.html',
  styleUrls: ['./setup-dpms.component.scss']
})
export class SetupDpmsComponent implements OnInit, OnDestroy {

  public filePicked: boolean = false;
  public uploading: boolean = false;
  public uploadProgress: number = 0;
  accessLevel = undefined;

  @ViewChild('upload', { static: false }) upload;
  companyName = new FormControl('', [Validators.required, Validators.minLength(5)]);

  constructor(
    private bus: BusService,
    private events: EventsService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.controller.name);
    this.bus.publish(this.events.requested.data.controller.hasLogo);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  logoUpdated() {
    this.bus.publish(this.events.notified.user.logoUpdate);
  }

  public submit() {
    if (this.companyName.valid) {
      // EE-1755: We don't need to update the controller name in case it hasn't changed.
      if (this.companyName.dirty) {
        this.bus.publish(this.events.requested.data.controller.updateName, {
          controllerName: this.companyName.value,
        });
      }

      this.router.navigate(['']);
    }
  }

  public updateCompanyName(data) {
    if (data.controllerName) {
      this.companyName.setValue(data.controllerName);
    }
  }

  public updateCompanyLogo(data) {
    if (data.hasLogo) {
      this.upload.load(
        `${AppConfig.apiUrl}/controllers/logo/` +
        `?token=${localStorage.getItem('currentUser')}`);
    }
  }

  public progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_setup_dpms',
    });
  }

  public get canWrite() {
    return true;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.controller.name.success, this.updateCompanyName, this);
    this.bus.subscribe(this.events.received.data.controller.hasLogo.success, this.updateCompanyLogo, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.controller.name.success, this.updateCompanyName);
    this.bus.unsubscribe(this.events.received.data.controller.hasLogo.success, this.updateCompanyLogo);
  }
}
