import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-policy-de',
  templateUrl: './cookie-policy-de.component.html',
  styleUrls: ['./cookie-policy-de.component.scss']
})
export class CookiePolicyDeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
