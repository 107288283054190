import { Injectable } from "@angular/core";
import { BusService } from "./bus.service";
import { Router } from "@angular/router";
import { EventsService } from "./events.service";
/*
  Validation mechanizm for all forms
*/
@Injectable()
export class ValidationService {

  private isValid: boolean = true;
  private forms: object = {};

  constructor(
    private bus: BusService,
    private events: EventsService,
    private router: Router
  ) {
    bus.subscribe(events.notified.validation.form.aggregation, this.aggregate, this);
    bus.subscribe(events.notified.validation.form.action, this.validate, this);
  }

  public reset (): void {
    this.isValid = true;
    this.forms = {};
  }
  // check form valid values
  public aggregate(isValid: boolean = false, name: string = 'default'): void {
    this.isValid = this.isValid && isValid;
    this.forms[name] = this.forms.hasOwnProperty(name) ? this.forms[name] && isValid : isValid;
  };
  // start success/error validation event
  public validate(): void {
    if (this.isValid) {
      this.bus.publish(this.events.received.validation.success);
    } else {
      this.bus.publish(this.events.received.validation.failure, this.forms);
    }
    this.reset();
  }

}
