import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from 'app/services/authentication.service';
import { DirectoryUser } from 'app/services/directory/directory-user';
import { DirectoryService } from 'app/services/directory/directory.service';
import { AccessControlService } from '../../../../services/access-control.service';


export interface Options {
  suggestEmptyUser?: boolean;
  emptyUserTitle?: string;
  allowEmpty?: boolean;
  selected?: DirectoryUser;
  roleSelection?: { role: string };
  multiple?: boolean;
}


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'select-directory-user-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class SelectDirectoryUserDialogComponent implements OnInit, OnDestroy {

  suggestions: DirectoryUser[];
  selected: DirectoryUser | DirectoryUser[];
  search$ = new Subject<void>();
  keyword: string;
  loading = false;
  roleSelection = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Options,
    public ref: MatDialogRef<SelectDirectoryUserDialogComponent>,
    private auth: AuthenticationService,
    private access: AccessControlService,
    private directory: DirectoryService,
  ) {
    this.data = this.data || {};
  }

  ngOnInit() {
    if (this.data) {
      if (this.data.suggestEmptyUser) {
        this.suggestions = [undefined];
      }

      if (this.data.selected) {
        if (this.auth.username === this.data.selected.username) {
          this.selected = undefined; // --> i.e. myself
        } else {
          this.selected = this.data.selected;
        }
      }

      if (this.data.roleSelection) {
        this.roleSelection = this.data.roleSelection;
      }
    }

    this.search$.pipe(
      tap(() => this.loading = true),
      debounceTime(300),
      switchMap(() => this.directory.search(this.keyword, this.roleSelection ? this.roleSelection.role : null)),
      tap(() => this.loading = false)
    ).subscribe(suggestions => {
      if (!this.keyword && this.data && this.data.suggestEmptyUser) {
        this.suggestions = [undefined].concat(suggestions);
      } else {
        this.suggestions = suggestions;
      }
    });

    this.search$.next();
  }

  ngOnDestroy() {
    this.search$.complete();
  }

  pick(user: DirectoryUser) {
    if (this.data.multiple) {
      if (!user) {
        this.selected = null;
        return;
      }

      if (!this.selected) {
        this.selected = [];
      }

      const existing = (this.selected as DirectoryUser[]).findIndex(u => u.username === user.username);
      if (existing !== -1) {
        (this.selected as DirectoryUser[]).splice(existing, 1);
      } else {
        (this.selected as DirectoryUser[]).push(user);
      }
    } else {
      if (user === this.selected) {
        this.ref.close({selected: this.selected});
      }

      this.selected = user;
    }
  }

  isSelected(user: DirectoryUser) {
    if (!user) {
      return false;
    }

    if (this.data.multiple) {
      return this.selected ? (this.selected as DirectoryUser[]).findIndex(u => u.username === user.username) !== -1 : false;
    }

    return this.selected ? (this.selected as DirectoryUser).username === user.username : false;
  }

  confirm() {
    this.ref.close({selected: this.selected, multiple: this.data.multiple});
  }
}
