import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Params, UrlSerializer, Router } from '@angular/router';
import { Observable ,  Subject as Sub ,  Subscription, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { Subject, CommentService } from '../../../services/comment/comment.service';
import { CommentBoxDialogComponent } from '../comment-box-dialog/comment-box-dialog.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'comment-button',
  templateUrl: './comment-button.component.html',
  styleUrls: ['./comment-button.component.scss']
})
export class CommentButtonComponent implements OnInit, OnChanges, OnDestroy {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('comment-subject-codename') subjectCodename: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('comment-subject-title') subjectTitle: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('comment-auto-open-dialog-delay') autoOpenDialogDelay = -1;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('comment-access-token-key') accessTokenKey = 'access-token';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('comment-access-token') accessToken: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('comment-mentions-limit') mentionsLimit: number[] | undefined = undefined;

  subject: Subject;
  subjectChange = new Sub<Subject>();
  subs = new Subscription();
  dialogRef: MatDialogRef<CommentBoxDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private comments: CommentService,
    private route: ActivatedRoute,
    private router: Router,
    private serializer: UrlSerializer,
  ) { }

  ngOnInit() {
    this.subs.add(combineLatest(this.subjectChange, this.route.queryParams)
      .subscribe(([_, queryParams]) => {
        const params = Object.assign({}, queryParams);
        if (this.accessToken) {
          params[this.accessTokenKey] = this.accessToken;
        }

        const url = this.router.createUrlTree([this.route.snapshot.url.join('/')], { queryParams: params });
        this.subject.host = this.serializer.serialize(url).substr(1); // --> remove the initial slash
      }));
  }

  ngOnDestroy() {
    this.subjectChange.complete();
    this.subs.unsubscribe();
  }

  ngOnChanges() {
    //
    // First a default
    //
    if (!this.subject) {
      this.subject = {
        codename: this.subjectCodename,
        host: this.route.snapshot.url.join('/'),
        comments: [],
        open: false,
      };
    }

    this.subject.title = this.subjectTitle;
    this.subjectChange.next(this.subject);

    if (this.dialogRef) {
      this.dialogRef.componentInstance.subject = this.subject;
    }

    if (this.subject.codename) {
      this.update(true);
    }
  }

  update(openDialog = false) {
    this.comments.get(this.subject.codename)
      .subscribe(subject => {
        this.subject = Object.assign(this.subject, subject);
        this.subjectChange.next(this.subject);
        if (this.subject.codename && openDialog) {
          this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
            if (params['comment-subject-codename'] === this.subjectCodename) {
              if (this.autoOpenDialogDelay >= 0) {
                // prevent automatically opening the comment dialog after the first time
                setTimeout(() => this.openDialog(undefined, true), this.autoOpenDialogDelay);
              }
            }
          });
        }
      });
  }

  // removeQueryParam: When the comment box is initalized on the page lazily, for example, as part of another dialog,
  // then it might be annoying to constantly open the comment dialog
  // Removing the query parameter from the URL makes it possible to prevent that.
  openDialog(event?, removeQueryParam = false) {
    if (event) {
      event.stopPropagation();
    }

    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(CommentBoxDialogComponent, {
        width: '512px',
        data: this.subject,
      });

      this.dialogRef.componentInstance.mentionConfig.limit = this.mentionsLimit;

      this.dialogRef.afterClosed().subscribe(() => {
        this.update();
        this.dialogRef = undefined;

        if (removeQueryParam) {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { 'comment-subject-codename': undefined },
            queryParamsHandling: 'merge'
          });
        }
      });
    }
  }

}
