import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BusService } from "../bus.service";
import {
  IPostOptions,
  RequestService
} from "../request.service";
import { EventsService } from "../events.service";

interface IPostCredentialsRequest {
  email: string;
  password: string;
}

interface IPostCredentialsResponse {
  _id: string;
  token: string;
  firstName: string;
  lastName: string;
  username: string;
  role: string;
  email: string;
  controllerId: number;
  department: {
    id: number;
    name: string;
    directoryId: string;
  };
}

/*
  An interlayer service for authentication between request service and login component
*/
@Injectable()
export class AuthenticationRequestService {

  private options: IPostOptions<IPostCredentialsRequest, IPostCredentialsResponse>;
  private next: string = null;
  private nextParams: any = {};

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: "/account/authenticate",
      body: {
        email: "",
        password: ""
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(user: IPostCredentialsResponse): void {
    localStorage.setItem('currentUser', user.token);
    localStorage.setItem('currentUserID', user._id);
    this.bus.publish(this.events.received.authentication.success, {
      _id: user._id,
      token: user.token,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      controllerId: user.controllerId,
      department: user.department,

      next: this.next,
      nextParams: this.nextParams,
    });
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.authentication.failure, httpErrorResponse);
  }

  private request(data): void {
    this.bus.publish(this.events.notified.request.starting);
    this.options.body.email = data.email;
    this.options.body.password = data.password;

    this.next = data.next;
    this.nextParams = data.nextParams;

    this.requestService.post<IPostCredentialsRequest, IPostCredentialsResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.authentication, this.request.bind(this));
  }
}
