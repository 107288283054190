import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BusService } from '../bus.service';
import { RequestService } from '../request.service';
import { EventsService } from '../events.service';


interface AssociateRequest {
  paId: number;
  departmentId: number;
}

@Injectable()
export class RpaInternallyProcessedService {
  private api = {
    root: '/pa/internally-processed',
    pas: '/pas',
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  // TODO: deprecate this
  getInternallyProcessedPas() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.pas,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.rpa.internallyProcessed.pas.success, data),
        error: error => this.bus.publish(this.events.received.data.rpa.internallyProcessed.pas.failure, error),
      }
    });
  }

  // TODO: deprecate this
  associations() {
    return this.requestService.get<any>({
      uri: this.api.root,
      handlers: {
        success: data => this.bus.publish(this.events.received.data.rpa.internallyProcessed.associations.success, data),
        error: error => this.bus.publish(this.events.received.data.rpa.internallyProcessed.associations.failure, error),
      }
    });
  }

  paAssociations(paId: number) {
    return this.requestService.get<any>({
      uri: this.api.root + `/${paId}`,
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  associate(event: AssociateRequest) {
    return this.requestService.put<AssociateRequest, any>({
      uri: this.api.root,
      body: event,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.rpa.internallyProcessed.associate.success, event),
        error: error => this.bus.publish(this.events.received.data.rpa.internallyProcessed.associate.failure, error),
      }
    });
  }

  disassociate(event: AssociateRequest) {
    return this.requestService.delete<any>({
      uri: this.api.root + `/${event.paId}/${event.departmentId}`,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.rpa.internallyProcessed.disassociate.success, event),
        error: error => this.bus.publish(this.events.received.data.rpa.internallyProcessed.disassociate.failure, error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.rpa.internallyProcessed.pas, this.getInternallyProcessedPas.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.internallyProcessed.associations, this.associations.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.internallyProcessed.associate, this.associate.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.internallyProcessed.disassociate, this.disassociate.bind(this));
  }
}
