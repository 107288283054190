import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';


@Component({
  selector: 'app-badge-info',
  templateUrl: './badge-info.component.html',
  styleUrls: ['./badge-info.component.scss']
})
export class BadgeInfoComponent implements OnInit, OnDestroy {

  token: string;
  controller: any;
  dpo: any;
  progress: any;
  imageUrl: string;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
      this.bus.publish(this.events.requested.data.badge.info, this.token);
    });
  }

  updateInfo(info) {
    this.controller = info.controller;
    this.dpo = info.dpo;
    this.progress = info.progress;
    this.imageUrl = info.imageUrl;
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.badge.info.success, this.updateInfo, this);
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.events.received.data.badge.info.success, this.updateInfo);
  }
}
