import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Observable, Subscription, zip } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TodoDialogComponent } from '../todo-dialog/todo-dialog.component';
import { TodosService, Todo, TodoType } from '../../../services/todos/todos.service';
import { TodoDetailDialogComponent } from '../todo-detail-dialog/todo-detail-dialog.component';
import { SubjectTodosDialogComponent } from '../subject-todos-dialog/subject-todos-dialog.component';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'todoable',
  templateUrl: './todoable-box.component.html',
  styleUrls: ['./todoable-box.component.scss']
})
export class TodoableBoxComponent implements OnInit, OnChanges, OnDestroy {

  @Input() position: 'right' | 'bottom' | 'left' | 'top' = 'right';
  @Input() subject: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('suggested-title') suggestedTitle: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('suggested-type') suggestedType: TodoType;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('access-token') accessToken: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('access-token-key') accessTokenKey = 'access-token';

  @ViewChild('holder', { static: false }) holder: ElementRef;

  expandTodo = true;
  justCreated = false;
  todo: Todo;
  queryParamsSub: Subscription;
  showCreatedSub: Subscription;
  initialScrollConducted = false;

  todosOnSubject: Todo[] = [];
  assignedTodosOnSubject: Todo[] = [];

  updateTodo: any;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private service: TodosService,
    private bus: BusService,
    private events: EventsService,
  ) { }

  ngOnInit() {
    zip(
      this.service.get({ done: false, subject: this.subject, limit: 2 }),
      this.service.getAssigned({ done: false, subject: this.subject, limit: 2 })
    ).subscribe(res => [this.todosOnSubject, this.assignedTodosOnSubject] = res as any);

    this.updateTodo = (todo: Todo) => {
      if (todo && this.todo && todo.id === this.todo.id) {
        this.todo = Object.assign({}, todo);
        if (this.todo.done) {
          this.expandTodo = false;
        }
      }
    };

    this.bus.subscribe(this.events.notified.todos.singleUpdate, this.updateTodo);
  }

  ngOnDestroy() {
    this.bus.unsubscribe(this.events.notified.todos.singleUpdate, this.updateTodo);
  }

  ngOnChanges() {
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
    this.queryParamsSub = this.route.queryParams.subscribe(params => {
      if (params.todo && params.subject && params.subject === this.subject) {
        this.service.single(params.todo).pipe(this.service.polish).subscribe(todo => {
          this.todo = todo[0];
          if (!this.initialScrollConducted) {
            setTimeout(() => {
              window.scrollTo({
                top: this.holder.nativeElement.getBoundingClientRect().y + window.pageYOffset - 200,
                behavior: 'smooth'
              });
              this.initialScrollConducted = true;
            }, 1000);
          }
        });
      }
    });
  }

  showTodoDetails() {
    if (this.todo) {
      if (!this.expandTodo) {
        this.expandTodo = true;
        return;
      }

      this.dialog.open(TodoDetailDialogComponent, {
        width: '700px',
        data: {
          todo: this.todo,
          showGoto: false,
          cleanUpURL: false,
        }
      });
    }
  }

  openNewTodoDialog() {
    this.showCreatedSub = this.dialog.open(TodoDialogComponent, {
      width: '700px',
      data: {
        suggestedTitle: this.suggestedTitle,
        suggestedType: this.suggestedType,
        subject: this.subject,
        link: true,
        accessToken: this.accessToken,
        accessTokenKey: this.accessTokenKey,
      }
    }).afterClosed().subscribe(created => {
      this.justCreated = created || this.justCreated;
    });
  }

  closeTodo(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.expandTodo = false;
  }

  openList() {
    this.dialog.open(SubjectTodosDialogComponent, {
      width: '768px',
      data: this.subject,
    });
  }

  get notify() {
    if (this.justCreated) {
      return true;
    } else if (!this.todo) {
      return this.todosOnSubject.length > 0 || this.assignedTodosOnSubject.length > 0;
    } else {
      return this.todosOnSubject.filter(t => t.id !== this.todo.id).length > 0 ||
            this.assignedTodosOnSubject.filter(t => t.id !== this.todo.id).length > 0;
    }
  }
}
