import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export enum StepState {
  current,
  past,
  future,
}

@Component({
  selector: 'stepper-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

  @Input() large : boolean = false;
  @Input() initial : boolean = false;
  @Input() id : string = undefined;
  @Output() activation : EventEmitter<void> = new EventEmitter<void>();

  index : number;
  public state : StepState = StepState.future;

  constructor() { }

  ngOnInit() {
  }

  activate() {
    this.state = StepState.current;
    this.activation.emit();
  }

  pushBack() {
    this.state = StepState.past;
  }

  pushForward() {
    this.state = StepState.future;
  }

  public get states() { return StepState; }
}
