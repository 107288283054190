import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BusService } from "../../services/bus.service";
import { EventsService } from "../../services/events.service";
import { AuthenticationService } from "../../services/authentication.service";

import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component'; //TODO: remove this later.
import { ClientSupervisionService } from '../../services/supervision/client-supervision.service';
import { SupervisionTemplatesService } from '../../services/supervision/templates.service';
import { AccessControlService, roles } from 'app/services/access-control.service';


@Component({
  selector: 'header-component',
  templateUrl: './header.template.html',
  styleUrls: ['./header.styles.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private overlay: boolean = false;
  private overlaySave: boolean = false;

  private messageBroadcast: string;

  controllerInfo = undefined;

  constructor(
    private router: Router,
    private bus: BusService,
    private events: EventsService,
    private auth: AuthenticationService,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    private supervisionService: ClientSupervisionService,
    private supervisionTemplates: SupervisionTemplatesService
  ) {
    this.bus.publish(this.events.requested.data.messageBroadcast); //admin broadcast
  }

  // Message Broadcast snackbar function
  openMessageBroadCastSnackBar(message: string, action: string, duration?: number) {
    if(message.length !== 0){
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom',
        panelClass:['snackbar-style'],
        direction: 'ltr',
        duration: duration,
      });
    }
  }

  get loggedIn() {
    return localStorage.getItem('currentUser');
  }

  //NOTE: Deprecated function. Do not use this!
  //TODO: remove this.
  //
  private openDialogFailure(data: any): void {
    let message = data.error ? data.error.message : 'Uncaught Exception';
    let status = (data.error && data.error.status) ? data.error.status : 500;
    let header = (data.error && data.error.status) ? `Error ${data.error.status}` : 'Error 500';
    let dialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'my-full-screen-dialog',
      width: '350px',
      data: { message: message, status: status, header: header}
    });
  }

  //NOTE: Deprecated function. Do not use this!
  //TODO: remove this.
  //
  private openDialogSuccess(data: any): void {
    let message = typeof data === 'string' ? data : 'Authentication';
    let dialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'my-full-screen-dialog',
      width: '350px',
      data: { message: message, status: 200, header: 'Success'}
    });
  }
  public logout(): void {
    this.bus.publish(this.events.notified.user.logout);
    this.router.navigate(['/home']);
  }

  private toggleOverlay(value: boolean = false): void {
    this.overlay = value;
  }

  private toggleOverlaySave(value: boolean = false): void {
    this.overlaySave = value;
  }

  private setMessageBroadcast(data: any): void {
    this.messageBroadcast = data.versionMessage;
    this.openMessageBroadCastSnackBar(data.adminBroadcastmessage, "CLOSE", 5000);
  }

  private setControllerInfo(basicInfo: any) {
    this.controllerInfo = basicInfo;
  }

  /**
   * Returns the string to display for the user role.
   * I don't think we need to translate those for now, as the roles aren't communicated.
   */
  get getShortRole() {
    const role = this.auth.role;
    switch (role) {
      case roles.anyone: {
        return ""
      }
      case roles.vtb: {
        return "(VTB)"
      }
      case roles.dsbv: {
        return "(DSBv)"
      }
      case roles.dst: {
        return "(DST)"
      }
      case roles.advisor: {
        return "(ADV)"
      }
      case roles.admin: {
        return "(ADM)"
      }
      case roles.dsb: {
        return "(DSB)"
      }
    }
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.notified.request.starting, this.toggleOverlay.bind(this, true), this);
    this.bus.subscribe(this.events.notified.request.finished, this.toggleOverlay.bind(this, false), this);
    this.bus.subscribe(this.events.notified.saving.starting, this.toggleOverlaySave.bind(this, true), this);
    this.bus.subscribe(this.events.notified.saving.finished, this.toggleOverlaySave.bind(this, false), this);
    this.bus.subscribe(this.events.received.data.messageBroadcast.success, this.setMessageBroadcast, this);
    this.bus.subscribe(this.events.received.data.basicInfo.get.success, this.setControllerInfo, this);

    this.bus.subscribe(this.events.notified.supervision.client.started, () => {
      this.controllerInfo = undefined;
      this.bus.publish(this.events.requested.data.basicInfo.get);
    }, this);

    this.bus.subscribe(this.events.notified.supervision.client.ended, () => {
      this.controllerInfo = undefined;
    }, this);
  }

  public unSubscribe(): void {
    this.bus.unsubscribe(this.events.notified.request.starting, this.toggleOverlay);
    this.bus.unsubscribe(this.events.notified.request.finished, this.toggleOverlay);
    this.bus.unsubscribe(this.events.notified.saving.starting, this.toggleOverlaySave);
    this.bus.unsubscribe(this.events.notified.saving.finished, this.toggleOverlaySave);
    this.bus.unsubscribe(this.events.received.data.messageBroadcast.success, this.setMessageBroadcast);
  }

  // make subscribe on a component initialization
  public ngOnInit(): void {
    this.subscribe();
  }

  // make unsubscribe before destroying component
  public ngOnDestroy(): void {
    this.unSubscribe();
  }
}
