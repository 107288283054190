import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';

import { EventsService } from '../../../../services/events.service';
import { BusService } from '../../../../services/bus.service';

import { VendorAddDialogComponent } from '../vendor-add-dialog/vendor-add-dialog.component';


@Component({
  selector: 'app-vendor-search-dialog',
  templateUrl: './vendor-search-dialog.component.html',
  styleUrls: ['./vendor-search-dialog.component.scss']
})
export class VendorSearchDialogComponent implements OnInit, OnDestroy {

  _vendors = [];
  search = '';

  constructor(
    private bus: BusService,
    private events: EventsService,
    public dialogRef: MatDialogRef<VendorSearchDialogComponent>,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.vendors.get);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  updateVendors(data) {
    this._vendors = data;
  }

  newVendor() {
    this.dialog.open(VendorAddDialogComponent, {
      width: '480px',
    }).afterClosed().subscribe(vendor => {
      if (vendor) {
        this.dialogRef.close(Object.assign(vendor, {added : true}));
      }
    });
  }

  get vendors() {
    if (this.search) {
      let search = this.search.toLowerCase();
      return this._vendors.filter(vendor => vendor.name.toLowerCase().indexOf(search) != -1);
    }

    return this._vendors
    ;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.vendors.get.success, this.updateVendors, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.vendors.get.success, this.updateVendors);
  }
}
