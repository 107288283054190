import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-auth-token-login',
  templateUrl: './auth-token-login.component.html',
  styleUrls: ['./auth-token-login.component.css']
})
export class AuthTokenLoginComponent implements OnInit {
  // This component is a customer support helper. From a valid jwt token URL, it sets the local storage as if logged in
  // as a user. This is meant to be used in a private window by support employees.

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const token = params['token'];
      const firstName = params['firstName'];
      const lastName = params['lastName'];
      const email = params['email'];
      const id = params['_id'];
      const product = params['product'];

      localStorage.setItem('currentUser', token);
      localStorage.setItem('currentUserID', id);
      localStorage.setItem('currentUserFirstName', firstName);
      localStorage.setItem('currentUserLastName', lastName);
      localStorage.setItem('currentUserEmail', email);
      localStorage.setItem('currentUserProductType', product);

      if (product !== 'supervisor') {
        this.router.navigate(['todolist']);
      } else {
        this.router.navigate(['supervision/clients']);
      }
    });
  }
}
