import { Component, Inject, INJECTOR, Input, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { TodoDialogComponent } from 'app/components/todos/todo-dialog/todo-dialog.component';
import { DirectoryUser } from 'app/services/directory/directory-user';


export interface UserInfoDialogData {
  user: DirectoryUser;
  todoSubject?: string;
}


@Component({
  selector: 'app-user-info-dialog',
  templateUrl: './user-info-dialog.component.html',
  styleUrls: ['./user-info-dialog.component.scss']
})
export class UserInfoDialogComponent implements OnInit {

  @Input() user: DirectoryUser;
  @Input() todoSubject: string | undefined = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: UserInfoDialogData,
    private dialog: MatDialog,
  ) {
    if (data.user) {
      this.user = data.user;
    }

    if (data.todoSubject) {
      this.todoSubject = data.todoSubject;
    }
  }

  ngOnInit() {
  }

  createTodo() {
    if (this.todoSubject) {
      this.dialog.open(TodoDialogComponent, {
        width: '700px',
        data: {
          todoSubject: this.todoSubject,
          suggestedAssignee: this.user,
        },
      });
    }
  }
}
