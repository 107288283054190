import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';


interface AddRequest {
  name: string;
  email: string;
  telephone: string;
  addressStreet: string;
  addressPostcode: string;
  addressCity: string;
  addressCountry: string;
}

interface ContactRequest {
  vendorId: number;
}

interface AddResponse {
  id: number;
}

interface DecodeDPATokenResponse {
  controller: {
    controllerName: string;
    controllerEmailAddress: string;
  };
  vendor: {
    name: string;
    email: string;
  };
}

@Injectable()
export class VendorsService {

  private api = {
    root: '/vendors',
    controller: '/controller',
    contact: '/contact',
    toggleRequired: '/toggleRequired',
    decodeDPAToken: '/dpa-token/'
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  public get() {
    return this.requestService.get<any>({
      uri: this.api.root,
      handlers: {
        success: vendors => this.bus.publish(this.events.received.data.vendors.get.success, vendors),
        error: error => this.bus.publish(this.events.received.data.vendors.get.failure, error),
      }
    });
  }

  public add(data: AddRequest) {
    return this.requestService.post<AddRequest, AddResponse>({
      uri: this.api.root,
      body: data,
      handlers: {
        success: response => {
          this.bus.publish(this.events.received.data.vendors.add.success, Object.assign({}, data, response));
        },
        error: error =>
          this.bus.publish(this.events.received.data.vendors.add.failure, error),
      }
    });
  }

  public getControllerVendors() {
    return this.requestService.get<any>({
      uri: this.api.root + this.api.controller,
      handlers: {
        success: vendors => this.bus.publish(this.events.received.data.vendors.controllerVendors.success, vendors),
        error: error => this.bus.publish(this.events.received.data.vendors.controllerVendors.failure, error),
      }
    });
  }

  public contactForDPA(data: ContactRequest) {
    return this.requestService.post<any, any>({
      uri: this.api.root + this.api.contact + `/${data.vendorId}/`,
      body: {},
      handlers: {
        success: () => this.bus.publish(this.events.received.action.vendors.contactForDPA.success),
        error: error => this.bus.publish(this.events.received.action.vendors.contactForDPA.failure, error),
      }
    });
  }

  public toggleDPARequired(data: { vendorId: number, notRequired: boolean }) {
    return this.requestService.post<any, any>({
      uri: this.api.root + this.api.toggleRequired + `/${data.vendorId}/`,
      body: { notRequired: data.notRequired },
      handlers: {
        success: () => this.bus.publish(this.events.received.action.vendors.toggleDPARequired.success),
        error: error => this.bus.publish(this.events.received.action.vendors.toggleDPARequired.failure, error),
      }
    });
  }

  public decodeDPAToken(dpaToken) {
    return this.requestService.get<DecodeDPATokenResponse>({
      uri: this.api.root + this.api.decodeDPAToken + `?dpa-token=${dpaToken}`,
      handlers: {
        success: response => this.bus.publish(this.events.received.data.vendors.decodeDPAToken.success, response),
        error: error => this.bus.publish(this.events.received.data.vendors.decodeDPAToken.failure, error),
      }
    });
  }

  private subscribe() {
    this.bus.subscribe(this.events.requested.data.vendors.get, this.get.bind(this));
    this.bus.subscribe(this.events.requested.data.vendors.add, this.add.bind(this));
    this.bus.subscribe(this.events.requested.data.vendors.controllerVendors, this.getControllerVendors.bind(this));
    this.bus.subscribe(this.events.requested.action.vendors.contactForDPA, this.contactForDPA.bind(this));
    this.bus.subscribe(this.events.requested.action.vendors.toggleDPARequired, this.toggleDPARequired.bind(this));
    this.bus.subscribe(this.events.requested.data.vendors.decodeDPAToken, this.decodeDPAToken.bind(this));
  }
}
