import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';
import { TranslateService } from '@ngx-translate/core';

import { _ } from '../../../trans';
import { MatDialog } from '@angular/material';
import { CustomPaDialogComponent } from '../custom-pa-dialog/custom-pa-dialog.component';

const spiritBoostPhrases = [
  _('pa.select.spiritBoosts.phrase1'),
  _('pa.select.spiritBoosts.phrase2'),
  _('pa.select.spiritBoosts.phrase3'),
  _('pa.select.spiritBoosts.phrase4'),
  _('pa.select.spiritBoosts.phrase5'),
]

@Component({
  selector: 'pa-selection',
  templateUrl: './pa-selection.rpa.template.html',
  styleUrls: ['./pa-selection.rpa.styles.scss'],
})
export class PaSelectionComponent implements OnInit, OnDestroy {
  public palist: any[];
  public companyName;
  public review: boolean = false;
  public progress: number;
  public spiritBoost: string = "";


  @ViewChild('stepper', { static: false }) stepper;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
  }

  public setStatus(pa, status) {
    if (this.canWrite) {
      pa.paApplicability = status;
      this.bus.publish(this.events.requested.data.rpa.updateStatus, {
        id: pa.paId,
        status: pa.paApplicability,
      });
    }
  }

  public nextUnfilledPa() {
    if (!this.stepper) return;

    let index = 0;
    this.palist.some(pa => ++index && pa.paId == this.stepper.current.id);

    let target = undefined;
    this.palist
      .slice(index)
      .some(pa => (target = pa)
        && (target.paApplicability != 'Applicable')
        && (target.paApplicability != 'Not Applicable'));

    if (target) this.stepper.switch(target.paId);
  }

  public get canWrite() {
    return true;
  }

  public loadAllPAs(palist: any[]): void {
    this.palist = palist.filter(pa => !pa.deprecationDate || pa.paApplicability == 'Applicable');
  }

  public updateCompanyName(data) {
    if (data.controllerName) {
      this.companyName = data.controllerName;
    }
  }

  public updateTaskProgress(data) {
    this.progress = data.progress;
  }

  public statusUpdateSuccess(result): void {
    setTimeout(() => this.nextUnfilledPa(), 200);
    this.bus.publish(this.events.requested.data.todos.controllerTaskProgress, {
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_pa_selection',
    });
    if (Math.random() > .8) {
      this.spiritBoost = spiritBoostPhrases[Math.floor(Math.random() * spiritBoostPhrases.length)];
    }
  }

  public get hasCustomPAs(): boolean {
    return this.palist && this.palist.filter(pa => pa.paOwner).length > 0;
  }

  public get applicables() {
    return this.palist.filter(pa => pa.paApplicability == 'Applicable');
  }

  public get notApplicables() {
    return this.palist.filter(pa => pa.paApplicability == 'Not Applicable');
  }

  public get unsets() {
    return this.palist.filter(pa => !['Applicable', 'Not Applicable'].includes(pa.paApplicability));
  }

  editCustomPa(paId) {
    const ref = this.dialog.open(CustomPaDialogComponent, {
      width: '700px',
      data: {
        id: paId
      }
    });
    ref.afterClosed().subscribe((update: boolean) => {
      if (update) {
        // do something
      }
    })
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.received.data.rpa.getAll.success, this.loadAllPAs, this);
    this.bus.subscribe(this.events.received.data.rpa.updateStatus.success, this.statusUpdateSuccess, this);
    this.bus.subscribe(this.events.received.data.controller.name.success, this.updateCompanyName, this);
    this.bus.subscribe(this.events.received.data.todos.controllerTaskProgress.success, this.updateTaskProgress, this);
  }

  private unsubscribe(): void {
    this.bus.unsubscribe(this.events.received.data.rpa.getAll.success, this.loadAllPAs);
    this.bus.unsubscribe(this.events.received.data.rpa.updateStatus.success, this.statusUpdateSuccess);
    this.bus.unsubscribe(this.events.received.data.controller.name.success, this.updateCompanyName);
    this.bus.unsubscribe(this.events.received.data.todos.controllerTaskProgress.success, this.updateTaskProgress);
  }

  public ngOnInit(): void {
    this.subscribe();
    this.bus.publish(this.events.requested.data.rpa.getAll);
    this.bus.publish(this.events.requested.data.controller.name);
    this.bus.publish(this.events.requested.data.todos.controllerTaskProgress, {
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_pa_selection',
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }
}
