import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';


interface AddClientRequest {
  email: string;
}

interface AddControllerRequest {
  controllerName: string;
  metadata: any;
}

@Injectable()
export class ClientManagementService {

  api = {
    root: '/supervision/client-management',
    clients: {
      all: '/clients',
      accepted: '/clients/accepted',
      pending: '/clients/pending',
      add: '/client',
      addController: '/client/controller',
      delete: '/client',
      getSingle: '/client',
      confirm: '/client/confirm',
      resendInvitation: '/resend-invitation'
    }
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  allClients() {
    this.requestService.get<any>({
      uri: this.api.root + this.api.clients.all,
      handlers: {
        success: clients => this.bus.publish(this.events.received.data.supervision.clients.all.success, clients),
        error: error => this.bus.publish(this.events.received.data.supervision.clients.all.failure, error.error),
      }
    });
  }

  acceptedClients() {
    this.requestService.get<any>({
      uri: this.api.root + this.api.clients.accepted,
      handlers: {
        success: clients => this.bus.publish(this.events.received.data.supervision.clients.accepted.success, clients),
        error: error => this.bus.publish(this.events.received.data.supervision.clients.accepted.failure, error.error)
      }
    });
  }

  pendingClients() {
    this.requestService.get<any>({
      uri: this.api.root + this.api.clients.pending,
      handlers: {
        success: clients => this.bus.publish(this.events.received.data.supervision.clients.pending.success, clients),
        error: error => this.bus.publish(this.events.received.data.supervision.clients.pending.failure, error.error)
      }
    });
  }

  getSingle(id: number) {
    this.requestService.get<any>({
      uri: this.api.root + this.api.clients.getSingle + `/${id}`,
      handlers: {
        success: client => this.bus.publish(this.events.received.data.supervision.clients.single.success, client),
        error: error => this.bus.publish(this.events.received.data.supervision.clients.single.failure, error.error),
      }
    });
  }

  addByInvite(data: AddClientRequest) {
    this.requestService.post<AddClientRequest, void>({
      uri: this.api.root + this.api.clients.add,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.clients.add.invite.success),
        error: error => this.bus.publish(this.events.received.action.supervision.clients.add.invite.failure, error.error),
      }
    });
  }

  addByControllerCreation(data: AddControllerRequest) {
    this.requestService.post<AddControllerRequest, void>({
      uri: this.api.root + this.api.clients.addController,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.clients.add.controller.success),
        error: error => this.bus.publish(this.events.received.action.supervision.clients.add.controller.failure, error.error),
      }
    });
  }

  deleteClient(supervisionId) {
    this.requestService.delete<any>({
      uri: this.api.root + this.api.clients.delete + `/${supervisionId}`,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.clients.delete.success),
        error: error => this.bus.publish(this.events.received.action.supervision.clients.delete.failure, error)
      }
    });
  }

  resendInvitation(supervisionId) {
    this.requestService.post<any, any>({
      uri: this.api.root + this.api.clients.getSingle + `/${supervisionId}` + this.api.clients.resendInvitation,
      body: {},
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.clientInvitation.resend.success),
        error: error => this.bus.publish(this.events.received.action.supervision.clientInvitation.resend.failure, error)
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.supervision.clients.all, this.allClients.bind(this));
    this.bus.subscribe(this.events.requested.data.supervision.clients.accepted, this.acceptedClients.bind(this));
    this.bus.subscribe(this.events.requested.data.supervision.clients.pending, this.pendingClients.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.clients.add.invite, this.addByInvite.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.clients.add.controller, this.addByControllerCreation.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.clients.delete, this.deleteClient.bind(this));
    this.bus.subscribe(this.events.requested.data.supervision.clients.single, this.getSingle.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.clientInvitation.resend, this.resendInvitation.bind(this));
  }
}
