import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthenticationService } from 'app/services/authentication.service';
import { TodoDetailDialogComponent } from '../../todo-detail-dialog/todo-detail-dialog.component';
import { hasPassingChildren, nodePassesFilter, prune, shouldNodeBeDisplayed, TodoNode, TodoTreeFilter } from '../todo-tree.types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'todo-tree-node',
  templateUrl: './todo-tree-node.component.html',
  styleUrls: ['./todo-tree-node.component.scss']
})
export class TodoTreeNodeComponent implements OnInit {

  @Input() todo: TodoNode;
  @Input() filter: TodoTreeFilter;
  expanded = false;

  constructor(
    private dialog: MatDialog,
    private auth: AuthenticationService,
  ) { }

  ngOnInit() {
  }

  get hasChildren() {
    return this.hasPassingChildren(this.todo);
  }

  get final() {
    return !this.hasChildren || !this.expanded;
  }

  open() {
    this.dialog.open(TodoDetailDialogComponent, {
      width: '700px',
      data: {
        todo: prune(this.todo),
        showGoto: false,
        cleanUpURL: false,
      }
    });
  }

  toggle(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.expanded = !this.expanded;
  }

  passesFilter(todo: TodoNode) {
    return nodePassesFilter(this.filter, this.auth.id)(todo);
  }

  hasPassingChildren(todo: TodoNode) {
    return hasPassingChildren(this.filter, this.auth.id)(todo);
  }

  show(todo: TodoNode) {
    return shouldNodeBeDisplayed(this.filter, this.auth.id)(todo);
  }
}
