import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Components
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { UserExpiredComponent } from './components/user-expired/user-expired.component';
import { EmailLoginComponent } from './components/login/email/email.login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { VerificationComponent } from './components/registration/verification/verification.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { BasicInfoComponent } from './components/rpa/basic-info/basic-info.rpa.component';
import { PaSelectionComponent } from './components/rpa/pa-selection/pa-selection.rpa.component';
import { ReportComponent } from './components/rpa/report/report.rpa.component';
import { ChangePasswordComponent } from './components/settings/change-password/change-password.settings.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { EmailNotificationComponent } from './components/settings/email-notification/email-notification.settings.component';
import { ProfilePictureComponent } from './components/settings/profile-picture/profile-picture.settings.component';
import { ImprintComponent } from './components/footer/imprint/imprint.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { DepartmentsComponent } from './components/organisation/department/departments.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { GdprAppliesComponent } from './components/welcome/gdpr-applies/gdpr-applies.component';
import { SetupDpmsComponent } from './components/welcome/setup-dpms/setup-dpms.component';
import { DpGoalsComponent } from './components/organisation/dp-goals/dp-goals.component';
import { DeclareDpoComponent } from './components/organisation/declare-dpo/declare-dpo.component';

//Guards
import { AuthenticationGuard } from "./guards/authentication.guard";
import { NotAuthenticatedGuard } from "./guards/not-authenticated.guard";
import { ProductFeaturesGuard } from './guards/product-features.guard';

import { ControllerComponent } from './components/organisation/controller/controller.component';
import { MilestoneOneComponent } from './components/milestones/milestone-one/milestone-one.component';
import { OrganisationComponent } from './components/organisation/organisation.component';
import { RpaComponent } from './components/rpa/rpa.component';
import { RpaTutorialComponent } from './components/rpa/tutorial/tutorial.component';
import { PaDetailsComponent } from './components/rpa/pa-details/pa-details.component';
import { PaExternalProcessorsComponent } from './components/rpa/pa-external-processors/pa-external-processors.component';
import { MilestoneTwoComponent } from './components/milestones/milestone-two/milestone-two.component';
import { DpasComponent } from './components/vendors/dpas/dpas.component';
import { OfferDpaComponent } from './components/vendors/offer-dpa/offer-dpa.component';
import { AuthTokenLoginComponent } from './components/auth-token-login/auth-token-login.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { ConfirmInvitationComponent } from './components/user-management/confirm-invitation/confirm-invitation.component';
import { PrivacyPolicyEnComponent } from './components/footer/privacy-policy-en/privacy-policy-en.component';
import { PrivacyPolicyDeComponent } from './components/footer/privacy-policy-de/privacy-policy-de.component';
import { TosDeComponent } from './components/footer/tos-de/tos-de.component';
import { TosEnComponent } from './components/footer/tos-en/tos-en.component';
import { CookiePolicyDeComponent } from './components/footer/cookie-policy-de/cookie-policy-de.component';
import { CookiePolicyEnComponent } from './components/footer/cookie-policy-en/cookie-policy-en.component';
import { TomsComponent } from './components/toms/toms.component';
import { TomsCategoryComponent } from './components/toms/toms-category/toms-category.component';
import { GetBadgeComponent } from './components/badge/get-badge/get-badge.component';
import { BadgeInfoComponent } from './components/badge/badge-info/badge-info.component';

import { SupervisionClientListComponent } from './components/supervision/client-list/client-list.component';
import { ConfirmSupervisionInvitationComponent } from './components/supervision/invitation/invitation.component';
import { SupervisionClientDetailsComponent } from './components/supervision/client-details/client-details.component';
import { SupervisorProfileComponent } from './components/supervision/profile/supervisor-profile.component';
import { WebsiteComplianceComponent } from './components/website-compliance/website-compliance.component';
import { AddClientEmailComponent } from './components/supervision/add-client/email/add-client-email.component';
import { AddClientControllerComponent } from './components/supervision/add-client/controller/add-client-controller.component';
import { DocumentsMiscListComponent } from './components/documents/misc-list/documents-misc-list.component';
import { DocumentsDashboardComponent } from './components/documents/dashboard/documents-dashboard.component';
import { ActionsComponent } from './components/actions/actions.component';

import { ChangelogComponent } from './components/changelog/changelog.component';

import { TodosComponent } from './components/todos/todos/todos.component';
import { PaListComponent } from './components/rpa/pa-list/pa-list.component';

import { AuthorityListComponent } from './components/records/authority/list/list.component';
import { AuthorityDetailsComponent } from './components/records/authority/details/details.component';

import { ComplaintsListComponent } from './components/records/complaints/list/list.component';
import { ComplaintsDetailsComponent } from './components/records/complaints/details/details.component';

import { DsarListComponent } from './components/records/dsar/list/list.component';
import { DsarDetailsComponent } from './components/records/dsar/details/details.component';

import { IncidentsListComponent } from './components/records/incidents/list/list.component';
import { IncidentsDetailsComponent } from './components/records/incidents/details/details.component';

import { MeasuresListComponent } from './components/records/measures/list/list.component';
import { MeasuresDetailsComponent } from './components/records/measures/details/details.component';
import { TrainingListComponent } from './components/training/training-list/training-list.component';
import { TrainingStatsComponent } from './components/training/training-stats/training-stats.component';

const routes: Routes = [
  {
    path: 'changelog',
    component: ChangelogComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'records/authority',
    component: AuthorityListComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'records/authority/:id',
    component: AuthorityDetailsComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'records/complaints',
    component: ComplaintsListComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'records/complaints/:id',
    component: ComplaintsDetailsComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'records/dsar',
    component: DsarListComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'records/dsar/:id',
    component: DsarDetailsComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'records/incidents',
    component: IncidentsListComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'records/incidents/:id',
    component: IncidentsDetailsComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'records/measures',
    component: MeasuresListComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'records/measures/:id',
    component: MeasuresDetailsComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'training',
    component: TrainingListComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'training/stats',
    component: TrainingStatsComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'access-expired',
    component: UserExpiredComponent,
    //canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'email-login',
    component: EmailLoginComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'verification',
    component: VerificationComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'welcome/gdpr-applies',
    component: GdprAppliesComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'welcome/setup-dpms',
    component: SetupDpmsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'organisation',
    component: OrganisationComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'organisation/controller',
    component: ControllerComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'organisation/dp-goals',
    component: DpGoalsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'organisation/dpo',
    component: DeclareDpoComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'milestones/one',
    component: MilestoneOneComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'milestones/two',
    component: MilestoneTwoComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'rpa',
    component: PaListComponent,
    canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'rpa/tutorial',
    component: RpaTutorialComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'rpa/basic-info',
    component: BasicInfoComponent,
    canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'rpa/pa-selection',
    component: PaSelectionComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'rpa/pa-details/:paId',
    component: PaDetailsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'rpa/pa-external-processors',
    component: PaExternalProcessorsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'documents',
    component: DocumentsDashboardComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'rpa/report',
    component: ReportComponent,
    canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'vendors/dpas',
    component: DpasComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'vendors/offer-dpa',
    component: OfferDpaComponent,
  },
  {
    path: 'toms',
    component: TomsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'toms/category/:catId',
    component: TomsCategoryComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'organisation/departments',
    component: DepartmentsComponent,
    canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'todolist',
    component: TodosComponent,
    canActivate: [AuthenticationGuard],
  },
  { // TODO: revert back to a redirect which retains query parameters later. Currently, Angular does not obviously support this.
    path: '',
    pathMatch: 'full',
    component: TodosComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'email-notification',
    component: EmailNotificationComponent,
    canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'profile-pictures',
    component: ProfilePictureComponent,
    canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'under-construction',
    component: UnderConstructionComponent,
  },
  {
    path: 'complaint',
    component: UnderConstructionComponent,
  },
  {
    path: 'authorityRequest',
    component: UnderConstructionComponent,
  },
  {
    path: 'measure',
    component: UnderConstructionComponent,
  },
  {
    path: 'imprint',
    component: ImprintComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'privacy-policy/de',
    component: PrivacyPolicyDeComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'privacy-policy/en',
    component: PrivacyPolicyEnComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'privacy-policy',
    redirectTo: 'privacy-policy/en',
  },
  {
    path: 'terms/de',
    component: TosDeComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'terms/en',
    component: TosEnComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'terms',
    redirectTo: 'terms/en',
  },
  {
    path: 'cookie-policy/de',
    component: CookiePolicyDeComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'cookie-policy/en',
    component: CookiePolicyEnComponent,
    // canActivate: [AuthenticationGuard] // covers entire module
  },
  {
    path: 'cookie-policy',
    redirectTo: 'cookie-policy/en',
  },
  {
    path: 'badge/get',
    component: GetBadgeComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'badge/info/:token',
    component: BadgeInfoComponent,
  },
  {
    path: 'supervision/clients',
    component: SupervisionClientListComponent,
    canActivate: [AuthenticationGuard, ProductFeaturesGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/client/:clientId',
    component: SupervisionClientDetailsComponent,
    canActivate: [AuthenticationGuard, ProductFeaturesGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'supervision/confirm-invitation',
    component: ConfirmSupervisionInvitationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'supervision/supervisor/profile',
    component: SupervisorProfileComponent,
    canActivate: [AuthenticationGuard, ProductFeaturesGuard],
    data: {
      productType: 'supervisor'
    }
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthenticationGuard, ProductFeaturesGuard],
    data: {
      productType: 'default'
    }
  },
  {
    path: 'organisation/user-management',
    component: UserManagementComponent,
    canActivate: [AuthenticationGuard, ProductFeaturesGuard],
    data: {
      productType: 'default'
    }
  },
  {
    path: 'confirm-invitation',
    component: ConfirmInvitationComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'website',
    component: WebsiteComplianceComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'documents/misc',
    component: DocumentsMiscListComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'actions',
    component: ActionsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'auth-token-login',
    component: AuthTokenLoginComponent
  },
  // {
  //   path: '',
  //   redirectTo: '/todolist',
  //   pathMatch: 'full',
  // },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    enableTracing: false,
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule],
  providers: [
  ]
})
export class RoutingConfig { }
