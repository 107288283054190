export default class RegistrationModel {
  public firstName: string = '';
  public lastName: string = '';
  public company: string = '';
  public password: string = '';
  public email: string = '';
  public tos: boolean = false;
  public heardAboutUsHow: string = '';

  public inviteToken?: string;
  public clientInviteToken?: string;
}
