import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { Router, ActivatedRoute, UrlSerializer } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';

import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { DirectoryUser } from 'app/services/directory/directory-user';
import { AuthenticationService } from 'app/services/authentication.service';
import { TodosService, Todo, TodoType } from 'app/services/todos/todos.service';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface TodoDialogParams {
  subject?: string;
  suggestedTitle?: string;
  link?: boolean;
  accessToken?: string;
  suggestedType?: TodoType;
  accessTokenKey?: string;
  todo?: Todo;
  parent?: number;
  suggestedAssignee?: DirectoryUser;
  roleSelection?: { role: string };
  multiple?: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'todo-dialog',
  templateUrl: './todo-dialog.component.html',
  styleUrls: ['./todo-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [LOCALE_ID] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TodoDialogComponent implements OnInit {

  assignee: DirectoryUser;
  title: string;
  description: string;
  duedate: Moment;
  type: TodoType = 'todos.type.generic';
  parent: number | undefined;

  pending = false;

  constructor(
    public auth: AuthenticationService,
    private service: TodosService,
    private bus: BusService,
    private events: EventsService,
    private router: Router,
    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private ref: MatDialogRef<TodoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public params: TodoDialogParams,
  ) {
    this.params = this.params || {};
  }

  ngOnInit() {
    if (this.params.todo) {
      this.title = this.params.todo.title;
      this.description = this.params.todo.description;
      this.duedate = moment(this.params.todo.dueDate);
      this.type = this.params.todo.type;
      if ((this.params.todo.assignee as any).directoryId === this.auth.username) {
        this.assignee = undefined;
      } else {
        this.assignee = {
          firstName: this.params.todo.assignee.firstName,
          lastName: this.params.todo.assignee.lastName,
          username: (this.params.todo.assignee as any).directoryId,
          email: this.params.todo.assignee.email
        } as DirectoryUser;
      }
    }

    if (this.params.suggestedTitle) {
      this.title = this.params.suggestedTitle;
    }

    if (this.params.suggestedType) {
      this.type = this.params.suggestedType;
    }

    if (this.params.parent) {
      this.parent = this.params.parent;
    }

    if (this.params.suggestedAssignee) {
      this.assignee = this.params.suggestedAssignee;
    }
  }

  get valid(): boolean {
    return this.title !== undefined && this.title.length > 0;
  }

  create() {
    if (!this.params.todo) {
      this.pending = true;

      this.route.queryParams.pipe(take(1)).subscribe(queryParams => {
        let link: string;
        if (this.params.link) {
          const root = this.router.url.split('?')[0];
          const params = Object.assign({}, queryParams, {
            todo: undefined,
            subject: undefined
          });

          if (this.params.accessToken) {
            params[this.params.accessTokenKey] = this.params.accessToken;
          }

          link = this.serializer.serialize(this.router.createUrlTree([root], {queryParams: params}));
        }

        if (this.params.multiple && this.assignee) {
          const assignees = this.assignee as any as DirectoryUser[];
          forkJoin(assignees.map(a => this.service.insert({
            title: this.title,
            description: this.description,
            link,
            subject: this.params.subject,
            assignee: a,
            type: this.type,
            parent: this.parent,
            dueDate: (this.duedate && this.duedate.isValid()) ? this.duedate.toDate() : undefined,
          }))).subscribe((_) => {
            this.bus.publish(this.events.notified.todos.create, this.params.subject);
            this.ref.close(true);
          });
        } else {
          this.service.insert({
            title: this.title,
            description: this.description,
            link,
            subject: this.params.subject,
            assignee: this.assignee,
            type: this.type,
            parent: this.parent,
            dueDate: (this.duedate && this.duedate.isValid()) ? this.duedate.toDate() : undefined,
          }).subscribe(() => {
            this.bus.publish(this.events.notified.todos.create, this.params.subject);
            this.ref.close(true);
          });
        }
      });
    }
  }

  update() {
    this.params.todo.title = this.title;
    this.params.todo.description = this.description;
    this.params.todo.assignee = this.assignee;
    this.params.todo.dueDate = (this.duedate && this.duedate.isValid()) ? this.duedate.toDate() : undefined;
    this.params.todo.type = this.type;

    this.service.update(this.params.todo).subscribe(() => {
      this.bus.publish(this.events.notified.todos.singleUpdate, this.params.todo);
      this.bus.publish(this.events.notified.todos.update);
      if (!this.params.todo.assignee) {
        this.params.todo.assignee = {
          firstName: this.auth.firstName,
          lastName: this.auth.lastName,
          email: this.auth.email,
          username: this.auth.username,
        } as DirectoryUser;
      }
      this.ref.close(true);
    });
  }
}
