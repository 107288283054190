import { Injectable } from '@angular/core';
import { BusService } from '../bus.service';
import { EventsService } from '../events.service';
import { RequestService } from '../request.service';

interface AddRequest {
  name: string;
  description: string;
  department: string;
}

interface AddResponse {
  id: number;
}

interface UpdateRequest {
  id: number;
  name: string;
  description: string;
  department: string;
}

interface GetRequest {
  id: number;
}

interface DeleteRequest {
  id: number;
}

@Injectable()
export class CustomPaService {

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  requestAdd(data: AddRequest) {
    this.requestService.post<AddRequest, AddResponse>({
      uri: '/custom-pa/',
      body: data,
      handlers: {
        success: result =>
          this.bus.publish(this.events.received.data.rpa.customPa.add.success, result),
        error: error =>
          this.bus.publish(this.events.received.data.rpa.customPa.add.failure, error),
      }
    });
  }

  requestUpdate(data: UpdateRequest) {
    this.requestService.put<UpdateRequest, {}>({
      uri: `/custom-pa/${data.id}/`,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.rpa.customPa.update.success),
        error: error =>
          this.bus.publish(this.events.received.data.rpa.customPa.update.failure, error),
      }
    });
  }

  requestGet(data: GetRequest) {
    this.requestService.get<GetRequest>({
      uri: `/custom-pa/${data.id}/`,
      handlers: {
        success: result => this.bus.publish(this.events.received.data.rpa.customPa.get.success, result),
        error: error =>
          this.bus.publish(this.events.received.data.rpa.customPa.get.failure, error),
      }
    });
  }

  requestDelete(data: DeleteRequest) {
    this.requestService.delete<DeleteRequest>({
      uri: `/custom-pa/${data.id}/`,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.rpa.customPa.delete.success),
        error: error =>
          this.bus.publish(this.events.received.data.rpa.customPa.delete.failure, error),
      }
    });
  }

  private subscribe() {
    this.bus.subscribe(this.events.requested.data.rpa.customPa.add, this.requestAdd.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.customPa.update, this.requestUpdate.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.customPa.get, this.requestGet.bind(this));
    this.bus.subscribe(this.events.requested.data.rpa.customPa.delete, this.requestDelete.bind(this));
  }
}
