import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';


@Component({
  selector: 'app-delete-client-dialog',
  templateUrl: './delete-client-dialog.component.html',
  styleUrls: ['./delete-client-dialog.component.scss']
})
export class DeleteClientDialogComponent implements OnInit, OnDestroy {

  constructor(
    private dialogRef: MatDialogRef<DeleteClientDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {

  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  delete() {
    if (this.data.client) {
      this.bus.publish(this.events.requested.action.supervision.clients.delete, this.data.client.id);
    }
  }

  deleted() {
    this.dialogRef.close(true);
  }

  deletionFailed(error) {
    console.error(error);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.supervision.clients.delete.success, this.deleted, this);
    this.bus.subscribe(this.events.received.action.supervision.clients.delete.failure, this.deletionFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.supervision.clients.delete.success, this.deleted);
    this.bus.unsubscribe(this.events.received.action.supervision.clients.delete.failure, this.deletionFailed);
  }
}
