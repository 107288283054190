import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {LogbookAction} from "../../../../../services/actions/actions.service";
import {UserManagementService} from "../../../../../services/user/user-management.service";
import {ActionDetailsDialogComponent} from "../../../../actions/list/details/details-dialog.component";

@Component({
  selector: 'app-changes-dialog',
  templateUrl: './changes-dialog.component.html',
  styleUrls: ['./changes-dialog.component.scss']
})
export class PaDetailsChangesDialogComponent implements OnInit {
  users = [];

  constructor(
    private userService: UserManagementService,
    @Inject(MAT_DIALOG_DATA) public data: { changes: LogbookAction[] },
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.userService.users(true).subscribe(response => {
      this.users = response.map(user => Object.assign({}, user, {
        name: `${user.firstName} ${user.lastName}`
      }));
    });
  }

  getUser(event: LogbookAction) {
    const candidates = this.users.filter(user => user.id === event.userId);
    if (candidates.length > 0) {
      return candidates[0];
    }

    return '---';
  }

  showDetails(event: LogbookAction) {
    this.dialog.open(ActionDetailsDialogComponent, {
      width: '700px',
      data: { event }
    });
  }

}
