import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusService } from "../../../services/bus.service";
import { EventsService } from "../../../services/events.service";

@Component({
  selector: 'email-notification-component',
  templateUrl: './email-notification.settings.template.html',
  styleUrls: ['./email-notification.settings.styles.scss']
})
export class EmailNotificationComponent implements OnInit, OnDestroy {

  public permission: boolean;

  constructor(
    private bus: BusService,
    private events: EventsService
  ) { }

  // starts email-notification request
  public submit(): void {
    this.bus.publish(this.events.requested.data.settings.emailNotification, this.permission)
  }

  public subscribe(): void {
  }

  public unSubscribe(): void {
  }

  // make subscribe on a component initialization
  public ngOnInit(): void {
    this.subscribe();
  }

  // make unsubscribe before destroying component
  public ngOnDestroy(): void {
    this.unSubscribe();
  }
}
