import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BusService} from '../../../services/bus.service';
import {EventsService} from '../../../services/events.service';

const URL_PATTERN = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
const ZIP_PATTERN = '(/^\\d{5}?$/)';
const PHONE_PATTERN = '^\\s*(?:\\+?\\d{1,3})?[- (]*\\d{3}(?:[- )]*\\d{3})?[- ]*\\d{4}(?: *[x/#]\\d+)?\\s*$';
const NUMBER_PATTERN = '^[0-9]*$';

@Component({
  selector: 'app-controller',
  templateUrl: './controller.component.html',
  styleUrls: ['./controller.component.scss']
})
export class ControllerComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  private timeout: any;
  fieldsBeingSaved = {};

  constructor(private bus: BusService,
              private events: EventsService,
            ) {

    this.formGroup = new FormGroup({
      controllerName: new FormControl(''),
      controllerUrl: new FormControl('', [Validators.pattern(URL_PATTERN)]),
      controllerAddressStreet: new FormControl(''),
      controllerAddressPostcode: new FormControl('', [Validators.pattern(ZIP_PATTERN)]),
      controllerAddressCity: new FormControl(''),
      controllerAddressCountry: new FormControl(''),
      controllerContactName: new FormControl(''),
      controllerContactRole: new FormControl(''),
      controllerEmailAddress: new FormControl('', [Validators.email]),
      controllerNumEmployees: new FormControl('', [Validators.pattern(NUMBER_PATTERN)]),
      controllerTelephoneNumber: new FormControl('', [Validators.pattern(PHONE_PATTERN)])
    });
  }

  private fetch(data) {

    this.formGroup.patchValue({
      controllerName: data.controllerName,
      controllerUrl: data.controllerUrl,
      controllerAddressStreet: data.controllerAddressStreet,
      controllerAddressPostcode: data.controllerAddressPostcode,
      controllerAddressCity: data.controllerAddressCity,
      controllerAddressCountry: data.controllerAddressCountry,
      controllerContactName: data.controllerContactName,
      controllerContactRole: data.controllerContactRole,
      controllerEmailAddress: data.controllerEmailAddress,
      controllerNumEmployees: data.controllerNumEmployees,
      controllerTelephoneNumber: data.controllerTelephoneNumber
    });
  }

  public submit(field?): void {
    if (!this.formGroup.controls[field].valid) {
      return;
    }

    clearTimeout(this.timeout);
    if (field) {
      this.fieldsBeingSaved[field] = true;
    }
    this.timeout = setTimeout( () => {
      if (field) {
        this.fieldsBeingSaved[field] = false;
      }
      this.controllerPut();
    }, 1000);
  }

  private controllerPut(): void {
    this.bus.publish(this.events.requested.data.controller.update,
      this.formGroup.value);
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.received.data.controller.get.success, this.fetch, this);
  }

  private unsubscribe(): void {
    this.bus.unsubscribe(this.events.received.data.controller.get.success, this.fetch);
  }

  public get canWrite() {
    return true;
  }

  ngOnInit() {
    this.subscribe();

    this.bus.publish(this.events.requested.data.controller.get);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

}
