import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BusService } from "../../../services/bus.service";
import { EventsService } from "../../../services/events.service";
import { Router } from "@angular/router";

@Component({
  selector: 'verification-component',
  templateUrl: './verification.template.html',
  styleUrls: ['./verification.styles.scss']
})

export class VerificationComponent implements OnInit, OnDestroy {
  state:string = 'initial';

  constructor(
    private bus: BusService,
    private events: EventsService,
  ) {}

  public ngOnInit(): void {
    setTimeout(() => {
      this.bus.publish(this.events.requested.action.verification);
    }, 1000);
    this.subscribe();
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public success(): void {
    this.state = 'success';
  }

  public failure(): void {
    this.state = 'failure';
  }

  private subscribe() {
    this.bus.subscribe(this.events.received.action.verification.success, this.success, this);
    this.bus.subscribe(this.events.received.action.verification.failure, this.failure, this);
  }

  private unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.verification.success, this.success);
    this.bus.unsubscribe(this.events.received.action.verification.failure, this.failure);
  }
}
