import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusService } from 'app/services/bus.service';
import { EventsService } from 'app/services/events.service';
import { ActionsService } from 'app/services/actions/actions.service';
import { UserManagementService } from 'app/services/user/user-management.service';
import { Event } from '../../event';
import { DepartmentService } from 'app/services/departments/department.service';
import { TomsService } from 'app/services/toms/toms.service';

@Component({
  selector: 'app-actions-metadata-toms',
  templateUrl: './toms.component.html',
  styleUrls: ['./toms.component.scss']
})
export class ActionsMetadataTomsComponent implements OnInit {

  @Input() event: Event;
  entry: any;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private tomsService: TomsService
  ) {
  }


  private receiveEntry(entries) {
    this.entry = entries[0];
  }

  ngOnInit() {
    this.bus.subscribe(this.events.received.data.toms.singleEntry.success, this.receiveEntry, this);

    this.bus.publish(this.events.requested.data.toms.singleEntry, this.event.metadata.entryId);
  }
}
