import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';

import { DirectoryUser } from 'app/services/directory/directory-user';
import { SelectDirectoryUserDialogComponent, Options } from './dialog/dialog.component';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'select-directory-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectDirectoryUserComponent implements OnInit {

  @Input() placeholder: string;
  @Input() emptyUserTitle = 'Unknown User';
  @Input() suggestEmptyUser = true;
  @Input() allowEmpty = true;
  @Input() readonly = false;
  @Input() user: DirectoryUser | DirectoryUser[] = undefined;
  @Input() roleSelection = null;
  @Input() multiple = false;
  @Output() userChange = new EventEmitter<DirectoryUser | DirectoryUser[]>();

  touched = false;
  focused = false;
  dialogOpen = false;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    console.log(this.multiple);
  }

  get empty(): boolean {
    return !this.user && !this.allowEmpty;
  }

  get error(): boolean {
    return this.touched && this.empty;
  }

  openDialog() {
    if (this.readonly) {
      return;
    }

    this.dialogOpen = true;
    this.dialog.open(SelectDirectoryUserDialogComponent, {
      width: '500px',
      data: {
        emptyUserTitle: this.emptyUserTitle,
        suggestEmptyUser: this.allowEmpty && this.suggestEmptyUser,
        allowEmpty: this.allowEmpty,
        selected: this.user,
        roleSelection: this.roleSelection,
        multiple: this.multiple
      } as Options
    }).afterClosed().subscribe((result) => {
      this.touched = true;
      this.dialogOpen = false;
      if (result) {
        if (result.selected || this.allowEmpty) {
          this.user = result.selected;
          this.userChange.emit(result.selected);
        }
      }
    });
  }

  blur() {
    this.focused = false;
    this.touched = !this.dialogOpen;
  }
}
