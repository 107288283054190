import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BusService } from "../bus.service";
import {
  IPostOptions,
  RequestService
} from "../request.service";
import { EventsService } from "../events.service";

interface IPostCredentialsRequest {
  permission: boolean;
}

interface IPostCredentialsResponse {
}

/*
  An interlayer service for authentication between request service and login component
*/
@Injectable()
export class EmailNotificationService {

  private options: IPostOptions<IPostCredentialsRequest, IPostCredentialsResponse>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: "/email-notification",
      body: {
        permission: false
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(user: IPostCredentialsResponse): void {
    this.bus.publish(this.events.received.data.settings.emailNotification.success, user);
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
    this.bus.publish(this.events.received.data.settings.emailNotification.failure, httpErrorResponse);
  }

  private request(data: any): void {
    // this.bus.publish(this.events.notified.request.starting);
    this.options.body.permission = data.permission;
    // this.requestService.post<IPostCredentialsRequest, IPostCredentialsResponse>(this.options);
  }

  public subscribe(): void {
    this.bus.subscribe(this.events.requested.data.settings.emailNotification, this.request.bind(this));
  }
}
