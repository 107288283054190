import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-dpa-request-email-sent-dialog',
  templateUrl: './dpa-request-email-sent-dialog.component.html',
  styleUrls: ['./dpa-request-email-sent-dialog.component.scss']
})
export class DpaRequestEmailSentDialogComponent implements OnInit {

  public vendor: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data : any
  ) {
    if (data.vendor) {
      this.vendor = data.vendor;
    }
  }

  ngOnInit() {
  }

}
