import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BasicInfoControllerComponentModel } from './basic-info.rpa.model';

/*
  ReactiveForm structure class
*/
export default class BasicInfoForm {
  private formBuilder: FormBuilder;
  public formGroup: FormGroup;
  public model: BasicInfoControllerComponentModel;

  constructor(model: BasicInfoControllerComponentModel) {
    this.formBuilder = new FormBuilder();
    this.model = model;
    this.createForm();
  }

  // set form fields with validation rules
  public createForm(): void {
    this.formGroup = this.formBuilder.group({
      // controllerName: new FormControl(this.model.controllerName, [Validators.required, Validators.minLength(7), Validators.pattern("^[A-Za-z][A-Za-z0-9]*$")]),
      // controllerContactName: new FormControl(this.model.controllerContactName, [Validators.required, Validators.minLength(7), Validators.pattern("^[A-Za-z][A-Za-z0-9]*$")]),
      // controllerContactRole: new FormControl(this.model.controllerContactRole),
      // controllerAddressStreet: new FormControl(this.model.controllerAddressStreet),
      // controllerAddressPostcode: new FormControl(this.model.controllerAddressPostcode),
      // controllerAddressCity: new FormControl(this.model.controllerAddressCity),
      // controllerAddressCountry: new FormControl(this.model.controllerAddressCountry),
      // controllerTelephoneNumber: new FormControl(this.model.controllerTelephoneNumber, [Validators.required]),
      // controllerEmailAddress: new FormControl(this.model.controllerEmailAddress, [Validators.email]),
      // controllerUrl: new FormControl(this.model.controllerUrl, [Validators.required, Validators.pattern('((http|ftp|https)?:\/\/)?[A-Za-z0-9]*\.[A-Za-z0-9]*\.[A-Za-z0-9]*')]),
      //
      // dpoName: new FormControl(this.model.dpoName, [Validators.required, Validators.minLength(7), Validators.pattern("^[A-Za-z][A-Za-z0-9]*$")]),
      // dpoAddressStreet: new FormControl(this.model.dpoAddressStreet),
      // dpoAddressPostcode: new FormControl(this.model.dpoAddressPostcode),
      // dpoAddressCity: new FormControl(this.model.dpoAddressCity),
      // dpoAddressCountry: new FormControl(this.model.dpoAddressCountry),
      // dpoTelephoneNumber: new FormControl(this.model.dpoTelephoneNumber, [Validators.required]),
      // dpoEmailAddress: new FormControl(this.model.dpoEmailAddress, [Validators.email])
      controllerName: new FormControl(this.model.controllerName),
      controllerContactName: new FormControl(this.model.controllerContactName),
      controllerContactRole: new FormControl(this.model.controllerContactRole),
      controllerAddressStreet: new FormControl(this.model.controllerAddressStreet),
      controllerAddressPostcode: new FormControl(this.model.controllerAddressPostcode),
      controllerAddressCity: new FormControl(this.model.controllerAddressCity),
      controllerAddressCountry: new FormControl(this.model.controllerAddressCountry),
      controllerTelephoneNumber: new FormControl(this.model.controllerTelephoneNumber),
      controllerEmailAddress: new FormControl(this.model.controllerEmailAddress),
      controllerUrl: new FormControl(this.model.controllerUrl),

      dpoName: new FormControl(this.model.dpoName),
      dpoAddressStreet: new FormControl(this.model.dpoAddressStreet),
      dpoAddressPostcode: new FormControl(this.model.dpoAddressPostcode),
      dpoAddressCity: new FormControl(this.model.dpoAddressCity),
      dpoAddressCountry: new FormControl(this.model.dpoAddressCountry),
      dpoTelephoneNumber: new FormControl(this.model.dpoTelephoneNumber),
      dpoEmailAddress: new FormControl(this.model.dpoEmailAddress)
    });
    this.formGroup.valueChanges.subscribe(data => {
      this.model.controllerName = data.controllerName;
      this.model.controllerContactName = data.controllerContactName;
      this.model.controllerContactRole = data.controllerContactRole;
      this.model.controllerAddressStreet = data.controllerAddressStreet;
      this.model.controllerAddressPostcode = data.controllerAddressPostcode;
      this.model.controllerAddressCity = data.controllerAddressCity;
      this.model.controllerAddressCountry = data.controllerAddressCountry;
      this.model.controllerTelephoneNumber = data.controllerTelephoneNumber;
      this.model.controllerEmailAddress = data.controllerEmailAddress;
      this.model.controllerUrl = data.controllerUrl;

      this.model.dpoName = data.dpoName;
      this.model.dpoAddressStreet = data.dpoAddressStreet;
      this.model.dpoAddressPostcode = data.dpoAddressPostcode;
      this.model.dpoAddressCity = data.dpoAddressCity;
      this.model.dpoAddressCountry = data.dpoAddressCountry;
      this.model.dpoTelephoneNumber = data.dpoTelephoneNumber;
      this.model.dpoEmailAddress = data.dpoEmailAddress;
    })
  }

  // form update
  public patchForm(data: any): void {
    this.formGroup.patchValue(data);
    Object.keys(data).forEach(field => {
      this.model[field] = data[field];
    });
  }

  // get form property name
  public getControl(name: string) {
    return this.formGroup.get(name);
  }

  // get form validation status
  get isValid() {
    return this.formGroup.valid;
  }

  // get group value
  get formData(): BasicInfoControllerComponentModel {
    return this.formGroup.getRawValue();
  }

  // start form validation
  public validate(): void {
    this.validateFormFields(this.formGroup);
  }

  // form validation functionality
  public validateFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateFormFields(control);
      }
    });
  }
}
