import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BusService } from "../../services/bus.service";
import { EventsService } from "../../services/events.service";
import { AuthenticationService } from "../../services/authentication.service";


@Component({
  selector: 'app-user-expired',
  templateUrl: './user-expired.component.html',
  styleUrls: ['./user-expired.component.scss']
})
export class UserExpiredComponent implements OnInit {

  constructor(
    private router: Router,
    private bus: BusService,
    private events: EventsService,
    private auth: AuthenticationService,
  ) { }

  ngOnInit() {
  }

  logout() {
    let email = '' + this.auth.email; // yep to clone it.

    this.bus.publish(this.events.notified.user.logout);
    this.router.navigate(['/login'], {queryParams: {email: email}});
  }
}
