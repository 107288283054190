import { Component, OnInit } from '@angular/core';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';


@Component({
  selector: 'app-gdpr-applies',
  templateUrl: './gdpr-applies.component.html',
  styleUrls: ['./gdpr-applies.component.scss']
})
export class GdprAppliesComponent implements OnInit {

  constructor(
    private bus: BusService,
    private events: EventsService,
  ) { }

  ngOnInit() {
  }

  progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_gdpr_applies',
    });
  }
}
