import { Injectable } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import { share } from 'rxjs/operators';

import { ControllerType, SettingsType, Template } from '../../components/supervision/templates/types';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';


export interface CreateTemplateRequest {
  controllerId: number;
  templateName: string;
  settings: SettingsType;
}

export interface CreateTemplateResponse {
  templateId: number;
}

export interface ApplyTemplateRequest {
  settings: SettingsType;
}


@Injectable()
export class SupervisionTemplatesBackendService {
  api = {
    root: '/supervision/templates',
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private request: RequestService,
  ) {
    this.subscribe();
  }

  /**
   *
   * will fetch all of the templates owned by current user.
   * NOTE that templates are user bound not controller bound.
   *
   */
  public all() {
    return this.request.get<Template[]>({
      uri: this.api.root,
      handlers: {
        success: response => this.bus.publish(this.events.received.data.supervision.templates.all.success, response),
        error: error => this.bus.publish(this.events.received.data.supervision.templates.all.failure, error),
      }
    }).pipe(share());
  }

  /**
   *
   * will fetch info and data of a single template.
   * NOTE that the template should be owned by current user.
   *
   */
  public get(templateId: number) {
    return this.request.get<Template>({
      uri: this.api.root + `/${templateId}`,
      handlers: {
        success: response => this.bus.publish(this.events.received.data.supervision.templates.get.success, response),
        error: error => this.bus.publish(this.events.received.data.supervision.templates.get.failure, error),
      }
    }).pipe(share());
  }

  /**
   *
   * will create a new template based on data from given controller.
   * @param settings will be used to determine which data to extract from the controller.
   *
   */
  public create(controllerId: number, templateName: string, settings: SettingsType) {
    return this.request.post<CreateTemplateRequest, CreateTemplateResponse>({
      uri: this.api.root,
      body: {
        controllerId,
        templateName,
        settings
      },
      handlers: {
        success: response => this.bus.publish(this.events.received.action.supervision.templates.create.success, response),
        error: error => this.bus.publish(this.events.received.action.supervision.templates.create.failure, error)
      }
    }).pipe(share());
  }

  /**
   *
   * will apply the given template to given controller, filling in all missing data from
   * data present within given template.
   * @param settings determines which pieces of data present in the template to use.
   *
   */
  public apply(templateId: number, controllerId: number, settings: SettingsType) {
    return this.request.post<ApplyTemplateRequest, any>({
      uri: this.api.root + `/${templateId}/apply/${controllerId}`,
      body: { settings },
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.templates.apply.success),
        error: error => this.bus.publish(this.events.received.action.supervision.templates.apply.failure, error),
      }
    }).pipe(share());
  }

  /**
   *
   * will update the given template from given controller.
   * @param settings determines which pieces of data from the controller should be used.
   *
   */
  public update(templateId: number, controllerId: number, templateName: string, settings: SettingsType) {
    return this.request.put<CreateTemplateRequest, any>({
      uri: this.api.root + `/${templateId}`,
      body: {
        templateName: templateName,
        controllerId: controllerId,
        settings: settings,
      },
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.templates.update.success),
        error: error => this.bus.publish(this.events.received.action.supervision.templates.update.failure, error),
      }
    }).pipe(share());
  }

  /**
   *
   * will delete given template.
   *
   */
  public delete(templateId: number) {
    return this.request.delete<any>({
      uri: this.api.root + `/${templateId}`,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.templates.delete.success),
        error: error => this.bus.publish(this.events.received.action.supervision.templates.delete.failure, error),
      }
    }).pipe(share());
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.supervision.templates.all, this.all.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.templates.create, event => this.create(event.controllerId, event.templateName, event.settings || {}));
    this.bus.subscribe(this.events.requested.action.supervision.templates.apply, event => this.apply(event.templateId, event.controllerId, event.settings || {}));
    this.bus.subscribe(this.events.requested.action.supervision.templates.update, event => this.update(event.templateId, event.controllerId, event.templateId, event.settings || {}));
    this.bus.subscribe(this.events.requested.action.supervision.templates.delete, event => this.delete(event.templateId));
  }
}
