import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';

interface UpdateProfileRequest {
  displayName: string;
}

@Injectable()
export class SupervisorProfileService {

  api = {
    root: '/supervision/supervisor/profile'
  };

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  getProfile() {
    this.requestService.get<any>({
      uri: this.api.root,
      handlers: {
        success: profile => this.bus.publish(this.events.received.data.supervision.supervisor.profile.success, profile),
        error: error => this.bus.publish(this.events.received.data.supervision.supervisor.profile.failure, error.error),
      }
    });
  }

  updateProfile(data: UpdateProfileRequest) {
    this.requestService.post<UpdateProfileRequest, void>({
      uri: this.api.root,
      body: data,
      handlers: {
        success: () => this.bus.publish(this.events.received.action.supervision.supervisor.profile.update.success),
        error: error => this.bus.publish(this.events.received.action.supervision.supervisor.profile.update.failure, error.error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.supervision.supervisor.profile, this.getProfile.bind(this));
    this.bus.subscribe(this.events.requested.action.supervision.supervisor.profile.update, this.updateProfile.bind(this));
  }
}
