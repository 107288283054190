import { Injectable } from '@angular/core';

import {HttpErrorResponse} from '@angular/common/http';
import {BusService} from '../bus.service';
import {EventsService} from '../events.service';
import {
  IGetOptions,
  IPostOptions,
  RequestService
} from '../request.service';

export enum CommentTypes {
  text = 'comments.type.text',
  close = 'comments.type.close',
}

export interface NewComment {
  subject: Subject;
  text?: string;
  mentions?: { id: number; }[];
  type: CommentTypes;
}

export interface Comment {
  authorId: number;
  date: string;
  text: string;
  type: CommentTypes;
}

export interface Subject {
  title?: string;
  host?: string;
  codename: string;
  open: boolean;
  comments: Comment[];
}

@Injectable()
export class CommentService {
  api = {
    root: '/comments',
  }

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.subscribe();
  }

  get(subject: string) {
    return this.requestService.get<Subject>({
      uri: this.api.root + `/${subject}/`,
      handlers: {
        success: subject => this.bus.publish(this.events.received.data.comments.get.success, subject),
        error: error => this.bus.publish(this.events.received.data.comments.get.failure, error),
      }
    });
  }

  post(comment: NewComment) {
    return this.requestService.post<NewComment, void>({
      uri: this.api.root + `/${comment.subject.codename}/`,
      body: comment,
      handlers: {
        success: () => this.bus.publish(this.events.received.data.comments.post.success, comment),
        error: error => this.bus.publish(this.events.received.data.comments.post.failure, error),
      }
    });
  }

  subscribe() {
    this.bus.subscribe(this.events.requested.data.comments.get, this.get.bind(this));
    this.bus.subscribe(this.events.requested.data.comments.post, this.post.bind(this));
  }
}
