import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { BusService } from '../../../../services/bus.service';
import { EventsService } from '../../../../services/events.service';
import { DocumentResourceService } from '../../../../services/documents/document-resource.service';


@Component({
  selector: 'app-delete-document-dialog',
  templateUrl: './delete-document-dialog.component.html',
  styleUrls: ['./delete-document-dialog.component.scss']
})
export class DeleteDocumentDialogComponent implements OnInit, OnDestroy {

  constructor(
    private dialogRef: MatDialogRef<DeleteDocumentDialogComponent>,
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private docService: DocumentResourceService
  ) {

  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  get document() {
    return this.data.document;
  }

  delete() {
    if (this.data.document) {
      this.bus.publish(this.events.requested.action.documents.delete, this.data.document);
    }
  }

  deleted() {
    this.dialogRef.close(true);
  }

  deletionFailed(error) {
    console.error(error);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.action.documents.delete.success, this.deleted, this);
    this.bus.subscribe(this.events.received.action.documents.delete.failure, this.deletionFailed, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.action.documents.delete.success, this.deleted);
    this.bus.unsubscribe(this.events.received.action.documents.delete.failure, this.deletionFailed);
  }
}
