import { Component, OnInit } from '@angular/core';
import { DirectoryUser } from 'app/services/directory/directory-user';
import { MatDialog } from '@angular/material';
import { TrainingDialogComponent } from '../training-dialog/training-dialog.component';
import { TrainingService, Training, TrainingSearchCriteria } from 'app/services/training/training.service';
import { TrainingInfoDialogComponent } from '../training-info-dialog/training-info-dialog.component';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { TrainingStatsComponent } from '../training-stats/training-stats.component';

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss']
})
export class TrainingListComponent implements OnInit {

  trainings: Training[];
  justAdded: Training;
  searchCriteria: TrainingSearchCriteria = {};
  pastTrainings = true;
  upcomingTrainings = true;

  fetchSubject = new Subject();
  criteriaChanged = new Subject();

  constructor(
    private dialog: MatDialog,
    private service: TrainingService,
  ) {
    this.fetchSubject.pipe(switchMap(() => {
      this.searchCriteria.startingTime = {};

      if (!this.pastTrainings) {
        this.searchCriteria.startingTime.after = new Date();
      }

      if (!this.upcomingTrainings) {
        this.searchCriteria.startingTime.before = new Date();
      }

      return this.service.find(this.searchCriteria).pipe(tap(trainings => {
        this.trainings = trainings;
        if (this.justAdded) {
          const added = this.trainings.find(t => t.id === this.justAdded.id);
          if (added) {
            this.showInfo(added);
          } else {
            this.showInfo(this.justAdded);
          }

          this.justAdded = undefined;
        }
      }));
    })).subscribe();

    this.criteriaChanged.pipe(debounceTime(300)).subscribe(() => this.fetchSubject.next());
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.fetchSubject.next();
  }

  newTraining() {
    this.dialog.open(TrainingDialogComponent, {
      width: '700px',
    }).afterClosed().subscribe(changes => {
      if (changes) {
        this.fetchData();
        if (changes.saved) {
          this.justAdded = changes.saved;
        }
      }
    });
  }

  showStats() {
    this.dialog.open(TrainingStatsComponent, {
      width: '512px'
    });
  }

  showInfo(training: Training) {
    this.dialog.open(TrainingInfoDialogComponent, {
      width: '512px',
      data: { training }
    }).afterClosed().subscribe(changes => {
      if (changes) {
        this.fetchData();
      }
    });
  }
}
