import { Injectable } from "@angular/core";

import * as Rx from "rxjs";
/*
  service to subscribe/unsubscribe and start event
*/

@Injectable()
export class BusService {

  listeners;
  eventsSubject;
  events;

  constructor() {
    this.listeners = {}; // list of current(subscribed) events
    this.eventsSubject = new Rx.Subject();

    this.events = Rx.from(this.eventsSubject);

    this.events.subscribe(
      ({name, args}) => {
        if (this.listeners[name]) {
          for (let listener of this.listeners[name]) {
            const myThis = listener.boundContext;
            if(listener.boundContext) {
              listener.call(myThis, ...args);
            } else {
              listener(...args);
            }

          }
        }
      });
  }

  // add the event to an event list
  subscribe(name: string, listener, context?) {
    //console.log("bus service",name )
    if(context) {
      listener.boundContext = context
      //console.log('context ', context)
     // console.log('listener.boundContext ', listener.boundContext)
    }
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }
    //console.log("final listhener ", listener)
   // console.log("this listhener ", this.listeners[name])
    this.listeners[name].push(listener);
  }
  // remove the event from an event list
  unsubscribe(name: string, listener) {
    const listeners = this.listeners[name];
    if(listeners){
      const index = listeners.indexOf(listener);
      listeners.splice(index, 1)
    }
  }
  // publish delivers the event we create + adding data we send
  publish(name: string, ...args) {
    //console.log('name ===>>>', name)
   // console.log('...args ===>>>', ...args)
    this.eventsSubject.next({
      name,
      args
    });
  }
}
