export class BasicInfoComponentModel {
  constructor(
    public firstname: string,
    public lastname: string,
    public email: string,
    public password: string
  ) {  }
}

export class BasicInfoUserComponentModel {
  public _id: string;
  public username: string;
  public password: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
}

export class BasicInfoControllerComponentModel {
  public conrollerId: string;
  public controllerName: string;
  public controllerContactName: string;
  public controllerContactRole: string;
  public controllerAddressStreet: string;
  public controllerAddressPostcode: string;
  public controllerAddressCity: string;
  public controllerAddressCountry: string;
  public controllerTelephoneNumber: string;
  public controllerEmailAddress: string;
  public controllerUrl: string;

  public dpoId: string;
  public dpoName: string;
  public dpoAddressStreet: string;
  public dpoAddressPostcode: string;
  public dpoAddressCity: string;
  public dpoAddressCountry: string;
  public dpoTelephoneNumber: string;
  public dpoEmailAddress: string;
}
