import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BusService } from "../bus.service";
import {
  IPostOptions,
  RequestService
} from "../request.service";
import { EventsService } from "../events.service";

interface IPostSendVerificationEmailRequest {
  email: string;
}

interface IPostSendVerificationEmailResponse {
  success: boolean;
}

@Injectable()
export class SendVerificationEmailService {
  private options: IPostOptions<IPostSendVerificationEmailRequest,
                              IPostSendVerificationEmailResponse>;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private requestService: RequestService
  ) {
    this.options = {
      uri: "/account/send-verification-email",
      body: {
        email: "",
      },
      handlers: {
        success: this.success.bind(this),
        error: this.error.bind(this)
      }
    };
    this.subscribe();
  }

  public success(data: IPostSendVerificationEmailResponse): void {
  }

  public error(httpErrorResponse: HttpErrorResponse): void {
  }

  public request(data: any): void {
    this.bus.publish(this.events.notified.request.starting);
    this.options.body.email = data.email;
    this.requestService.post<IPostSendVerificationEmailRequest,
                            IPostSendVerificationEmailResponse>(this.options);
  }

  private subscribe(): void {
    this.bus.subscribe(this.events.requested.action.resendVerificationEmail, this.request.bind(this));
  }
}
