import { Directive, Host, Self, Optional, HostListener } from '@angular/core';
import { MatSelect, MatDialog, MatOption, MatDialogRef } from '@angular/material';
import { LongSelectDialogComponent, LongSelectDialogConfig } from 'app/components/long-select-dialog/long-select-dialog.component';

/**
 * This is meant to extend the functionality of a mat-select for scenarios when there are lots of options.
 * 
 * This directive connects the mat-select with a dedicated modal.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[long-select]'
})
export class LongSelectDirective {

  ref: MatDialogRef<LongSelectDialogComponent>;

  constructor(
    private dialog: MatDialog,
    @Host() @Self() private host: MatSelect
  ) {
    host.panelClass = 'panel-hidden'; // see styles.scss for workaround / this is the most convenient way to suppress the host picker
    host.openedChange.subscribe((isClosed) => {
      // only open the dialog when it hasn't been opened
      if (isClosed) {
        this.openDialog();
      }
    }); // whenever the host opens, open the modal instead
  }

  /**
   * Get a preview of the selected values. When the host is disabled, it will not trigger onOpen. 
   */
  @HostListener('click')
  clicked() {
    if (this.host.disabled && this.host.multiple) {
      this.openDialog();
    }
  }

  openDialog() {
    if (this.ref) { // prevent two dialogs from being opened 
      return;
    }

    if (this.host.disabled && !this.host.multiple) {
      return;  // opening the dialog would not provide additional value - the single selected item is already visible in the host
    } else {
      this.ref = this.dialog.open(LongSelectDialogComponent, {
        width: '512px',
        data: <LongSelectDialogConfig>{
          options: this.host.options,
          value: this.hostValue,
          multiple: this.host.multiple,
          readonly: this.host.disabled,
          title: this.host.placeholder,
        }
      });

      this.ref.afterClosed().subscribe(update => {
        if (update && 'value' in update) { // make sure something changed
          // really forcing the host to pick up the new value:
          this.host.ngControl.control.setValue(update.value);
          this.host.writeValue(update.value);
          this.host.valueChange.emit(update.value);
          this.host.selectionChange.emit(update.value);
        }

        this.host.close();
        this.host.focused = false;

        this.ref = undefined;
      });
    }
  }

  // helper function, because there is no more straight-forward way, apparently
  get hostValue() {
    if (this.host.multiple) {
      return (this.host.selected as MatOption[]).map(option => option.value);
    } else {
      if (this.host.selected) {
        return (this.host.selected as MatOption).value;
      } else {
        return undefined;
      }
    }
  }
}
