import { Component, OnInit, OnDestroy } from '@angular/core';

import { BusService } from '../../../services/bus.service';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class RpaTutorialComponent implements OnInit, OnDestroy {

  companyName : string = 'ACME';

  constructor(
    private bus: BusService,
    private events: EventsService,
  ) { }

  ngOnInit() {
    this.subscribe();
    this.bus.publish(this.events.requested.data.controller.name);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_pa_tutorial',
    });
  }

  updateCompanyName(data) {
    if (data.controllerName)
      this.companyName = data.controllerName;
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.controller.name.success, this.updateCompanyName, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.controller.name.success, this.updateCompanyName);
  }

}
