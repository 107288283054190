import { environment } from '../environments/environment';

export const AppConfig = {
  apiUrl: environment.apiUrl,
  apiReport: environment.apiUrl + '/report',
  defaultLang: 'de',
  supportedLanguages: ['en', 'de'],
  intercomAppId: environment.intercomAppId,
  stripePublishable: environment.stripePublishable,
  documentDownloadUrl: environment.apiUrl + `/document/`,
  uploadMaxSize: environment.uploadMaxSize,
};
