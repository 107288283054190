import {Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestService } from '../request.service';
import { roles, AccessControlService } from '../access-control.service';


export interface Action {
  title: string;
  hint: string;
  tooltip?: string;
  url: string;
  disabled: boolean;
  dangerous?: boolean;
  requiresValidation?: boolean;
}

export const Submit = <Action> {
  title: 'rpas.workflow.actions.submit',
  hint: 'rpas.workflow.actions.hints.submit',
  url: '/rpas/workflow/submit/',
  disabled: false,
  requiresValidation: true,
};

export const Approve = <Action> {
  title: 'rpas.workflow.actions.approve',
  hint: 'rpas.workflow.actions.hints.approve',
  url: '/rpas/workflow/approve/',
  disabled: false,
  requiresValidation: true,
};

export const Reject = <Action> {
  title: 'rpas.workflow.actions.reject',
  hint: 'rpas.workflow.actions.hints.reject',
  url: '/rpas/workflow/reject/',
  disabled: false,
};

export const Archive = <Action> {
  title: 'rpas.workflow.actions.archive',
  hint: 'rpas.workflow.actions.hints.archive',
  url: '/rpas/workflow/archive/',
  disabled: false,
};

export const Delete = <Action> {
  title: 'rpas.workflow.actions.delete',
  hint: 'rpas.workflow.actions.hints.delete',
  tooltip: 'rpas.workflow.actions.tooltips.delete',
  url: '/rpas/workflow/delete/',
  disabled: false,
  dangerous: true,
};

export const ReOpen = <Action> {
  title: 'rpas.workflow.actions.reopen',
  hint: 'rpas.workflow.actions.hints.reopen',
  url: '/rpas/workflow/reopen/',
  disabled: false,
};

export const DisabledApprove = <Action> {
  title: 'rpas.workflow.actions.approve',
  hint: 'rpas.workflow.actions.hints.approve-disabled',
  disabled: true,
};


export const Actions = {
  submit: Submit,
  approve: Approve,
  reject: Reject,
  archive: Archive,
  delete: Delete,
  reopen: ReOpen
};

export const DisabledActions = {
  approve: DisabledApprove,
};


export const Status = {
  draft: 'draft',
  open: 'open',
  submitted: 'submitted',
  approved: 'approved',
  archived: 'archived',
  deleted: 'deleted',
};


export const AlwaysDisplayedActions = {
  [Status.submitted]: [{action: 'approve', minRole: roles.admin}]
};


@Injectable()
export class RpaWorkflowService {
  constructor(
    private req: RequestService,
    private access: AccessControlService,
  ) {}

  do(action: Action, paId: number) {
    if (action.disabled) {
      return of(false);
    }

    return this.req.put({
      uri: `${action.url}${paId}`,
      body: {},
      handlers: {
        success: () => {},
        error: () => {},
      }
    });
  }

  possibleActions(pa: { paId: number, paStatus: string }): Observable<Action[]> {
    return this.req.get({
      uri: `/rpas/workflow/actions/${pa.paId}`,
      handlers: {
        success: () => {},
        error: () => {}
      }
    }).pipe(map((list: string[]) => {
      const possible = list.map(name => Actions[name]);
      const disabled = (AlwaysDisplayedActions[pa.paStatus] || [])
        .filter(action => typeof action === 'string'
                        || !action.minRole
                        || this.access.hasRoleOrStronger(action.minRole))
        .map(action => (typeof action === 'string') ? action : action.action)
        .filter(name => !list.includes(name))
        .map(name => DisabledActions[name]);

      return [].concat(disabled, possible);
    }));
  }
}
