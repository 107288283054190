import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { EventsService } from '../../../services/events.service';
import { BusService } from '../../../services/bus.service';

import { DpaDialogComponent } from './dpa-dialog/dpa-dialog.component';


@Component({
  selector: 'app-dpas',
  templateUrl: './dpas.component.html',
  styleUrls: ['./dpas.component.scss']
})
export class DpasComponent implements OnInit, OnDestroy {

  vendors = [];
  vendorToOpen = null;

  constructor(
    private bus: BusService,
    private events: EventsService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.subscribe();
    this.requestUpdate();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.vendor)
        this.vendorToOpen = params.vendor;
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  requestUpdate() {
    this.bus.publish(this.events.requested.data.vendors.controllerVendors);
  }

  updateVendors(vendors) {
    this.vendors = vendors;

    if (this.vendorToOpen) {
      let candidates = this.vendors.filter(vendor => vendor.id == this.vendorToOpen);
      if (candidates.length > 0)
        this.openVendorDialog(candidates[0]);

      this.vendorToOpen = undefined;
    }
  }

  lastRequestDateReadable(vendor) {
    return new Date(Date.parse(vendor.lastRequestDate)).toDateString();
  }

  public get canWrite() {
    return true;
  }

  openVendorDialog(vendor) {
    if (this.canWrite) {
      this.dialog.open(DpaDialogComponent, {
        width: '512px',
        data: {
          vendor: vendor,
        }
      }).afterClosed().subscribe(() => {
        this.requestUpdate();
      });
    }
  }

  get done(): boolean {
    if (this.vendors.some(vendor => !vendor.docPath)) return false;
    return true;
  }

  bypass() {
  }

  bypassed() {
    this.router.navigate([""]);
  }

  finish() {
    this.router.navigate(['']);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.vendors.controllerVendors.success, this.updateVendors, this);
  }

  unsubscribe() {
    this.bus.unsubscribe(this.events.received.data.vendors.controllerVendors.success, this.updateVendors);
  }
}
