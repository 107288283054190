import { Component, OnInit } from '@angular/core';
import { BusService } from '../../services/bus.service';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { ControllerModel, ControllerService } from '../../services/controller/controller.service';

@Component({
  selector: 'app-website-compliance',
  templateUrl: './website-compliance.component.html',
  styleUrls: ['./website-compliance.component.scss']
})
export class WebsiteComplianceComponent implements OnInit {

  controllerInfo: ControllerModel;

  timeout: any;

  constructor(
    private controllerService: ControllerService,
    private events: EventsService,
    private router: Router,
    private bus: BusService,
  ) {
  }

  ngOnInit() {
    this.loadData();
    this.subscribe();
  }

  private loadData() {
    this.bus.publish(this.events.requested.data.controller.get);
  }

  public get canWrite() {
    return true;
  }

  updateCompanyData(data) {
    this.controllerInfo = data;
  }

  updateCheckbox(identifier, value) {
    if (!this.canWrite) {
      return;
    }

    if (this.controllerInfo[identifier] !== value) {
      this.controllerInfo[identifier] = value;

      this.controllerService.requestPut(this.controllerInfo);
    }


  }

  updateUrl(identifier, value) {
    if (!this.canWrite) {
      return;
    }

    if (this.controllerInfo[identifier] !== value) {
      this.controllerInfo[identifier] = value;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.controllerService.requestPut(this.controllerInfo);
      }, 500);
    }
  }

  public progress(progress) {
    this.bus.publish(this.events.requested.action.tasks.progressControllerTask, {
      progress: progress,
      workflowCodename: 'path_to_gdpr',
      taskCodename: 'ptg_website',
    });
  }

  continue() {
    this.progress(1);
    this.router.navigate(['']);
  }

  subscribe() {
    this.bus.subscribe(this.events.received.data.controller.get.success, (data) => {
      this.updateCompanyData(data);
    });
  }

  unsubscribe() {

  }

}
