import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import ChangePasswordModel from './change-password.settings.model';


export function sameValue(sourceControl): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    return (sourceControl.value != control.value)?{'sameValue': {'value': control.value}}:null;
  };
}

/*
  ReactiveForm structure class
*/
export default class RegistrationForm {
  private formBuilder: FormBuilder;
  public formGroup: FormGroup;
  public model: ChangePasswordModel;

  constructor(model: ChangePasswordModel) {
    this.formBuilder = new FormBuilder();
    this.model = model;
    this.createForm();
  }
  // set form fields with validation rules
  public createForm() {
    var password = new FormControl(this.model.password, [Validators.required, Validators.minLength(8)]);
    var passwordConfirm = new FormControl(this.model.passwordConfirm, [Validators.required, sameValue(password)]);
    //var passwordConfirm = new FormControl(this.model.passwordConfirm, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      password: password,
      passwordConfirm: passwordConfirm,
    });
    this.formGroup.valueChanges.subscribe(data => {
      this.model.password = data.password;
      this.model.passwordConfirm = data.passwordConfirm;
    })
  }

  // form update
  public patchForm(data: any): void {
    this.formGroup.patchValue(data);
    Object.keys(data).forEach(field => {
      this.model[field] = data[field];
    });
  }

  // get form property name
  public getControl(name: string) {
    return this.formGroup.get(name);
  }

  // get form validation status
  get isValid() {
    return this.formGroup.valid;
  }

  // get group value
  get formData(): ChangePasswordModel {
    return this.formGroup.getRawValue();
  }

  // start form validation
  public validate(): void {
    this.validateFormFields(this.formGroup);
  }

  // form validation functionality
  public validateFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateFormFields(control);
      }
    });
  }
}
